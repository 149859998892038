import { MultiSelectControl } from "./MultiSelectControl";

export const SelectFilter = (props: {
  title: string;
  filterCounts: Record<string, number>;
  activeFilter: string[];
  setActiveFilter: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  return (
    <MultiSelectControl
      title={props.title}
      items={Object.entries(props.filterCounts)
        .sort((a, b) => b[1] - a[1])
        .map(([value, count]) => ({
          id: value,
          name: value,
          count: count,
        }))}
      selectedItems={props.activeFilter.map((value) => ({
        id: value,
        name: value,
      }))}
      selectItem={(item, isSelected) => {
        if (isSelected) {
          // find doc type
          props.setActiveFilter((prev) => [...prev, item.id]);
        } else {
          props.setActiveFilter((prev) =>
            prev.filter((value) => value !== item.id)
          );
        }
      }}
      clearSelectedItems={() => {
        props.setActiveFilter([]);
      }}
      selectAll={() => {
        props.setActiveFilter(Object.keys(props.filterCounts));
      }}
      selectItemOnly={(item) => {
        props.setActiveFilter([item.id]);
      }}
    />
  );
};

export const getFilterCounts = (filterValuesAll: string[]) => {
  const filterCounts: Record<string, number> = filterValuesAll.reduce(
    (acc, value) => {
      acc[value] = (acc[value] || 0) + 1;
      return acc;
    },
    {} as Record<string, number>
  );
  return filterCounts;
};
