export const getCookie = (cookieName: string) => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(cookieName)) {
      const output = cookie.substring(cookieName.length, cookie.length);
      return output;
    }
  }
  return "";
};

export const toggleAtlasWidget = (dialogOpen: boolean) => {
  const widgetEl = document.querySelector<HTMLDivElement>("#atlas-chat");
  if (!widgetEl) return;
  widgetEl.style.opacity = dialogOpen ? "25%" : "100%";
};
