import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../shadcn/components/popover";
import { Button } from "../../shadcn/components/button";
import { cn } from "../../shadcn/lib/utils";
import { CalendarIcon, ReloadIcon } from "@radix-ui/react-icons";
import { format } from "date-fns";
import { Calendar } from "../../shadcn/components/calendar";
import { Input } from "../../shadcn/components/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../shadcn/components/select";
import { ApprovalConfig, FlowSchedule } from "../../types";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../../shadcn/components/dialog";
import { useState } from "react";
import { updateApprovalConfig } from "../../utils/apiCalls";
import { toast } from "sonner";
import { useAuthInfo } from "@propelauth/react";

export const Scheduler = (props: {
  docId: string;
  approvalConfig: ApprovalConfig;
  activeSchedule: FlowSchedule | null;
  setApprovalConfig: React.Dispatch<
    React.SetStateAction<ApprovalConfig | null>
  >;
}) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const authInfo = useAuthInfo();

  const onSave = async () => {
    setLoading(true);
    const response = await updateApprovalConfig(
      props.docId,
      props.approvalConfig,
      null,
      authInfo.accessToken ?? ""
    );
    if (response !== null) {
      toast.success("Schedule updated");
      props.setApprovalConfig((prev) => {
        if (prev && prev.schedule) {
          return {
            ...prev,
            schedule: response.schedule_instance_id
              ? {
                  ...prev.schedule,
                  schedule_instance_id: response.schedule_instance_id,
                }
              : null,
          };
        }
        return prev;
      });
      setLoading(false);
      setOpen(false);
    } else {
      toast.error("Error updating schedule");
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">Schedule</Button>
      </DialogTrigger>
      <DialogContent>
        <div className="flex items-center space-x-2">
          <div>Start Date</div>
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant={"outline"}
                className={cn(
                  "w-[240px] justify-start text-left font-normal",
                  !props.activeSchedule?.schedule_start_date &&
                    "text-muted-foreground"
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {props.activeSchedule?.schedule_start_date ? (
                  format(
                    new Date(props.activeSchedule.schedule_start_date),
                    "PPP"
                  )
                ) : (
                  <span>Pick a date</span>
                )}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                mode="single"
                selected={
                  props.activeSchedule?.schedule_start_date
                    ? new Date(props.activeSchedule.schedule_start_date)
                    : undefined
                }
                onSelect={(date) => {
                  if (date) {
                    props.setApprovalConfig((prev) => {
                      if (prev !== null && prev.schedule) {
                        return {
                          ...prev,
                          schedule: {
                            ...prev.schedule,
                            schedule_start_date: date?.toISOString(),
                          },
                        };
                      } else {
                        return {
                          attestation: prev?.attestation ?? false,
                          flow_id: prev?.flow_id ?? null,
                          schedule: {
                            schedule_interval_amount: 1,
                            schedule_interval_unit: "years",
                            schedule_instance_id: null,
                            schedule_start_date: date?.toISOString(),
                          },
                        } as ApprovalConfig;
                      }
                    });
                  }
                }}
                initialFocus
              />
            </PopoverContent>
          </Popover>
        </div>
        <div className="flex items-center space-x-2">
          <div>Schedule every</div>
          <Input
            type="number"
            placeholder="1"
            className="w-16"
            value={props.activeSchedule?.schedule_interval_amount}
            onChange={(e) => {
              props.setApprovalConfig((prev) => {
                if (prev !== null && prev.schedule) {
                  return {
                    ...prev,
                    schedule: {
                      ...prev.schedule,
                      schedule_interval_amount: Number(e.target.value),
                    },
                  };
                } else {
                  return {
                    attestation: prev?.attestation ?? false,
                    flow_id: prev?.flow_id ?? null,
                    schedule: {
                      schedule_interval_amount: Number(e.target.value),
                      schedule_interval_unit: "years",
                      schedule_instance_id: null,
                      schedule_start_date: new Date().toISOString(),
                    },
                  } as ApprovalConfig;
                }
              });
            }}
          />
          <Select
            value={props.activeSchedule?.schedule_interval_unit}
            onValueChange={(intervalType: "months" | "years") => {
              props.setApprovalConfig((prev) => {
                if (prev !== null && prev.schedule) {
                  return {
                    ...prev,
                    schedule: {
                      ...prev.schedule,
                      schedule_interval_unit: intervalType,
                    },
                  };
                } else {
                  return {
                    attestation: prev?.attestation ?? false,
                    flow_id: prev?.flow_id ?? null,
                    schedule: {
                      schedule_interval_amount: 1,
                      schedule_interval_unit: intervalType,
                      schedule_instance_id: null,
                      schedule_start_date: new Date().toISOString(),
                    },
                  } as ApprovalConfig;
                }
              });
            }}
          >
            <SelectTrigger className="w-28 bg-white">
              <SelectValue placeholder="years" />
            </SelectTrigger>
            <SelectContent>
              {["months", "years"].map((intervalType) => (
                <SelectItem value={intervalType} key={intervalType}>
                  {intervalType}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <Button variant="default" onClick={onSave}>
          Save
          {loading && <ReloadIcon className="ml-2 h-4 w-4 animate-spin" />}
        </Button>
      </DialogContent>
    </Dialog>
  );
};
