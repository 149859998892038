import { SearchResult } from "../types";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../shadcn/components/card";
import { cn } from "../shadcn/lib/utils";
import { Badge } from "../shadcn/components/badge";
import { Button } from "../shadcn/components/button";
import { Copy } from "lucide-react";
import { toast } from "sonner";

export const processRelevantQuote = (
  text: string,
  startIndex: number,
  endIndex: number
) => {
  const wordCount = 5;
  const textFmt = text.replace(//g, "•");
  const words = textFmt.split(/\s+/);

  const startWordIndex = textFmt.slice(0, startIndex).split(/\s+/).length - 1;
  const endWordIndex = textFmt.slice(0, endIndex).split(/\s+/).length - 1;

  const startRange = Math.max(startWordIndex - wordCount, 0);
  const endRange = Math.min(endWordIndex + wordCount, words.length);

  const quote = words.slice(startWordIndex, endWordIndex).join(" ");
  const beforeQuote = words.slice(startRange, startWordIndex).join(" ");
  const afterQuote = words.slice(endWordIndex, endRange).join(" ");

  return {
    quote,
    beforeQuote,
    afterQuote,
  };
};

const HighlightedSection = (props: {
  text: string;
  startIndex: number;
  endIndex: number;
}) => {
  const { quote, beforeQuote, afterQuote } = processRelevantQuote(
    props.text,
    props.startIndex,
    props.endIndex
  );
  return (
    <CardContent className="text-md text-gray-600">
      ...
      {beforeQuote} <span className="bg-yellow-200">{quote}</span> {afterQuote}
      ...
    </CardContent>
  );
};

export const SearchResultCard = (props: {
  searchResult: SearchResult;
  activeSearchDocId: string | null;
  setActiveSearchDocId: (searchDocId: string | null) => void;
  clickCallback?: () => Promise<void>;
}) => {
  const handleCopy = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const quote = props.searchResult.relevant_quote
      ? processRelevantQuote(
          props.searchResult.text,
          props.searchResult.relevant_quote.start_index,
          props.searchResult.relevant_quote.end_index
        ).quote
      : props.searchResult.text;
    navigator.clipboard.writeText(
      `${props.searchResult.doc_name} Page ${props.searchResult.page}
      
"${quote}"`
    );
    toast.success("Copied to clipboard");
  };

  return (
    <Card
      className={cn(
        "hover:shadow-lg hover:border-2 hover:border-gray-500 rounded-lg cursor-pointer relative",
        props.activeSearchDocId === props.searchResult.search_doc_id
          ? "border-2 border-gray-500"
          : ""
      )}
      onClick={() => {
        props.setActiveSearchDocId(props.searchResult.search_doc_id);
        if (props.clickCallback) {
          props.clickCallback();
        }
      }}
    >
      <Button
        variant="ghost"
        size="icon"
        className="absolute top-2 right-2 z-10"
        onClick={handleCopy}
      >
        <Copy className="h-4 w-4" />
      </Button>
      <CardHeader className="space-y-4">
        <CardTitle>
          <div className="font-normal" style={{ lineHeight: "1.2" }}>
            <span className="inline break-words align-middle">
              {props.searchResult.doc_name}
            </span>
            <Badge className="bg-gray-400 inline ml-4 text-sm">
              {props.searchResult.doc_type.short_display_name ||
                props.searchResult.doc_type.name}
            </Badge>
          </div>
        </CardTitle>
        {props.searchResult.page !== null && (
          <CardDescription className="flex text-gray-400">
            {props.searchResult.additional_info && (
              <span className="mr-2">{props.searchResult.additional_info}</span>
            )}
            <span>Page {props.searchResult.page}</span>
          </CardDescription>
        )}
      </CardHeader>
      {props.searchResult.relevant_quote !== null && (
        <HighlightedSection
          text={props.searchResult.text}
          startIndex={props.searchResult.relevant_quote.start_index}
          endIndex={props.searchResult.relevant_quote.end_index}
        />
      )}
    </Card>
  );
};
