import { Input } from "./input";

export const Search = (props: {
  onChangeHandler: (e: any) => void;
  width: string;
  placeholder?: string;
}) => {
  return (
    <div>
      <Input
        type="search"
        placeholder={props.placeholder || "Search..."}
        className={`w-[${props.width}] inline`}
        onChange={props.onChangeHandler}
      />
    </div>
  );
};
