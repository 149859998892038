import React, {
  MutableRefObject,
  ReactNode,
  RefObject,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Box, HighlightBox, HighlightEnd } from "./types";
import {
  getCharIndexUnderMouse,
  isHighlightable,
  isCurrentBeforeAnchor,
  applyOffsetToHighlight,
  getPxOffsetOfIndex,
  getSpansSortedByheight,
  createMaxBox,
  printHighlights,
  useMutationObserver,
} from "./util";
import { DocViewerContext } from "../../contexts/DocViewerContext";
import HighlightTooltip from "./HighlightTooltip";

export type PdfHighlighterContextType = {
  onPageRenderSuccess: () => void;
  pdfPageRefs: React.MutableRefObject<React.RefObject<HTMLDivElement>[]> | null;
  setPdfPageRefs: (refs: React.RefObject<HTMLDivElement>[]) => void;
};
const PdfHighlighterContext = createContext<PdfHighlighterContextType>({
  onPageRenderSuccess: () => {},
  pdfPageRefs: null,
  setPdfPageRefs: (refs) => {},
});

const emptyHighlightEnd: HighlightEnd = {
  element: null,
  offset: -1,
  offsetPxStartLetter: -1,
  offsetPxEndLetter: -1,
};
interface PdfHighlighterProps {
  children: React.ReactNode;
  tooltipContent?: ReactNode;
}
export const PdfHighlighterProvider: React.FC<PdfHighlighterProps> = ({
  children,
  tooltipContent,
}) => {
  //Refs
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const pdfPageRefs = useRef<React.RefObject<HTMLDivElement>[]>([]);
  //Highlighting state
  const [highlightedElements, setHighlightedElements] = useState<
    HighlightBox[]
  >([]);
  const [highlightedElementsBoundingRect, setHighlightedElementsBoundingRect] =
    useState<Box>({
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    });
  const [highlightAnchor, setHighlightAnchor] =
    useState<HighlightEnd>(emptyHighlightEnd);
  const [highlightCurrent, setHighlightCurrent] =
    useState<HighlightEnd>(emptyHighlightEnd);
  const [isHighlighting, setIsHighlighting] = useState(false);
  const [isHighlightingBackwards, setIsHighlightingBackwards] = useState(false);
  //Cached
  const [elementCache, setElementCache] = useState<Map<Element, HighlightBox>>(
    new Map()
  );
  const [spansSortedByHeight, setSpansSortedByHeight] = useState<
    { element: Element; rect: DOMRect }[]
  >([]);

  const { citationText, setCitationText, numPages, pageNumber } =
    useContext(DocViewerContext);

  useEffect(() => {
    drawHighlightedElements();
    if (!isHighlighting && highlightedElements.length) {
      setHighlightedElementsBoundingRect(createMaxBox(highlightedElements));
    } else {
    }
  }, [highlightedElements]);

  useEffect(() => {
    highlightText(citationText?.match || "");
  }, [citationText, pageNumber]);

  const setPdfPageRefs = (refs: React.RefObject<HTMLDivElement>[]) => {
    pdfPageRefs.current = refs;
  };

  const drawHighlightedElements = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      if (ctx) {
        // Clear the canvas before drawing new highlights
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        highlightedElements.forEach((box) => {
          ctx.fillStyle = "rgba(255, 255, 0, 0.4)";
          ctx.fillRect(box.left, box.top, box.width, box.height);
        });
      }
    }
  };

  const onTextLayerRerender = () => {
    setSpansSortedByHeight(getSpansSortedByheight(containerRef));
    highlightText(citationText?.match || "");
  };

  if (pdfPageRefs) {
    //Observe changes to the text layer: spans load async
    useMutationObserver(pdfPageRefs, onTextLayerRerender, {
      attributes: true,
      childList: true,
      subtree: true,
    });
  }
  /**
   * Create a text blob from all spans, match against searchText, ignoring all characters between [a-z][0-9]
   * @param searchText
   * @returns
   */
  const highlightText = (searchText: string): Range | null => {
    const textLayers = containerRef.current?.querySelectorAll(".textLayer");
    if (!searchText) {
      //Clear Highlight
      setHighlightedElements([]);
    }
    if (!textLayers?.length || !searchText) {
      return null;
    }
    let concatenatedText = "";
    const nodes: { node: Node; startOffset: number; endOffset: number }[] = [];

    textLayers.forEach((textLayer) => {
      const walker = document.createTreeWalker(
        textLayer,
        NodeFilter.SHOW_TEXT,
        null
      );
      let currentNode;

      while ((currentNode = walker.nextNode())) {
        const text = currentNode.textContent || "";
        nodes.push({
          node: currentNode,
          startOffset: concatenatedText.length,
          endOffset: concatenatedText.length + text.length,
        });
        concatenatedText += text;
      }
    });
    let startIndex: number;
    let endIndex: number;
    if (searchText.length > 1000) {
      //Search start and end of biig text blob
      const searchPatternStart = searchText
        .slice(0, 1000)
        .slice()
        .replace(/[^a-zA-Z0-9]/g, "")
        .split("")
        .join("[^a-zA-Z0-9]*");
      const searchPatternEnd = searchText
        .slice(-1000)
        .slice()
        .replace(/[^a-zA-Z0-9]/g, "")
        .split("")
        .join("[^a-zA-Z0-9]*");

      const startRegex = new RegExp(searchPatternStart, "i");
      const startMatch = startRegex.exec(concatenatedText.slice());
      const endRegex = new RegExp(searchPatternEnd, "i");
      const endMatch = endRegex.exec(concatenatedText.slice());
      if (!startMatch || !endMatch) {
        return null;
      }
      startIndex = startMatch.index;
      endIndex = endMatch.index + endMatch[0].length;
    } else {
      const searchPattern = searchText
        .slice(0, 1000)
        .slice()
        .replace(/[^a-zA-Z0-9]/g, "")
        .split("")
        .join("[^a-zA-Z0-9]*");
      const regex = new RegExp(searchPattern, "i");
      const match = regex.exec(concatenatedText.slice());
      if (!match) {
        setHighlightedElements([]);
        return null;
      }
      startIndex = match.index;
      endIndex = startIndex + match[0].length - 1;
    }
    let rangeStartNode: HTMLElement | null = null;
    let rangeEndNode: HTMLElement | null = null;
    let rangeStartOffset = 0;
    let rangeEndOffset = 0;
    const highlightBoxesInRange: HighlightBox[] = [];
    for (let i = 0; i < nodes.length; i++) {
      const { node, startOffset, endOffset } = nodes[i];
      const nodeParentSpan = node.parentElement;
      if (!nodeParentSpan) {
        continue;
      }
      if (
        !rangeStartNode &&
        startIndex >= startOffset &&
        startIndex < endOffset
      ) {
        //We found our rage.start
        rangeStartNode = nodeParentSpan;
        rangeStartOffset = startIndex - startOffset;
        const { offsetPxStartLetter: startOffsetPx } = getPxOffsetOfIndex(
          rangeStartNode,
          rangeStartOffset
        );
        if (endIndex > startOffset && endIndex <= endOffset) {
          //start node === end node
          rangeEndOffset = endIndex - startOffset;
          const { offsetPxEndLetter: endOffsetPx } = getPxOffsetOfIndex(
            rangeStartNode,
            rangeEndOffset
          );
          highlightBoxesInRange.push(
            createHighlightBoxesFromElement(
              rangeStartNode,
              startOffsetPx,
              endOffsetPx
            )
          );
          break;
        }
        highlightBoxesInRange.push(
          createHighlightBoxesFromElement(rangeStartNode, startOffsetPx)
        );
        continue;
      } //End StartNode
      if (endIndex > startOffset && endIndex <= endOffset) {
        //We found our range end node
        rangeEndNode = nodeParentSpan;
        rangeEndOffset = endIndex - startOffset;
        const { offsetPxEndLetter: endOffsetPx } = getPxOffsetOfIndex(
          rangeEndNode,
          rangeEndOffset
        );
        highlightBoxesInRange.push(
          createHighlightBoxesFromElement(rangeEndNode, 0, endOffsetPx)
        );
        continue;
      } //End endNode
      if (rangeStartNode && rangeEndNode) {
        break;
      }
      if (
        rangeStartNode &&
        startIndex <= startOffset &&
        endOffset <= endIndex
      ) {
        //We have a middle node
        highlightBoxesInRange.push(
          createHighlightBoxesFromElement(nodeParentSpan)
        );
      }
    }
    setHighlightedElements(highlightBoxesInRange);
    return null;
  };

  const onPageRenderSuccess = () => {
    const pageElement = containerRef.current;
    if (pageElement && canvasRef.current) {
      const canvas = canvasRef.current;
      canvas.width = pageElement.clientWidth;
      canvas.height = pageElement.clientHeight;
      canvas.style.position = "absolute";
      canvas.style.top = "0";
      canvas.style.left = "0";
      canvas.style.display = "block";
      canvas.style.pointerEvents = "none"; // Make it invisible to pointer events
      pageElement.appendChild(canvas);
    }
    if (citationText?.match) {
      highlightText(citationText.match);
    }
    setSpansSortedByHeight(getSpansSortedByheight(containerRef));
  };

  /**
   * Page has rendered or re-rendered
   * Attach and resize hihglight layer canvas to the pdf canvas
   * Calculate 'Page Multipliers' as the %difference between the style in px and the actual boundingRect
   * The canvas is set to a certain h/w and scaled down, we need to find that scale
   */

  /**
   * Given our array of spans stored in state, sorted by textual order by rect.top, rect.left,
   * find the span/offset closest to the mouse position that should be highlighted
   * Different logic is applied to grab the span/offset depending on if we are highlighting backwards
   * or if we are to the left/right of the closest span
   * @param event MouseEvent
   * @returns The element closest to the span
   */
  const findClosestSpanToMouse = (event: MouseEvent): Element => {
    const { clientX, clientY } = event;
    //Could make binary search, but the volume of spans is so low it shouldnt matter
    let i;
    for (i = 0; i < spansSortedByHeight.length - 1; i++) {
      //If the next span starts below MouseY, we know we have the last eligiable span
      const nextSpan = spansSortedByHeight[i + 1];
      if (nextSpan.rect.top > clientY) {
        break;
      }
    }
    const closestSpan = spansSortedByHeight[i];
    let j = i;
    for (j = i; j > 0; j--) {
      //Go back through the spans that are on the same line
      if (
        Math.abs(
          spansSortedByHeight[i].rect.top - spansSortedByHeight[j - 1].rect.top
        ) > 10
      ) {
        //The next element up is a new line
        break;
      }
    }
    const startSpanOnLine = spansSortedByHeight[j];
    if (closestSpan.rect.bottom > clientY) {
      //We are on the x axis of a line
      if (clientX < startSpanOnLine.rect.left) {
        //We are to the left of the text, return accordingly
        if (isHighlightingBackwards) {
          return startSpanOnLine.element;
        }
        return spansSortedByHeight[j - 1].element;
      }
    }
    if (isHighlightingBackwards) {
      //We are highlighting upwards, so the last highlighted element should be the one below the cursor
      return spansSortedByHeight[i + 1]?.element || null;
    }
    return spansSortedByHeight[i].element;
  };

  /**
   * Maintains a cache of elements -> boundingBox
   * Gets the cached dom rect and apply offset, if not cached update it
   * @param element
   * @param startOffset
   * @param endOffset
   * @returns
   */
  const createHighlightBoxesFromElement = (
    element: Element,
    startOffset: number = 0,
    endOffset: number | false = false
  ): HighlightBox => {
    if (elementCache.has(element)) {
      const cachedRect = elementCache.get(element);
      if (cachedRect) {
        if (!startOffset && !endOffset) {
          return cachedRect;
        }
        return applyOffsetToHighlight(cachedRect, startOffset, endOffset);
      }
    }
    const { left, top, width, height } = element.getBoundingClientRect();
    const { left: canvasLeft, top: canvasTop } =
      canvasRef.current?.getBoundingClientRect() || { top: 0, left: 0 };
    const elemHighlight = {
      left: left - canvasLeft,
      top: top - canvasTop,
      width: width,
      height: height,
    };
    setElementCache(
      (prevCache) => new Map(prevCache.set(element, elemHighlight))
    );
    return applyOffsetToHighlight(elemHighlight, startOffset, endOffset);
  };

  /**
   * Loop through all nodes intersecting range and create a highlight box out of it
   */
  const getElementsInRange = useCallback(
    (range: Range): HighlightBox[] => {
      if (
        highlightAnchor &&
        highlightCurrent &&
        range.startContainer === range.endContainer
      ) {
        const startElement =
          range.startContainer.nodeType === Node.TEXT_NODE
            ? range.startContainer.parentElement
            : range.startContainer;
        return [
          createHighlightBoxesFromElement(
            startElement as Element,
            isHighlightingBackwards
              ? highlightCurrent.offsetPxStartLetter
              : highlightAnchor.offsetPxStartLetter,
            isHighlightingBackwards
              ? highlightAnchor.offsetPxStartLetter
              : highlightCurrent.offsetPxEndLetter
          ),
        ];
      }

      const elements: HighlightBox[] = [];
      let closestSpanAncestor = range.commonAncestorContainer;
      if (closestSpanAncestor.nodeType === Node.TEXT_NODE) {
        closestSpanAncestor =
          closestSpanAncestor.parentElement || closestSpanAncestor;
      }
      const treeWalker = document.createTreeWalker(
        closestSpanAncestor,
        NodeFilter.SHOW_ELEMENT,
        {
          acceptNode: (node) => {
            let closestSpan = node;
            if (range.intersectsNode(closestSpan)) {
              return NodeFilter.FILTER_ACCEPT;
            }
            return NodeFilter.FILTER_REJECT;
          },
        }
      );

      while (treeWalker.nextNode()) {
        const node = treeWalker.currentNode;
        const bbox = (node as Element).getBoundingClientRect();
        const classText = (node as Element).getAttribute("class") || "";
        if (
          classText.includes("react-pdf__Page") ||
          classText.includes("endOfContent")
        ) {
          continue;
        }
        if ((node as Element) === highlightAnchor?.element) {
          elements.push(
            createHighlightBoxesFromElement(
              node as Element,
              isHighlightingBackwards ? 0 : highlightAnchor.offsetPxStartLetter,
              isHighlightingBackwards ? highlightAnchor.offsetPxStartLetter : 0
            )
          );
        } else if ((node as Element) === highlightCurrent?.element) {
          elements.push(
            createHighlightBoxesFromElement(
              node as Element,
              isHighlightingBackwards
                ? highlightCurrent.offsetPxStartLetter
                : 0,
              isHighlightingBackwards ? 0 : highlightCurrent.offsetPxEndLetter
            )
          );
        } else {
          elements.push(createHighlightBoxesFromElement(node as Element));
        }
      }
      return elements;
    },
    [highlightAnchor, highlightCurrent]
  );

  /**
   * Takes the HighlightAnchor, HighlightCurrent from state and creates a range with those elements as the start/end
   * @returns
   */
  const createRangeFromHighlight = () => {
    if (!highlightAnchor.element || !highlightCurrent.element) {
      return null;
    }
    const range = document.createRange();
    try {
      if (isHighlightingBackwards) {
        range.setStart(
          highlightCurrent?.element?.firstChild as Node,
          highlightCurrent.offset
        );
        range.setEnd(
          highlightAnchor?.element?.firstChild as Node,
          highlightAnchor.offset
        );
      } else {
        range.setStart(
          highlightAnchor?.element?.firstChild as Node,
          highlightAnchor.offset
        );
        range.setEnd(
          highlightCurrent?.element?.firstChild as Node,
          highlightCurrent.offset + 1
        );
      }
    } catch (err) {
      console.log("ERR:", err);
      return null;
    }
    return range;
  };

  const handleMouseUp = () => {
    setIsHighlighting(false);
    const range = createRangeFromHighlight();
    if (range) {
      setCitationText({
        match: range.toString(),
        exactMatch: false,
        page: pageNumber,
      });
    }
    setHighlightAnchor({
      element: null,
      offset: -1,
      offsetPxStartLetter: -1,
      offsetPxEndLetter: -1,
    });
    setHighlightCurrent({
      element: null,
      offset: -1,
      offsetPxStartLetter: -1,
      offsetPxEndLetter: -1,
    });
  };

  /**
   * When the mouse is out of bounds on a valid element, find the nearest valid element to it using the cached list of spans sorted by
   * height
   * @param event
   */
  const findNearestValidFocus = (event: MouseEvent) => {
    //In the future we can detect scrolling or anything else that makes the page move
    const allSpans = containerRef.current?.querySelectorAll(
      `span[role='presentation']`
    );
    const firstSpanRect = allSpans?.length
      ? allSpans[0].getBoundingClientRect()
      : false;
    if (
      allSpans?.length !== spansSortedByHeight.length ||
      !firstSpanRect ||
      firstSpanRect.top !== spansSortedByHeight[0].rect.top
    ) {
      setSpansSortedByHeight(getSpansSortedByheight(containerRef));
    }

    const span = findClosestSpanToMouse(event);
    if (span) {
      const offsetIndex = span.textContent?.length;
      setHighlightCurrent({
        element: span,
        offset: offsetIndex ? offsetIndex - 1 : 0,
        offsetPxStartLetter: 0,
        offsetPxEndLetter: 0,
      });
    }
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (!isHighlighting || event.buttons === 0) {
      return;
    }
    event.preventDefault();
    const elementUnderCursor = document.elementFromPoint(
      event.clientX,
      event.clientY
    );
    if (elementUnderCursor && isHighlightable(elementUnderCursor)) {
      const { offsetNum, offsetPxStartLetter, offsetPxEndLetter } =
        getCharIndexUnderMouse(elementUnderCursor as HTMLElement, event);
      let hoveredContainer = elementUnderCursor as Node;

      const anchorElem = highlightAnchor?.element || null;
      if (
        anchorElem === null ||
        (anchorElem?.contains(hoveredContainer as HTMLElement) &&
          hoveredContainer.textContent !== anchorElem?.textContent)
      ) {
        setHighlightAnchor({
          element: hoveredContainer as HTMLElement,
          offset: offsetNum ?? 0,
          offsetPxStartLetter: offsetPxStartLetter ?? 0,
          offsetPxEndLetter: offsetPxEndLetter ?? 0,
        });
      }
      setHighlightCurrent({
        element: hoveredContainer as HTMLElement,
        offset: offsetNum ?? 0,
        offsetPxStartLetter: offsetPxStartLetter ?? 0,
        offsetPxEndLetter: offsetPxEndLetter ?? 0,
      });
      setIsHighlightingBackwards(
        isCurrentBeforeAnchor(highlightAnchor, highlightCurrent)
      );
    } else if (highlightAnchor.element) {
      try {
        findNearestValidFocus(event);
      } catch (e) {
        console.log(e);
      }
    }
    const range = createRangeFromHighlight();
    if (range) {
      const elemsInRange = getElementsInRange(range);
      setHighlightedElements(elemsInRange);
    }
  };
  const handleMouseDown = () => {
    setIsHighlighting(true);
  };
  useEffect(() => {
    const pageDiv = containerRef.current;
    if (pageDiv) {
      pageDiv.addEventListener("mouseup", handleMouseUp);
      pageDiv.addEventListener("mousemove", handleMouseMove);
      pageDiv.addEventListener("mousedown", handleMouseDown);
    }
    return () => {
      if (pageDiv) {
        pageDiv.removeEventListener("mouseup", handleMouseUp);
        pageDiv.removeEventListener("mousemove", handleMouseMove);
        pageDiv.removeEventListener("mousedown", handleMouseDown);
      }
    };
  }, [containerRef, handleMouseUp, handleMouseMove, handleMouseDown]);

  return (
    <PdfHighlighterContext.Provider
      value={{
        onPageRenderSuccess: onPageRenderSuccess,
        pdfPageRefs: pdfPageRefs,
        setPdfPageRefs: setPdfPageRefs,
      }}
    >
      <div
        ref={containerRef}
        style={{ userSelect: "none", position: "relative" }}
      >
        <canvas
          ref={canvasRef}
          style={{ display: "none" }}
          id="pdf-highlight-layer"
        />
        {children}
        {tooltipContent && (
          <HighlightTooltip
            isVisible={
              !isHighlighting &&
              !!citationText?.match.length &&
              highlightedElements.length > 0
            }
            highlightBox={highlightedElementsBoundingRect}
          >
            {tooltipContent}
          </HighlightTooltip>
        )}
      </div>
    </PdfHighlighterContext.Provider>
  );
};

export const usePdfHighlighter = () => {
  return useContext(PdfHighlighterContext);
};
