import { useEffect, useState } from "react";
import { Attestation } from "../../types";
import { getFlowAttestations } from "../../utils/apiCalls";
import { toast } from "sonner";
import { useAuthInfo } from "@propelauth/react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "../../shadcn/components/dialog";
import { Button } from "../../shadcn/components/button";
import { DataTable, HeaderCell } from "../../components/Table";
import { loadAndFormatTime } from "../../utils/format";
import { LoadingView } from "../../components/Loading";

const columns = [
  {
    header: ({ column }: any) => {
      return <HeaderCell column={column} columnName={"User"} />;
    },
    accessorKey: "user_email",
  },
  {
    header: ({ column }: any) => {
      return <HeaderCell column={column} columnName={"Attested"} />;
    },
    accessorKey: "attested",
    cell: ({ row }: any) => {
      return row.original.attested ? (
        <span className="text-green-500">Attested</span>
      ) : (
        <span className="text-red-500">Not Attested</span>
      );
    },
  },
  {
    header: ({ column }: any) => {
      return <HeaderCell column={column} columnName={"Date"} />;
    },
    accessorKey: "updated_at",
    cell: ({ row }: any) => {
      return row.original.attested ? (
        <span>{loadAndFormatTime(row.original.updated_at)}</span>
      ) : (
        <></>
      );
    },
  },
];

export const DocAttestationsDialog = (props: { flowInstanceId: string }) => {
  const authInfo = useAuthInfo();
  const [attestationsLoading, setAttestationsLoading] = useState(false);
  const [attestations, setAttestations] = useState<Attestation[]>([]);

  const totalAttestations = attestations.length;
  const completedAttestations = attestations.filter(
    (attestation) => attestation.attested
  ).length;

  useEffect(() => {
    setAttestationsLoading(true);
    getFlowAttestations(
      props.flowInstanceId,
      authInfo.accessToken ?? null
    ).then((attestations) => {
      if (attestations !== null) {
        setAttestations(attestations);
      } else {
        toast.error("Failed to fetch attestations");
      }
      setAttestationsLoading(false);
    });
  }, [props.flowInstanceId]);

  return (
    <Dialog>
      <DialogTrigger>
        <Button
          variant="outline"
          size="sm"
          disabled={attestationsLoading || totalAttestations === 0}
        >
          Attestations{" "}
          {totalAttestations > 0 &&
            `(${completedAttestations}/${totalAttestations})`}
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-[90%] h-[90%]">
        <DialogTitle>Document Attestations</DialogTitle>
        {!attestationsLoading && (
          <DataTable columns={columns} data={attestations} />
        )}
        {attestationsLoading && (
          <LoadingView customText="Loading attestations..." />
        )}
      </DialogContent>
    </Dialog>
  );
};
