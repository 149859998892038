import { v4 as uuidv4 } from "uuid";
import { useContext, useEffect, useState } from "react";
import ReactFlow, {
  BaseEdge,
  ConnectionLineType,
  Edge,
  EdgeChange,
  EdgeLabelRenderer,
  EdgeProps,
  Handle,
  Node,
  NodeChange,
  Position,
  ReactFlowProvider,
  getStraightPath,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "reactflow";
import dagre from "@dagrejs/dagre";

import "reactflow/dist/base.css";
import { ApprovalFlow, ApprovalStage, ApproverType } from "../../types";
import { UserContext } from "../../contexts/UserContext";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../shadcn/components/select";
import { MultiSelectDialog } from "../../components/MultiSelectControl";
import { Button } from "../../shadcn/components/button";
import { TrashIcon } from "@radix-ui/react-icons";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";

const ButtonEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  data,
  style = {},
  markerEnd,
}: EdgeProps) => {
  const [edgePath, labelX, labelY] = getStraightPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  const onAddClick = () => {
    // split edge id by | and get the first part
    const [startId, endId] = id.split("|");
    const newStage = {
      id: uuidv4(),
      approver_type: "user",
      approver_id: "",
      on_accept: endId === "end" ? null : endId,
      on_reject: startId === "start" ? null : startId,
    };

    data.setChosenApprovalFlow((prev: ApprovalFlow | null) => {
      if (prev) {
        const newFlow = [newStage];
        for (const stage of prev.flow) {
          if (startId === "start" && endId === stage.id) {
            newFlow.push({
              ...stage,
              on_reject: newStage.id,
            });
          } else if (endId === "end" && startId === stage.id) {
            newFlow.push({
              ...stage,
              on_accept: newStage.id,
            });
          } else if (startId === stage.id) {
            newFlow.push({
              ...stage,
              on_accept: newStage.id,
            });
          } else if (endId === stage.id) {
            newFlow.push({
              ...stage,
              on_reject: newStage.id,
            });
          } else {
            newFlow.push(stage);
          }
        }

        return {
          ...prev,
          flow: newFlow,
        };
      }
      return prev;
    });
  };

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            // everything inside EdgeLabelRenderer has no pointer events by default
            // if you have an interactive element, set pointer-events: all
            pointerEvents: "all",
          }}
          className="nodrag nopan"
        >
          <Tooltip>
            <TooltipTrigger>
              <Button variant="default" onClick={onAddClick}>
                +
              </Button>
            </TooltipTrigger>
            <TooltipContent>Add approval stage</TooltipContent>
          </Tooltip>
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

const edgeTypes = {
  buttonedge: ButtonEdge,
};

interface ApprovalStageNodeData {
  approvalStage: ApprovalStage;
  setChosenApprovalFlow: React.Dispatch<
    React.SetStateAction<ApprovalFlow | null>
  >;
}

type TerminalNodeType = "Start" | "End";

const nodeWidth = 300;
const nodeHeight = 200;
const nodeDirection = "LR";
type OnChange<ChangesType> = (changes: ChangesType[]) => void;
const approverTypeOptions: { label: string; value: ApproverType }[] = [
  { label: "User", value: "user" },
  { label: "Department", value: "department" },
];

const TerminalNode = ({ data }: { data: TerminalNodeType }) => {
  return (
    <Button variant="secondary">
      {data}
      <Handle
        type={data === "Start" ? "source" : "target"}
        position={data === "Start" ? Position.Right : Position.Left}
      />
    </Button>
  );
};

const ApprovalStageNode = ({ data }: { data: ApprovalStageNodeData }) => {
  const { departments, simpleUsers } = useContext(UserContext);
  const [dialogOpen, setDialogOpen] = useState(false);

  const selectableItems =
    data.approvalStage.approver_type === "user"
      ? simpleUsers.map((u) => ({ id: u.id, name: u.email }))
      : departments.map((d) => ({ id: d.id, name: d.name }));

  const selectedItem = data.approvalStage.approver_id
    ? [
        selectableItems.find(
          (item) => item.id === data.approvalStage.approver_id
        )!,
      ]
    : [];

  const onDelete = () => {
    data.setChosenApprovalFlow((prev) => {
      if (prev) {
        const newFlow = [];
        for (const stage of prev.flow) {
          if (data.approvalStage.on_accept === stage.id) {
            newFlow.push({
              ...stage,
              on_reject: data.approvalStage.on_reject,
            });
          } else if (data.approvalStage.on_reject === stage.id) {
            newFlow.push({
              ...stage,
              on_accept: data.approvalStage.on_accept,
            });
          } else if (stage.id !== data.approvalStage.id) {
            newFlow.push(stage);
          }
        }
        return {
          ...prev,
          flow: newFlow,
        };
      }
      return prev;
    });
  };

  return (
    <div className="border-2 border-black w-[300px] h-[200px] p-4 space-y-4">
      <div className="flex justify-end">
        <Button variant="destructive" onClick={onDelete}>
          <TrashIcon className="w-4 h-4" />
        </Button>
      </div>
      <Handle type="target" position={Position.Left} />
      <div className="flex justify-center">
        <div className="space-y-4">
          <Select
            value={data.approvalStage.approver_type}
            onValueChange={(approverType: ApproverType) => {
              data.setChosenApprovalFlow((prev) => {
                if (prev) {
                  return {
                    ...prev,
                    flow: [
                      ...prev.flow.filter(
                        (stage) => stage.id !== data.approvalStage.id
                      ),
                      {
                        ...data.approvalStage,
                        approver_type: approverType,
                        approver_id: "",
                      },
                    ],
                  };
                }
                return prev;
              });
            }}
          >
            <SelectTrigger className="bg-white">
              <SelectValue placeholder="Select document to compare.." />
            </SelectTrigger>
            <SelectContent>
              {approverTypeOptions.map((approverTypeOption) => (
                <SelectItem
                  value={approverTypeOption.value}
                  key={approverTypeOption.value}
                >
                  {approverTypeOption.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <MultiSelectDialog
            open={dialogOpen}
            setOpen={setDialogOpen}
            title="Entities"
            items={selectableItems}
            selectedItems={selectedItem}
            selectItem={(item, isSelected) => {
              if (isSelected) {
                data.setChosenApprovalFlow((prev) => {
                  if (prev) {
                    return {
                      ...prev,
                      flow: [
                        ...prev.flow.filter(
                          (stage) => stage.id !== data.approvalStage.id
                        ),
                        {
                          ...data.approvalStage,
                          approver_id: item.id,
                        },
                      ],
                    };
                  }
                  return prev;
                });
              } else {
                data.setChosenApprovalFlow((prev) => {
                  if (prev) {
                    return {
                      ...prev,
                      flow: [
                        ...prev.flow.filter(
                          (stage) => stage.id !== data.approvalStage.id
                        ),
                        {
                          ...data.approvalStage,
                          approver_id: "",
                        },
                      ],
                    };
                  }
                  return prev;
                });
              }
            }}
            clearSelectedItems={() => {
              data.setChosenApprovalFlow((prev) => {
                if (prev) {
                  return {
                    ...prev,
                    flow: [
                      ...prev.flow.filter(
                        (stage) => stage.id !== data.approvalStage.id
                      ),
                      {
                        ...data.approvalStage,
                        approver_id: "",
                      },
                    ],
                  };
                }
                return prev;
              });
            }}
            limitOne={true}
          />
          <Button onClick={() => setDialogOpen(true)} variant="secondary">
            {selectedItem.length > 0 ? selectedItem[0].name : "Select"}
          </Button>
        </div>
      </div>
      <Handle type="source" position={Position.Right} />
    </div>
  );
};

const nodeTypes = {
  approvalStage: ApprovalStageNode,
  terminal: TerminalNode,
};

const getLayoutedElements = (
  nodes: Node<any, string | undefined>[],
  edges: Edge[]
) => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({
    rankdir: nodeDirection,
    ranksep: 200,
  });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = Position.Top;
    node.sourcePosition = Position.Bottom;

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };

    return node;
  });

  return { nodes, edges };
};

const intitialNodeSetup = (approvalStageNode: ApprovalStageNodeData) => {
  return {
    id: approvalStageNode.approvalStage.id,
    type: "approvalStage",
    data: approvalStageNode,
    position: { x: 0, y: 0 },
  };
};

const initialEdgeSetup = (approvalStageNode: ApprovalStageNodeData) => {
  return {
    id: `${approvalStageNode.approvalStage.id}|${approvalStageNode.approvalStage.on_accept}`,
    source: approvalStageNode.approvalStage.id,
    target: approvalStageNode.approvalStage.on_accept!,
    type: "buttonedge",
    animated: true,
    data: {
      setChosenApprovalFlow: approvalStageNode.setChosenApprovalFlow,
    },
  };
};

const FlowView = (props: {
  nodes: Node<ApprovalStageNodeData, string | undefined>[];
  edges: Edge[];
  onNodesChange: OnChange<NodeChange>;
  onEdgesChange: OnChange<EdgeChange>;
}) => {
  const { fitView } = useReactFlow();

  useEffect(() => {
    fitView();
  }, [props.nodes, props.edges]);

  return (
    <ReactFlow
      onNodesChange={props.onNodesChange}
      onEdgesChange={props.onEdgesChange}
      nodes={props.nodes}
      edges={props.edges}
      fitView
      connectionLineType={ConnectionLineType.SmoothStep}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
    />
  );
};

export const ApprovalFlowBuilder = (props: {
  approvalStages: ApprovalStage[];
  setChosenApprovalFlow: React.Dispatch<
    React.SetStateAction<ApprovalFlow | null>
  >;
}) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  useEffect(() => {
    const newNodes = [
      {
        id: "start",
        type: "terminal",
        data: "Start",
        position: { x: 0, y: 0 },
      },
      {
        id: "end",
        type: "terminal",
        data: "End",
        position: { x: 0, y: 0 },
      },
      ...props.approvalStages.map((stage) =>
        intitialNodeSetup({
          approvalStage: stage,
          setChosenApprovalFlow: props.setChosenApprovalFlow,
        })
      ),
    ];

    let newEdges: Edge[] = [];
    if (newNodes.length == 2) {
      newEdges = [
        {
          id: `start|end`,
          source: "start",
          target: "end",
          type: "buttonedge",
          animated: true,
          data: {
            setChosenApprovalFlow: props.setChosenApprovalFlow,
          },
        },
      ];
    } else {
      newEdges = [
        ...props.approvalStages
          .filter((stage) => stage.on_accept)
          .map((stage) =>
            initialEdgeSetup({
              approvalStage: stage,
              setChosenApprovalFlow: props.setChosenApprovalFlow,
            })
          ),
        {
          id: `start|${
            props.approvalStages.find((stage) => stage.on_reject === null)?.id
          }`,
          source: "start",
          target: props.approvalStages.find((stage) => stage.on_reject === null)
            ?.id!,
          type: "buttonedge",
          animated: true,
          data: {
            setChosenApprovalFlow: props.setChosenApprovalFlow,
          },
        },
        {
          id: `${
            props.approvalStages.find((stage) => stage.on_accept === null)?.id
          }|end`,
          source: props.approvalStages.find((stage) => stage.on_accept === null)
            ?.id!,
          target: "end",
          type: "buttonedge",
          animated: true,
          data: {
            setChosenApprovalFlow: props.setChosenApprovalFlow,
          },
        },
      ];
    }

    const { nodes: formattedNodes, edges: formattedEdges } =
      getLayoutedElements(newNodes, newEdges);

    setNodes(formattedNodes);
    setEdges(formattedEdges);
  }, [props.approvalStages]);

  return (
    <div className="w-full h-full">
      <ReactFlowProvider>
        <FlowView
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
        />
      </ReactFlowProvider>
    </div>
  );
};
