export interface RelevantQuote {
  start_index: number;
  end_index: number;
}

export interface DocType {
  id: string;
  name: string;
  short_display_name: string;
  agency: string;
  url: string;
  external: boolean;
  editable: boolean;
  reg_doc_gap: boolean;
  last_updated_at: string | null;
}

export interface SearchResult {
  original_rank: number;
  final_rank: number;
  search_doc_id: string;
  relevant_quote: RelevantQuote | null;
  doc_type: DocType;
  doc_name: string;
  text: string;
  additional_info: string | null;
  page: number | null;
  doc_id: string;
  score: number;
}

export interface Permission {
  doc_type: DocType;
  enabled: boolean;
  organization_doc_id: string;
}

export interface ManagedUser {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  role: string;
  picture_url: string;
  permissions: Permission[];
}

export interface SimpleUser {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
}

export type DocRelationshipType = "supersedes" | "attachment" | "revision";

export interface Relationship {
  id: string;
  id_type: "doc" | "regulatory_doc";
  relationship_type: DocRelationshipType;
  direction: "primary" | "secondary";
  name: string;
}

export interface RegulatoryDoc {
  id: string;
  doc_type: DocType;
  doc_identifier: string;
  title: string;
  date: string;
  metadata_: any;
  doc_id: string;
  overview_items: RegulatoryDocOverviewItem[];
  requirements: Requirement[];
  relationships: Relationship[];
  relevant: boolean;
}

export interface PaginationOutput {
  total_pages: number;
  total_records: number;
  current_page: number;
  next_page: number | null;
  previous_page: number | null;
}

export interface RelevantSection {
  page: number;
  text: string;
  start_index: number;
  end_index: number;
}

type SearchDocResultType = "filename" | "content";

export interface SearchDocName {
  id: string | null;
  doc_id: string;
  name: string;
  doc_type_name: string;
  additional_metadata: Record<string, string>;
  result_type: SearchDocResultType;
  citation: Citation | null;
}

export type ModelChatType = "system" | "user" | "assistant";

export interface Citation {
  id: string | null;
  page: number;
  text: string;
  formatted_text: string | null;
  doc_id?: string;
  doc_name?: string;
  created_at: string | null;
  user: SimpleUser | null;
  start_index: number | null;
}

export interface ModelChat {
  role: ModelChatType;
  content: string;
}

export interface SpecificDocSearchResult {
  doc_id: string;
  doc_name: string;
  answer: string;
}

export interface SearchDocumentResponse {
  query: string;
  search_id: string;
  error: string;
  search_results: SearchResult[];
  filter_: string[] | null;
  query_suggestions: string[];
  summary: string | null;
  search_doc_names: SearchDocName[];
  data_source_suggestions: DocType[];
  web_search_result: string | null;
  specific_doc_search_result: SpecificDocSearchResult | null;
}

export interface DataSource {
  doc_type: DocType;
  enabled: boolean;
  external: boolean;
}

export interface ActionItem {
  id: string;
  text: string;
  citations: Citation[];
  status: TaskStatus;
  assignees: Department[];
  text_updated_at: string;
  text_updated_by: SimpleUser;
  status_updated_at: string;
  status_updated_by: SimpleUser;
  created_at: string;
  notes: Note[];
  requirement_id: string;
  updated_at: string;
  reference_doc_types: string[] | null;
  impacted_doc_types?: string[] | null;
  reference_documents: Citation[];
}

export type RequirementTag =
  | "no_substantive_changes"
  | "informational_only"
  | "actionable"
  | "not_applicable";

export interface Requirement {
  id: string | null;
  text: string;
  citations: Citation[];
  action_items: ActionItem[];
  tag: RequirementTag | null;
  impacted_documents: Citation[];
  reference_documents: Citation[];
  assignees: Department[];
  updated_at: string;
  reference_doc_types: string[] | null;
  impacted_doc_types?: string[] | null;
}

export interface RegulatoryDocOverviewItem {
  name: string;
  value: string;
  citation: Citation;
}

export interface RegulatoryDocOverview {
  overview_items: RegulatoryDocOverviewItem[];
  requirements: Requirement[];
}

export interface DocToView {
  docId: string;
  historyId?: string;
}

export interface NoteReaction {
  reaction: string | null;
  user: SimpleUser;
}

export interface Note {
  id: string;
  note: string;
  user: SimpleUser;
  created_at: string;
  reactions: NoteReaction[];
}

export interface AuditQuestionAnswer {
  id: string;
  narrative: string;
  notes: Note[];
  status: TaskStatus;
  citations: Citation[];
  narrative_updated_at: string;
  narrative_updated_by: SimpleUser;
  status_updated_at: string;
  status_updated_by: SimpleUser;
}

export interface AuditQuestion {
  id: string;
  question: string;
  question_index: string;
  section_index: string;
  section_title: string;
  assignments: SimpleUser[];
}

export interface AuditQuestionMetadata extends AuditQuestion {
  status: TaskStatus;
  updated_at: string | null;
  answer_id: string | null;
  answer_to_copy: string | null;
}

export interface Audit {
  id: string;
  name: string;
}

export interface QuestionAnswerId {
  question_id: string;
  answer_id: string;
}

export interface Insight {
  id: string;
  name: string;
}

export interface PageMatch {
  page: number;
  count: number;
  id: string;
}

export interface ExactMatches {
  page_matches: PageMatch[];
  total: number;
}

export interface CitationText {
  match: string;
  exactMatch: boolean;
  page: number;
}

export type Feature =
  | "search"
  | "regulatory_doc"
  | "audit"
  | "insight"
  | "policy_repo"
  | "gap_analysis"
  | "dashboard";

export interface FileIndex {
  type: "file" | "folder";
  children: FileIndex[];
  name: string;
  id: string | null;
  doc_type_name: string;
  regulatory_doc_id: string | null;
}

export interface Department {
  id: string;
  name: string;
}

export type ApproverType = "user" | "department";

export interface ApprovalStage {
  id: string;
  approver_type: ApproverType;
  approver_id: string;
  on_accept: string | null;
  on_reject: string | null;
}

export interface WorkflowApprovalStatus {
  approval_stage: ApprovalStage | null;
  allowed_user_emails: string[];
  can_start: boolean;
}

export interface DocMetadata {
  id: string;
  regulatory_doc_id: string | null;
  editable_doc_id: string | null;
  approval_flow_id: string | null;
  flow_instance_id: string | null;
  doc_source: string | null;
  base_doc_id: string | null;
  name: string;
}

export interface DocHistory {
  id: string;
  initially_active: string | null;
  last_active: string | null;
  name: string;
  created_at: string;
  active?: boolean;
}

type ApprovalEventType =
  | "approve"
  | "reject"
  | "skip"
  | "cancel"
  | "failed"
  | "start";

interface ApprovalEventPayload {
  name: ApprovalEventType;
  approver_type: ApproverType;
  approver_id: string;
}

export interface ApprovalEvent {
  id: string;
  flow_instance_id: string;
  doc_id: string;
  event: ApprovalEventPayload;
  approval_flow_id: string;
  user: SimpleUser;
  updated_at: string;
}

export interface DocDiff {
  page: number;
  diffs: string[];
  page_missing_in_base: boolean;
  page_missing_in_compare: boolean;
}

export interface FlowSchedule {
  schedule_start_date: string;
  schedule_interval_unit: "months" | "years";
  schedule_interval_amount: number;
  schedule_instance_id: string | null;
}

export interface ApprovalConfig {
  schedule: FlowSchedule | null;
  attestation: boolean;
  flow_id: string | null;
}

export interface PolicyRepoDoc {
  doc_id: string;
  name: string;
  relevant_date: string;
}

export interface PolicyRepoOverview {
  recently_updated_docs: PolicyRepoDoc[];
  currently_processing_docs: PolicyRepoDoc[];
  user_action_docs: PolicyRepoDoc[];
  upcoming_docs: PolicyRepoDoc[];
  attestations: Attestation[];
}

export interface RecentlyViewedDoc {
  doc_id: string;
  name: string;
  doc_type_name: string;
  date: string;
}

export type TaskStatus = "todo" | "review" | "done";

export interface Attestation {
  id: string;
  flow_instance_id: string;
  user: SimpleUser;
  doc_id: string;
  doc_name: string;
  attested: boolean;
  updated_at: string;
}

export interface ApprovalFlow {
  id: string;
  name: string;
  flow: ApprovalStage[];
}

export interface PolicyRepoError {
  error: string;
  citation: Citation;
}

export const statusToLabelMap: Record<TaskStatus, string> = {
  todo: "To Do",
  review: "Review",
  done: "Done",
};

export const tagToLabelMap: Record<RequirementTag, string> = {
  not_applicable: "Not Applicable",
  no_substantive_changes: "No Substantive Changes",
  informational_only: "Informational Only",
  actionable: "Actionable",
};

export interface PreviousSearch {
  id: string;
  query: string;
  timestamp: string;
}

export interface GapAnalysis {
  id: string;
  name: string;
  doc_id: string;
  doc_name: string;
  doc_type_name: string;
  created_at: string;
  updated_at: string;
}

export interface AddCitationResponse {
  citation_ids: string[];
  citation_start_indices: number[];
  departments: Department[];
}

export type ChatMessageType =
  | "message"
  | "definition"
  | "clarify"
  | "linked_document"
  | "impacted_policies";

export interface ChatHistoryMetadata {
  chat_id: string;
  doc_name: string;
  doc_id: string;
  updated_at: string;
  doc_type_name: string;
}
