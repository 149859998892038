import React, { forwardRef, Ref } from "react";

interface IconProps extends React.SVGProps<SVGSVGElement> {}

export const InboxStackIcon = forwardRef<SVGSVGElement, IconProps>(
  (props, ref) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="size-6"
      ref={ref}
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m7.875 14.25 1.214 1.942a2.25 2.25 0 0 0 1.908 1.058h2.006c.776 0 1.497-.4 1.908-1.058l1.214-1.942M2.41 9h4.636a2.25 2.25 0 0 1 1.872 1.002l.164.246a2.25 2.25 0 0 0 1.872 1.002h2.092a2.25 2.25 0 0 0 1.872-1.002l.164-.246A2.25 2.25 0 0 1 16.954 9h4.636M2.41 9a2.25 2.25 0 0 0-.16.832V12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 12V9.832c0-.287-.055-.57-.16-.832M2.41 9a2.25 2.25 0 0 1 .382-.632l3.285-3.832a2.25 2.25 0 0 1 1.708-.786h8.43c.657 0 1.281.287 1.709.786l3.284 3.832c.163.19.291.404.382.632M4.5 20.25h15A2.25 2.25 0 0 0 21.75 18v-2.625c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125V18a2.25 2.25 0 0 0 2.25 2.25Z"
      />
    </svg>
  )
);

export const HighlighterIcon = forwardRef<SVGSVGElement, IconProps>(
  (props, ref) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      ref={ref}
      {...props}
    >
      <path
        fill="var(--ci-primary-color, currentColor)"
        d="M398.789,22.31a31.762,31.762,0,0,0-22.771-9.52H376a31.769,31.769,0,0,0-22.783,9.552L87.534,292.234a32.086,32.086,0,0,0,.177,45.076l14.7,14.7L16,439.427V478H122.8l52.8-52.8,12.479,12.48a32,32,0,0,0,46-.77L492.313,160.77a31.913,31.913,0,0,0-.6-44.339ZM109.548,446H54.5l46.552-47.1,27.8,27.8Zm101.16-30.946L175.6,379.947l-24.127,24.127-27.932-27.932,23.986-24.269-37.191-37.189,48.338-49.105L257.8,364.7Zm68.958-73.74-98.541-98.54L376.017,44.791l92.923,94.121Z"
        className="ci-primary"
      />
    </svg>
  )
);
