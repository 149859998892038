import { useContext, useEffect, useState } from "react";
import { DocType, FileIndex, SearchDocName } from "../types";
import { FileIcon, FolderClosed, FolderOpen } from "lucide-react";
import { getDocTypes, getFileIndex } from "../utils/apiCalls";
import { useAuthInfo } from "@propelauth/react";
import { toast } from "sonner";
import { ReloadIcon } from "@radix-ui/react-icons";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../shadcn/components/dialog";
import { Button } from "../shadcn/components/button";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "../shadcn/components/resizable";
import { DocViewerCitation } from "./DocViewer";
import { cn } from "../shadcn/lib/utils";
import { DocViewerContext } from "../contexts/DocViewerContext";
import { toggleAtlasWidget } from "../utils/cookies";

const FolderDisplay = (props: {
  folderChildren: FileIndex[];
  name: string;
  onSelect: (fileIndex: FileIndex) => void;
  parentLevel: number;
  onOpenCallback?: () => Promise<boolean>;
  loading?: boolean;
  activeDocId: string | null;
}) => {
  const [open, setOpen] = useState(false);
  const newLevel = props.parentLevel + 1;

  const onOpen = async () => {
    if (!open && props.onOpenCallback) {
      const success = await props.onOpenCallback();
      if (success) {
        setOpen(true);
      }
    } else {
      setOpen(!open);
    }
  };

  return (
    <>
      <div
        className={`flex items-center space-x-2 text-left py-2 w-full cursor-pointer hover:bg-gray-100 pl-${
          newLevel * 2
        }`}
        onClick={onOpen}
      >
        {open ? (
          <FolderOpen className="h-4 w-4 mr-2 flex-shrink-0" />
        ) : (
          <FolderClosed className="h-4 w-4 mr-2 flex-shrink-0" />
        )}
        {decodeURI(props.name)}{" "}
        {props.loading === true && (
          <ReloadIcon className="animate-spin h-4 w-4" />
        )}
      </div>
      {open &&
        props.folderChildren
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((child) => (
            <FileFolderDisplay
              key={`${child.name}-${child.id}`}
              fileIndex={child}
              onSelect={props.onSelect}
              parentLevel={newLevel}
              activeDocId={props.activeDocId}
            />
          ))}
    </>
  );
};

const FileDisplay = (props: {
  fileIndex: FileIndex;
  onSelect: (fileIndex: FileIndex) => void;
  parentLevel: number;
  activeDocId: string | null;
}) => {
  const newLevel = props.parentLevel + 1;
  const { setPageNumber } = useContext(DocViewerContext);
  return (
    <div
      className={cn(
        `flex items-center space-x-2 text-left py-2 w-full pl-${newLevel * 2}`,
        props.activeDocId === props.fileIndex.id
          ? "bg-black text-white"
          : "hover:bg-gray-100 cursor-pointer"
      )}
      onClick={() => {
        props.onSelect(props.fileIndex);
        setPageNumber(1);
      }}
    >
      <FileIcon className="h-4 w-4 mr-2 flex-shrink-0" />
      <div className="truncate">{props.fileIndex.name}</div>
    </div>
  );
};

const FileFolderDisplay = (props: {
  fileIndex: FileIndex;
  onSelect: (fileIndex: FileIndex) => void;
  parentLevel: number;
  activeDocId: string | null;
}) => {
  switch (props.fileIndex.type) {
    case "folder":
      return (
        <FolderDisplay
          folderChildren={props.fileIndex.children}
          name={props.fileIndex.name}
          onSelect={props.onSelect}
          parentLevel={props.parentLevel + 1}
          activeDocId={props.activeDocId}
        />
      );
    case "file":
      return (
        <FileDisplay
          fileIndex={props.fileIndex}
          onSelect={props.onSelect}
          parentLevel={props.parentLevel + 1}
          activeDocId={props.activeDocId}
        />
      );
  }
};

const DocTypeFolderDisplay = (props: {
  docType: DocType;
  onSelect: (fileIndex: FileIndex) => void;
  activeDocId: string | null;
}) => {
  const auth = useAuthInfo();
  const [fileIndex, setFileIndex] = useState<FileIndex | null>(null);
  const [loadingIndex, setLoadingIndex] = useState(false);

  const onClick = async () => {
    let success = true;
    if (fileIndex === null) {
      setLoadingIndex(true);
      const response = await getFileIndex(
        props.docType.id,
        auth.accessToken ?? null
      );
      if (response !== null) {
        setFileIndex(response);
      } else {
        success = false;
        toast.error("Failed to fetch file index");
      }
      setLoadingIndex(false);
    }
    return success;
  };

  return (
    <FolderDisplay
      folderChildren={fileIndex?.children ?? []}
      name={props.docType.name}
      onSelect={props.onSelect}
      onOpenCallback={onClick}
      loading={loadingIndex}
      parentLevel={-1}
      activeDocId={props.activeDocId}
    />
  );
};

export const FileSelectorDialog = (props: {
  onItemSelect: (result: SearchDocName) => void;
  docTypeIds: string[];
  hideAtlasWidget?: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const authInfo = useAuthInfo();
  const [docTypes, setDocTypes] = useState<DocType[]>([]);
  const [activeDoc, setActiveDoc] = useState<FileIndex | null>(null);

  useEffect(() => {
    getDocTypes(authInfo.accessToken ?? null).then((docTypes) => {
      if (docTypes !== null) {
        setDocTypes(docTypes);
      } else {
        toast.error("There was an error fetching the document types");
      }
    });
  }, []);

  return (
    <Dialog
      open={open}
      onOpenChange={(dOpen) => {
        setOpen(dOpen);
        if (props.hideAtlasWidget) {
          toggleAtlasWidget(dOpen);
        }
      }}
    >
      <DialogTrigger asChild>
        <Button variant="ghost" size="icon">
          <FolderOpen className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-[90%] h-[90%]">
        <DialogHeader>
          <div className="flex items-center justify-between">
            <DialogTitle>File Explorer</DialogTitle>
            <Button
              variant="default"
              onClick={() => {
                if (activeDoc) {
                  props.onItemSelect({
                    id: null,
                    doc_id: activeDoc.id!,
                    name: activeDoc.name,
                    doc_type_name: activeDoc.doc_type_name,
                    additional_metadata: {
                      regulatory_doc_id: activeDoc.regulatory_doc_id,
                    },
                    result_type: "filename",
                    citation: null,
                  } as SearchDocName);
                  setOpen(false);
                }
              }}
              className="mr-5"
              disabled={activeDoc === null}
            >
              Select
            </Button>
          </div>
        </DialogHeader>
        <ResizablePanelGroup direction="horizontal">
          <ResizablePanel
            defaultSize={30}
            minSize={25}
            maxSize={50}
            id="item-navigation-panel"
            order={2}
          >
            <div className="h-[calc(100vh-185px)] overflow-y-auto">
              {docTypes
                .filter(
                  (docType) =>
                    props.docTypeIds.includes(docType.id) ||
                    props.docTypeIds.length === 0
                )
                .map((docType) => (
                  <DocTypeFolderDisplay
                    key={docType.id}
                    docType={docType}
                    onSelect={setActiveDoc}
                    activeDocId={activeDoc?.id ?? null}
                  />
                ))}
            </div>
          </ResizablePanel>
          <ResizableHandle withHandle />
          <ResizablePanel
            defaultSize={70}
            minSize={50}
            maxSize={70}
            id="doc-panel"
            order={3}
          >
            {activeDoc?.id && (
              <DocViewerCitation
                docId={activeDoc.id}
                className="h-[calc(100vh-285px)]"
                hideAtlasWidget={props.hideAtlasWidget}
              />
            )}
          </ResizablePanel>
        </ResizablePanelGroup>
      </DialogContent>
    </Dialog>
  );
};
