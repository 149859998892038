import { Requirement } from "../../types";

const truncateText = (text: string, maxLength: number) => {
  if (text.length <= maxLength) {
    return text;
  }
  const truncated = text.slice(0, maxLength);
  const lastSpaceIndex = truncated.lastIndexOf(" ");
  return `${truncated.slice(0, lastSpaceIndex)}...`;
};

export const RequirementDisplay = (props: {
  requirement: Requirement;
  truncated?: boolean;
}) => {
  const textToDisplay =
    props.requirement.citations[0]?.formatted_text ??
    props.requirement.citations[0]?.text ??
    "";

  return (
    <>
      <span className="text-gray-400">
        pg {props.requirement.citations[0]?.page ?? 1} |&nbsp;&nbsp;
      </span>
      {props.truncated
        ? truncateText(textToDisplay.trim(), 120)
        : textToDisplay.trim()}
    </>
  );
};
