import { DocHistory } from "../../types";
import { DocApprovalEventView } from "./DocApprovalView";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../shadcn/components/accordion";
import { loadAndFormatTime } from "../../utils/format";

export const DocHistoryTitle = (props: { docHistory: DocHistory }) => {
  const active =
    props.docHistory.initially_active !== null &&
    props.docHistory.last_active === null;

  return (
    <div className="text-left space-y-2">
      {active && <div className="text-green-500">Active Policy</div>}
      {props.docHistory.initially_active && (
        <div className="text-gray-500">
          Initially Active{" "}
          {loadAndFormatTime(props.docHistory.initially_active)}
        </div>
      )}
      {props.docHistory.last_active && (
        <span className="text-gray-500">
          Last Active {loadAndFormatTime(props.docHistory.last_active)}
        </span>
      )}
      {props.docHistory.initially_active === null &&
        props.docHistory.last_active === null && (
          <span>
            {props.docHistory.active ? (
              <span className="text-blue-500">Active Flow</span>
            ) : (
              <span className="text-red-500">Terminated Flow</span>
            )}{" "}
            <span className="text-gray-500">
              {loadAndFormatTime(props.docHistory.created_at)}
            </span>
          </span>
        )}
    </div>
  );
};

const DocHistoryItem = (props: {
  editableDocId: string;
  docHistory: DocHistory;
}) => {
  return (
    <AccordionItem value={props.docHistory.id}>
      <AccordionTrigger>
        <DocHistoryTitle docHistory={props.docHistory} />
      </AccordionTrigger>
      <AccordionContent>
        <DocApprovalEventView
          editableDocId={props.editableDocId}
          flowInstanceId={props.docHistory.id}
        />
      </AccordionContent>
    </AccordionItem>
  );
};

export const DocHistoryView = (props: {
  editableDocId: string;
  activeHistoryId: string | undefined;
  setActiveHistoryId: (value: string) => void;
  docHistory: DocHistory[];
}) => {
  return (
    <>
      {props.docHistory.length > 0 && (
        <Accordion
          type="single"
          collapsible
          className="w-full"
          value={props.activeHistoryId}
          onValueChange={props.setActiveHistoryId}
        >
          {props.docHistory.map((history) => (
            <DocHistoryItem
              key={history.id}
              editableDocId={props.editableDocId}
              docHistory={history}
            />
          ))}
        </Accordion>
      )}
      {props.docHistory.length === 0 && <div>No history</div>}
    </>
  );
};
