import Markdown from "react-markdown";
import { Badge } from "../shadcn/components/badge";
import remarkGfm from "remark-gfm";
import { extractTag } from "../utils/format";

export const MarkdownCitationDisplay = (props: {
  text: string;
  clickableCitationIds: string[];
  onCitationClick: (citationId: string) => void;
}) => {
  const { text, onCitationClick, clickableCitationIds } = props;
  let fmtText = text;

  const citationIdsRaw = extractTag(text, "citation");
  const citationIds = citationIdsRaw
    .map((citationId) => citationId.split(","))
    .flatMap((citation) => citation);

  citationIds
    .filter((citationId) => citationId.length > 0)
    .map((citationId, i) => {
      const regex = new RegExp(`(${citationId})(?!~)`);
      fmtText = fmtText.replace(regex, `~${i + 1}<>$1~`);
    });

  fmtText = fmtText.replaceAll("<citation>", "");
  fmtText = fmtText.replaceAll("</citation>", "");
  fmtText = fmtText.replaceAll("~,~", "~ ~");

  return (
    <div className="rounded-lg border bg-card text-card-foreground shadow-sm p-4">
      <Markdown
        className="prose"
        remarkPlugins={[remarkGfm]}
        components={{
          del: ({ node, ...props }) => {
            const [index, citationId] = (props.children as string).split("<>");
            const cleanCitationId = citationId.trim();

            const badge = (
              <Badge
                variant="default"
                className="text-xs hover:bg-gray-100 hover:text-black cursor-pointer"
                onClick={() => {
                  if (clickableCitationIds.includes(cleanCitationId)) {
                    onCitationClick(cleanCitationId);
                  }
                }}
              >
                {index}
              </Badge>
            );

            if (clickableCitationIds.includes(cleanCitationId)) {
              return badge;
            }
            return (
              <a href={`/doc-chat?citationId=${cleanCitationId}`}>{badge}</a>
            );
          },
        }}
      >
        {fmtText}
      </Markdown>
    </div>
  );
};
