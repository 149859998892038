import { v4 as uuidv4 } from "uuid";
import { useContext, useEffect, useState } from "react";
import {
  Department,
  RegulatoryDoc,
  RegulatoryDocOverviewItem,
} from "../../types";
import { useAuthInfo } from "@propelauth/react";
import {
  createRegulatoryDocOverview,
  updateOverviewItems,
} from "../../utils/apiCalls";
import { toast } from "sonner";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../shadcn/components/card";
import { Button } from "../../shadcn/components/button";
import { Pencil1Icon, PlusIcon, ReloadIcon } from "@radix-ui/react-icons";
import { format } from "date-fns";
import { DocViewerContext } from "../../contexts/DocViewerContext";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "../../shadcn/components/resizable";
import { DocViewerCitation } from "../../components/DocViewer";
import { UserContext } from "../../contexts/UserContext";
import { Textarea } from "../../shadcn/components/textarea";
import { TrashIcon } from "lucide-react";
import { cn } from "../../shadcn/lib/utils";

const OverviewSection = (props: {
  regulatoryDoc: RegulatoryDoc;
  item: RegulatoryDocOverviewItem;
  setEnableHighlight: React.Dispatch<React.SetStateAction<boolean>>;
  activeItem: string | null;
  setActiveItem: React.Dispatch<React.SetStateAction<string | null>>;
  setRegulatoryDoc: React.Dispatch<React.SetStateAction<RegulatoryDoc | null>>;
}) => {
  const authInfo = useAuthInfo();
  const { isAgent } = useContext(UserContext);
  const [editing, setEditing] = useState(false);
  const [editingValue, setEditingValue] = useState(props.item.value);
  const { citationText, pageNumber, setCitationText, setPageNumber } =
    useContext(DocViewerContext);
  const [saveLoading, setSaveLoading] = useState(false);

  const onSave = async () => {
    setSaveLoading(true);
    const newCitation = {
      id: null,
      page: pageNumber,
      text: citationText?.match ?? "",
      formatted_text: null,
      created_at: null,
      user: null,
      start_index: null,
      doc_id: props.regulatoryDoc.doc_id,
      doc_name: "",
    };
    const response = await updateOverviewItems(
      props.regulatoryDoc.id,
      [
        {
          name: props.item.name,
          value: editingValue,
          citation: newCitation,
        } as RegulatoryDocOverviewItem,
      ],
      [],
      authInfo.accessToken ?? ""
    );
    if (response) {
      setEditing(false);
      props.setRegulatoryDoc((prev) => {
        if (prev) {
          return {
            ...prev,
            overview_items: prev.overview_items.map((item) =>
              item.name === props.item.name
                ? { ...item, value: editingValue, citation: newCitation }
                : item
            ),
          };
        }
        return null;
      });
    } else {
      toast.error("Failed to save changes");
      setSaveLoading(false);
    }
    setSaveLoading(false);
  };

  useEffect(() => {
    if (editing) {
      props.setEnableHighlight(true);
      setEditingValue(props.item.value);
    } else {
      props.setEnableHighlight(false);
    }
  }, [editing]);

  return (
    <div className="space-y-2">
      <div className="flex justify-between">
        <div className="font-semibold text-lg text-gray-500">
          {props.item.name.charAt(0).toUpperCase() + props.item.name.slice(1)}
        </div>
        {isAgent && !editing && (
          <Button variant="ghost" size="icon" onClick={() => setEditing(true)}>
            <Pencil1Icon className="h-4 w-4" />
          </Button>
        )}
        {isAgent && editing && (
          <div className="flex items-center space-x-2">
            <Button variant="default" size="sm" onClick={onSave}>
              Save
              {saveLoading && (
                <ReloadIcon className="ml-2 h-4 w-4 animate-spin" />
              )}
            </Button>
            <Button
              variant="destructive"
              size="sm"
              onClick={() => setEditing(false)}
            >
              Cancel
            </Button>
          </div>
        )}
      </div>
      {editing ? (
        <Textarea
          value={editingValue}
          onChange={(e) => setEditingValue(e.target.value)}
        />
      ) : (
        <div
          className={cn(
            "text-left gap-4 p-3 w-full rounded-md shadow-md cursor-pointer hover:bg-gray-100",
            props.activeItem === props.item.name && "bg-gray-100"
          )}
          onClick={() => {
            setPageNumber(props.item.citation.page);
            setCitationText({
              match: props.item.citation.text,
              exactMatch: false,
              page: props.item.citation.page,
            });
            props.setActiveItem(props.item.name);
          }}
        >
          {props.item.value}
        </div>
      )}
    </div>
  );
};

const SummaryCard = (props: {
  docName: string;
  overviewItems: RegulatoryDocOverviewItem[];
  setEnableHighlight: React.Dispatch<React.SetStateAction<boolean>>;
  activeItem: string | null;
  setActiveItem: React.Dispatch<React.SetStateAction<string | null>>;
  regulatoryDoc: RegulatoryDoc;
  setRegulatoryDoc: React.Dispatch<React.SetStateAction<RegulatoryDoc | null>>;
}) => {
  let overviewContent = <></>;
  const sectionBaseProps = {
    setEnableHighlight: props.setEnableHighlight,
    activeItem: props.activeItem,
    setActiveItem: props.setActiveItem,
    regulatoryDoc: props.regulatoryDoc,
    setRegulatoryDoc: props.setRegulatoryDoc,
  };

  switch (props.docName) {
    case "DHCS APL":
      const purposeDhcs = props.overviewItems.find(
        (item) => item.name === "purpose"
      );
      const background = props.overviewItems.find(
        (item) => item.name === "background"
      );
      const policy = props.overviewItems.find((item) => item.name === "policy");
      overviewContent = (
        <>
          {purposeDhcs && (
            <OverviewSection item={purposeDhcs} {...sectionBaseProps} />
          )}
          {background && (
            <OverviewSection item={background} {...sectionBaseProps} />
          )}
          {policy && <OverviewSection item={policy} {...sectionBaseProps} />}
        </>
      );
      break;
    case "DMHC APL":
      const purposeDmhc = props.overviewItems.find(
        (item) => item.name === "purpose"
      );
      overviewContent = (
        <>
          {purposeDmhc && (
            <OverviewSection item={purposeDmhc} {...sectionBaseProps} />
          )}
        </>
      );
      break;
    case "HPMS Memo":
      const purposeHpms = props.overviewItems.find(
        (item) => item.name === "purpose"
      );
      overviewContent = (
        <>
          {purposeHpms && (
            <OverviewSection item={purposeHpms} {...sectionBaseProps} />
          )}
        </>
      );
      break;
  }

  return (
    <Card className="flex-grow">
      <CardHeader>
        <CardTitle>Summary</CardTitle>
      </CardHeader>
      <CardContent className="space-y-6">{overviewContent}</CardContent>
    </Card>
  );
};

const WhatChangedCard = (props: {
  regulatoryDoc: RegulatoryDoc;
  overviewItems: RegulatoryDocOverviewItem[];
  activeItem: string | null;
  setActiveItem: React.Dispatch<React.SetStateAction<string | null>>;
  setEnableHighlight: React.Dispatch<React.SetStateAction<boolean>>;
  setRegulatoryDoc: React.Dispatch<React.SetStateAction<RegulatoryDoc | null>>;
}) => {
  const authInfo = useAuthInfo();
  const { isAgent } = useContext(UserContext);
  const [editing, setEditing] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const { citationText, pageNumber, setCitationText, setPageNumber } =
    useContext(DocViewerContext);
  const [editItems, setEditItems] = useState<RegulatoryDocOverviewItem[]>(
    props.overviewItems
  );

  const itemsToDisplay = editing && isAgent ? editItems : props.overviewItems;

  const onSave = async () => {
    setSaveLoading(true);
    const response = await updateOverviewItems(
      props.regulatoryDoc.id,
      editItems,
      // find any props.overviewitems that don't exist in editItems and delete them, just look at name
      props.overviewItems.filter(
        (item) => !editItems.map((i) => i.name).includes(item.name)
      ),
      authInfo.accessToken ?? ""
    );
    if (response) {
      setEditing(false);
      props.setRegulatoryDoc((prev) => {
        if (prev) {
          return {
            ...prev,
            overview_items: [
              ...prev.overview_items.filter(
                (item) => !item.name.startsWith("change_")
              ),
              ...editItems,
            ],
          };
        }
        return null;
      });
    } else {
      toast.error("Failed to save changes");
      setSaveLoading(false);
    }
    setSaveLoading(false);
  };

  useEffect(() => {
    if (editing) {
      props.setEnableHighlight(true);
      setEditItems(props.overviewItems);
    } else {
      props.setEnableHighlight(false);
    }
  }, [editing]);

  useEffect(() => {
    if (editing && isAgent && citationText?.match && props.activeItem) {
      setEditItems((prev) =>
        prev.map((item) =>
          item.name === props.activeItem
            ? {
                ...item,
                citation: {
                  ...item.citation,
                  page: pageNumber,
                  text: citationText.match,
                  id: null,
                },
              }
            : item
        )
      );
    }
  }, [citationText]);

  return (
    <Card className="flex-grow text-orange-600">
      <CardHeader>
        <div className="flex items-center justify-between">
          <CardTitle>What Changed?</CardTitle>
          {isAgent && !editing && (
            <Button
              variant="ghost"
              size="icon"
              onClick={() => setEditing(true)}
            >
              <Pencil1Icon className="h-4 w-4" />
            </Button>
          )}
          {isAgent && editing && (
            <div className="flex items-center space-x-2">
              <Button
                variant="secondary"
                size="sm"
                onClick={() =>
                  setEditItems((prev) => [
                    {
                      name: `change_${uuidv4()}`,
                      value: "",
                      citation: {
                        id: null,
                        page: 1,
                        text: "",
                        formatted_text: null,
                        created_at: null,
                        user: null,
                        start_index: null,
                        doc_id: props.regulatoryDoc.doc_id,
                        doc_name: "",
                      },
                    },
                    ...prev,
                  ])
                }
              >
                <PlusIcon className="h-4 w-4 mr-2" />
                Add
              </Button>
              <Button variant="default" size="sm" onClick={onSave}>
                Save
                {saveLoading && (
                  <ReloadIcon className="ml-2 h-4 w-4 animate-spin" />
                )}
              </Button>
              <Button
                variant="destructive"
                size="sm"
                onClick={() => setEditing(false)}
              >
                Cancel
              </Button>
            </div>
          )}
        </div>
      </CardHeader>
      <CardContent className="space-y-6">
        {itemsToDisplay.map((item, i) =>
          isAgent && editing && props.activeItem === item.name ? (
            <div className="flex items-center space-x-2 p-3">
              <Textarea
                value={editItems[i].value}
                onChange={(e) =>
                  setEditItems((prev) =>
                    prev.map((i) =>
                      i.name === item.name ? { ...i, value: e.target.value } : i
                    )
                  )
                }
              />
              <Button
                variant="destructive"
                size="sm"
                onClick={() =>
                  setEditItems((prev) =>
                    prev.filter((i) => i.name !== item.name)
                  )
                }
              >
                <TrashIcon className="w-4 h-4" />
              </Button>
            </div>
          ) : (
            <div
              className={cn(
                "text-left gap-4 p-3 w-full rounded-md shadow-md cursor-pointer hover:bg-gray-100",
                props.activeItem === item.name && "bg-gray-100"
              )}
              key={item.name}
              onClick={() => {
                setPageNumber(item.citation.page);
                setCitationText({
                  match: item.citation.text,
                  exactMatch: false,
                  page: item.citation.page,
                });
                props.setActiveItem(item.name);
              }}
            >
              <div className="flex space-x-4">
                <span className="font-bold">{i + 1}</span>
                <span>{item.value}</span>
              </div>
            </div>
          )
        )}
      </CardContent>
    </Card>
  );
};

const ImpactedDepartmentsCard = (props: {
  impactedDepartments: Department[];
}) => {
  return (
    <Card className="flex-grow">
      <CardHeader>
        <CardTitle>Impacted Departments</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-2">
          {props.impactedDepartments.map((department: Department) => (
            <div className="flex space-x-2" key={department.id}>
              <p className="text-md text-gray-600">•</p>
              <p className="text-md text-gray-600">{department.name}</p>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

const DueDateCard = (props: {
  overviewItem: RegulatoryDocOverviewItem;
  regulatoryDoc: RegulatoryDoc;
  activeItem: string | null;
  setActiveItem: React.Dispatch<React.SetStateAction<string | null>>;
  setEnableHighlight: React.Dispatch<React.SetStateAction<boolean>>;
  setRegulatoryDoc: React.Dispatch<React.SetStateAction<RegulatoryDoc | null>>;
}) => {
  const authInfo = useAuthInfo();
  const { isAgent } = useContext(UserContext);
  const [editing, setEditing] = useState(false);
  const [editingValue, setEditingValue] = useState(props.overviewItem.value);
  const { pageNumber, citationText, setCitationText, setPageNumber } =
    useContext(DocViewerContext);
  const [saveLoading, setSaveLoading] = useState(false);

  const onSave = async () => {
    setSaveLoading(true);
    const newCitation = {
      id: null,
      page: pageNumber,
      text: citationText?.match ?? "",
      formatted_text: null,
      created_at: null,
      user: null,
      start_index: null,
      doc_id: props.regulatoryDoc.doc_id,
      doc_name: "",
    };
    const response = await updateOverviewItems(
      props.regulatoryDoc.id,
      [
        {
          name: props.overviewItem.name,
          value: editingValue,
          citation: newCitation,
        } as RegulatoryDocOverviewItem,
      ],
      [],
      authInfo.accessToken ?? ""
    );
    if (response) {
      setEditing(false);
      props.setRegulatoryDoc((prev) => {
        if (prev) {
          return {
            ...prev,
            overview_items: prev.overview_items.map((item) =>
              item.name === props.overviewItem.name
                ? { ...item, value: editingValue, citation: newCitation }
                : item
            ),
          };
        }
        return null;
      });
    } else {
      toast.error("Failed to save changes");
      setSaveLoading(false);
    }
    setSaveLoading(false);
  };
  const dueDate = new Date(props.overviewItem.value.replace(/-/g, "/"));
  const daysLeft = Math.floor(
    (dueDate.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)
  );
  const statusColor =
    daysLeft > 30
      ? "text-green-500"
      : daysLeft >= 0
        ? "text-orange-500"
        : "text-red-500";

  return (
    // create a div with 3 columns, one for one card
    <Card className="flex-grow">
      <CardHeader>
        <CardTitle>Status Tracker</CardTitle>
      </CardHeader>
      <CardContent className="space-y-1">
        {isAgent && (
          <div className="flex items-center justify-end">
            {!editing ? (
              <Button
                variant="secondary"
                size="sm"
                onClick={() => setEditing(true)}
              >
                <Pencil1Icon className="h-4 w-4" />
              </Button>
            ) : (
              <div className="flex items-center space-x-2">
                <Button variant="default" size="sm" onClick={onSave}>
                  Save
                  {saveLoading && (
                    <ReloadIcon className="ml-2 h-4 w-4 animate-spin" />
                  )}
                </Button>
                <Button
                  variant="destructive"
                  size="sm"
                  onClick={() => setEditing(false)}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
        )}
        {isAgent && editing ? (
          <Textarea
            value={editingValue}
            onChange={(e) => setEditingValue(e.target.value)}
          />
        ) : (
          <div
            className={cn(
              "text-left gap-4 p-3 w-full rounded-md shadow-md cursor-pointer hover:bg-gray-100 space-y-4",
              props.activeItem === props.overviewItem.name && "bg-gray-100"
            )}
            onClick={() => {
              setPageNumber(props.overviewItem.citation.page);
              setCitationText({
                match: props.overviewItem.citation.text,
                exactMatch: false,
                page: props.overviewItem.citation.page,
              });
              props.setActiveItem(props.overviewItem.name);
            }}
          >
            <div>
              <p className="text-sm text-muted-foreground">
                Submission Deadline
              </p>
              <div className={`text-xl font-bold ${statusColor}`}>
                {format(dueDate, "M/d/yyyy")}
              </div>
            </div>
            <div>
              <p className="text-sm text-muted-foreground">
                Days Left to Submit
              </p>
              <div className="text-xl font-bold text-gray-500">
                <span className={statusColor}>{daysLeft}</span>
              </div>
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export const RegulatoryDocOverviewView = (props: {
  regulatoryDoc: RegulatoryDoc;
  impactedDepartments: Department[];
  setRegulatoryDoc: React.Dispatch<React.SetStateAction<RegulatoryDoc | null>>;
}) => {
  const authInfo = useAuthInfo();
  const [enableHighlight, setEnableHighlight] = useState(false);
  const [generateLoading, setGenerateLoading] = useState(false);
  const [activeItem, setActiveItem] = useState<string | null>(null);
  const dueDateItem = props.regulatoryDoc.overview_items.find(
    (item) => item.name === "due date"
  );
  const changeItems = props.regulatoryDoc.overview_items.filter((item) =>
    item.name.startsWith("change_")
  );
  const overviewItems = props.regulatoryDoc.overview_items.filter(
    (item) => item.name !== "due date" && !item.name.startsWith("change_")
  );

  const onClickGenerate = async () => {
    setGenerateLoading(true);
    try {
      for await (const overview of createRegulatoryDocOverview(
        props.regulatoryDoc.id,
        authInfo.accessToken ?? null
      )) {
        props.setRegulatoryDoc((prev) => {
          if (prev) {
            return {
              ...prev,
              overview_items: overview.overview_items,
              requirements: overview.requirements,
            };
          }
          return prev;
        });
      }
    } catch (error) {
      console.error("There was an error generating the summary", error);
      toast.error("Unable to generate summary");
    }
    setGenerateLoading(false);
  };

  return (
    <ResizablePanelGroup direction="horizontal">
      <ResizablePanel
        defaultSize={50}
        minSize={50}
        maxSize={60}
        id="resource-panel"
        order={2}
        className="space-y-4"
      >
        <div className="space-y-4 pb-10 pl-1 pr-5 h-[calc(100vh-205px)] overflow-y-auto">
          <>
            {(props.regulatoryDoc.overview_items.length === 0 ||
              generateLoading) && (
              <Button
                variant="default"
                size="lg"
                className="w-full text-xl"
                onClick={onClickGenerate}
                disabled={generateLoading}
              >
                {generateLoading && (
                  <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                )}
                Analyze{" "}
                {props.regulatoryDoc.doc_type.name.split(" ").slice(-1)[0]}
              </Button>
            )}
            {overviewItems && overviewItems.length > 0 && (
              <SummaryCard
                docName={props.regulatoryDoc.doc_type.name}
                overviewItems={overviewItems}
                setEnableHighlight={setEnableHighlight}
                activeItem={activeItem}
                setActiveItem={setActiveItem}
                regulatoryDoc={props.regulatoryDoc}
                setRegulatoryDoc={props.setRegulatoryDoc}
              />
            )}
            <div className="grid grid-cols-2 gap-4">
              {props.impactedDepartments.length > 0 && (
                <ImpactedDepartmentsCard
                  impactedDepartments={props.impactedDepartments}
                />
              )}
              {dueDateItem && (
                <DueDateCard
                  overviewItem={dueDateItem}
                  activeItem={activeItem}
                  setActiveItem={setActiveItem}
                  setEnableHighlight={setEnableHighlight}
                  regulatoryDoc={props.regulatoryDoc}
                  setRegulatoryDoc={props.setRegulatoryDoc}
                />
              )}
            </div>
            {/* {changeItems && changeItems.length > 0 && (
              <WhatChangedCard
                overviewItems={changeItems}
                activeItem={activeItem}
                setActiveItem={setActiveItem}
                setEnableHighlight={setEnableHighlight}
                regulatoryDoc={props.regulatoryDoc}
                setRegulatoryDoc={props.setRegulatoryDoc}
              />
            )} */}
          </>
        </div>
      </ResizablePanel>
      <ResizableHandle withHandle className="mx-4" />
      <ResizablePanel
        defaultSize={50}
        minSize={40}
        maxSize={50}
        id="doc-view-panel"
        order={3}
      >
        <DocViewerCitation
          docId={props.regulatoryDoc.doc_id}
          hideAtlasWidget={true}
          enableHighlight={enableHighlight}
          className="h-[calc(100vh-300px)]"
        />
      </ResizablePanel>
    </ResizablePanelGroup>
  );
};
