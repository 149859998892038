import { useAuthInfo } from "@propelauth/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getInsights } from "../../utils/apiCalls";
import { toast } from "sonner";
import { Layout } from "../../components/Layout";
import { Insight } from "../../types";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../shadcn/components/card";
import { Badge } from "../../shadcn/components/badge";

const InsightCard = (props: { insights: Insight[] }) => {
  const navigate = useNavigate();

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-between">
          <div className="flex flex-col space-y-2">
            <CardTitle>Datasets</CardTitle>
            <div className="text-gray-500 text-sm font-normal">
              Select a dataset to view all records
            </div>
          </div>
          <Badge
            variant="default"
            className=" flex items-center justify-center bg-blue-400 text-base w-10 h-10"
          >
            {props.insights.length}
          </Badge>
        </div>
      </CardHeader>
      <CardContent>
        <div className="space-y-6">
          {props.insights.map((insight) => (
            <div
              key={insight.id}
              onClick={() => {
                navigate(`/insight/${insight.id}`);
              }}
              className="cursor-pointer underline text-[#007acc] transition-color duration-300 ease-in-out overflow-hidden"
            >
              {insight.name}
            </div>
          ))}
          {props.insights.length === 0 && (
            <div className="text-center text-gray-500">No insights found</div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export const InsightView = () => {
  const authInfo = useAuthInfo();
  const [insights, setInsights] = useState<Insight[]>([]);

  useEffect(() => {
    getInsights(authInfo.accessToken ?? null).then((insights) => {
      if (insights) {
        setInsights(insights);
      } else {
        toast.error("Failed to fetch insights");
      }
    });
  }, []);

  return (
    <Layout pageName="Insights">
      <InsightCard insights={insights} />
    </Layout>
  );
};
