import { useContext, useState } from "react";
import { LoadingView } from "../Loading";
import { RequirementListView } from "./RequirementListView";
import { RequirementTable } from "./RequirementTable";
import { RequirementContext } from "../../contexts/RequirementContext";
import { Button } from "../../shadcn/components/button";
import {
  ListBulletIcon,
  PlusIcon,
  ReloadIcon,
  TableIcon,
} from "@radix-ui/react-icons";
import { DocViewerContext } from "../../contexts/DocViewerContext";
import { useAuthInfo } from "@propelauth/react";
import { exportRequirements } from "../../utils/apiCalls";
import { toast } from "sonner";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../shadcn/components/dropdown-menu";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";

export const RequirementView = (props: {
  actionItemRoutePrefix: string;
  docId: string;
}) => {
  const { requirementsLoading, viewType } = useContext(RequirementContext);
  return (
    <>
      {requirementsLoading ? (
        <LoadingView customText="Loading requirements..." />
      ) : viewType === "list" ? (
        <RequirementListView
          actionItemRoutePrefix={props.actionItemRoutePrefix}
          docId={props.docId}
        />
      ) : (
        <RequirementTable />
      )}
    </>
  );
};

const AddRequirementButton = () => {
  const { pageNumber, docToView } = useContext(DocViewerContext);
  const {
    addRequirement,
    generateRequirementLoading,
    setViewType,
    viewType,
    editing,
  } = useContext(RequirementContext);
  return (
    <Button
      variant="default"
      onClick={() => {
        if (viewType !== "list") {
          setViewType("list");
          // wait for the view to change
          setTimeout(() => {
            addRequirement(docToView?.docId ?? "", pageNumber);
          }, 100);
        } else {
          addRequirement(docToView?.docId ?? "", pageNumber);
        }
      }}
      disabled={editing}
    >
      {generateRequirementLoading ? (
        <ReloadIcon className="w-4 h-4 mr-2 animate-spin" />
      ) : (
        <PlusIcon className="w-4 h-4 mr-2" />
      )}
      Add Requirement
    </Button>
  );
};

const ExportRequirementButton = (props: { docId: string }) => {
  const authInfo = useAuthInfo();
  const [exportLoading, setExportLoading] = useState<boolean>(false);

  const onClickExport = async (includePolicyText: boolean) => {
    setExportLoading(true);
    const response = await exportRequirements(
      props.docId,
      includePolicyText,
      authInfo.accessToken ?? null
    );
    if (!response) {
      toast.error("Failed to export analyses");
    }
    setExportLoading(false);
  };
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button className="w-24">
          Export{" "}
          {exportLoading && (
            <ReloadIcon className="w-4 h-4 ml-2 animate-spin" />
          )}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuItem onClick={() => onClickExport(true)}>
          Export with Full Details
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => onClickExport(false)}>
          Export with only Document Name
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const RequirementViewToggle = (props: {
  viewType: "list" | "table";
  setViewType: (viewType: "list" | "table") => void;
}) => {
  return (
    <div className="flex items-center space-x-1 bg-gray-200 p-1 rounded-md">
      <Tooltip>
        <TooltipTrigger>
          <Button
            size="icon"
            variant={props.viewType === "list" ? "outline" : "ghost"}
            onClick={() => {
              if (props.viewType !== "list") {
                props.setViewType("list");
              }
            }}
          >
            <ListBulletIcon className="h-4 w-4" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>View Requirements as a List</TooltipContent>
      </Tooltip>
      <Tooltip>
        <TooltipTrigger>
          <Button
            size="icon"
            variant={props.viewType === "table" ? "outline" : "ghost"}
            onClick={() => {
              if (props.viewType !== "table") {
                props.setViewType("table");
              }
            }}
          >
            <TableIcon className="h-4 w-4" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>View Requirements as a Table</TooltipContent>
      </Tooltip>
    </div>
  );
};

export const RequirementControlsHeader = (props: {
  docId: string;
  requirementView: boolean;
}) => {
  const { viewType, setViewType, requirements, editing } =
    useContext(RequirementContext);

  return (
    <div className="flex items-center space-x-2">
      {props.requirementView && <AddRequirementButton />}
      {requirements.length > 0 && (
        <ExportRequirementButton docId={props.docId} />
      )}
      {props.requirementView && (
        <RequirementViewToggle viewType={viewType} setViewType={setViewType} />
      )}
    </div>
  );
};
