import { useEffect, useRef, useState } from "react";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../shadcn/components/avatar";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../shadcn/components/card";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import { DocType, PaginationOutput, RegulatoryDoc } from "../../types";
import { useAuthInfo } from "@propelauth/react";
import { allRegulatoryDocs, getFilterDocTypes } from "../../utils/apiCalls";
import { toast } from "sonner";
import { Layout } from "../../components/Layout";
import { Separator } from "../../shadcn/components/separator";
import { Switch } from "../../shadcn/components/switch";
import { RegulatoryDocOverviewContent } from "./CustomDocFeedContent";
import { SearchDocNameBar } from "../../components/SearchDocName";
import { useNavigate } from "react-router-dom";
import { LoadingView } from "../../components/Loading";
import { useCookies } from "react-cookie";
import { Label } from "../../shadcn/components/label";
import { InfoIcon } from "lucide-react";

const FollowToggle = (props: {
  docTypeId: string;
  handleToggle: (docTypeId: string, enabled: boolean) => void;
  selected: boolean;
}) => {
  const [toggle, setToggle] = useState<boolean>(props.selected);

  const onChange = (checked: boolean) => {
    props.handleToggle(props.docTypeId, checked);
    setToggle(checked);
  };

  return (
    <div>
      <Switch
        checked={toggle}
        onCheckedChange={onChange}
        className="transform scale-150"
      />
    </div>
  );
};

const SourcesFilter = (props: {
  selectedFilters: string[];
  setSelectedFilters: React.Dispatch<React.SetStateAction<string[]>>;
  relevantOnly: boolean;
  setRelevantOnly: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const navigate = useNavigate();
  const authInfo = useAuthInfo();
  const [docTypes, setDocTypes] = useState<DocType[]>([]);
  const [cookies, setCookies] = useCookies([
    "reg-feed-filter",
    "relevant-only-filter",
  ]);

  useEffect(() => {
    getFilterDocTypes(authInfo.accessToken ?? null).then((docTypes) => {
      if (docTypes !== null) {
        setDocTypes(docTypes);
        if (cookies["reg-feed-filter"]) {
          props.setSelectedFilters(cookies["reg-feed-filter"]);
        } else {
          props.setSelectedFilters(docTypes.map((docType) => docType.id));
        }
      } else {
        toast.error("Something went wrong fetching filter doc types");
      }
    });
    props.setRelevantOnly(cookies["relevant-only-filter"] === "true");
  }, []);

  const handleToggle = (docTypeId: string, enabled: boolean) => {
    props.setSelectedFilters((prevFilters) => {
      if (enabled) {
        return [...prevFilters, docTypeId];
      } else {
        return prevFilters.filter((item) => item !== docTypeId);
      }
    });
  };

  useEffect(() => {
    setCookies("reg-feed-filter", props.selectedFilters);
  }, [props.selectedFilters]);

  return (
    <div className="space-y-6">
      <div>
        <SearchDocNameBar
          docTypeIds={docTypes.map((docType) => docType.id)}
          placeholder="Search Regulations..."
          onItemSelect={(result) => {
            navigate(
              `/regulatory-doc/overview/${result.additional_metadata["regulatory_doc_id"]}`
            );
          }}
          hideAtlasWidget={true}
        />
      </div>
      <div className="flex items-center space-x-2">
        <Switch
          id="relevant-only"
          checked={props.relevantOnly}
          onCheckedChange={(checked) => {
            props.setRelevantOnly(checked);
            setCookies("relevant-only-filter", checked.toString());
          }}
        />
        <Label htmlFor="relevant-only">Relevant Only</Label>
        <Tooltip>
          <TooltipTrigger>
            <InfoIcon className="h-4 w-4 text-blue-500" />
          </TooltipTrigger>
          <TooltipContent>
            Click to only show guidances relevant to your organization
          </TooltipContent>
        </Tooltip>
      </div>
      <Card>
        <CardHeader>
          <CardTitle>Sources</CardTitle>
          <CardDescription>
            Connect with authoritative sources and stay up to date with
            regulatory guidance for Managed Care Plans.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Separator />
          <div className="space-y-4 pt-8">
            <div className="grid gap-10 p-2">
              {docTypes
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((docType) => (
                  <div
                    key={docType.id}
                    className="flex items-center justify-between space-x-4"
                  >
                    <div className="flex items-center space-x-4">
                      <Avatar className="h-12 w-24">
                        <AvatarImage
                          src={`/${docType.agency.toLowerCase()}.png`}
                        />
                        <AvatarFallback>{docType.agency}</AvatarFallback>
                      </Avatar>
                      <div>
                        <p className="text-base font-medium leading-none truncate">
                          {docType.name}
                        </p>
                        <p className="text-sm text-gray-400 pt-1">
                          {docType.agency}
                        </p>
                      </div>
                    </div>
                    <div className="pr-2">
                      <FollowToggle
                        docTypeId={docType.id}
                        selected={props.selectedFilters.includes(docType.id)}
                        handleToggle={handleToggle}
                      />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

const FeedView = (props: {
  selectedFilters: string[];
  relevantOnly: boolean;
}) => {
  const authInfo = useAuthInfo();
  const [isLoading, setIsLoading] = useState(false);
  const [regulatoryDocs, setRegulatoryDocs] = useState<{
    docs: RegulatoryDoc[];
    pagination_output: PaginationOutput | null;
  }>({ docs: [], pagination_output: null });
  const [activePage, setActivePage] = useState(1);
  const [lastElement, setLastElement] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    setActivePage(1);
    setRegulatoryDocs({ docs: [], pagination_output: null });
  }, [props.selectedFilters, props.relevantOnly]);

  const getDocs = async (page: number) => {
    setIsLoading(true);
    const response = await allRegulatoryDocs(
      15,
      page,
      props.selectedFilters,
      props.relevantOnly,
      authInfo.accessToken ?? null
    );
    if (response !== null) {
      setRegulatoryDocs((prevDocs) => {
        return {
          docs: [...prevDocs.docs, ...response.docs],
          pagination_output: response.pagination_output,
        };
      });
    } else {
      toast.error("Something went wrong pulling docs");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (
      !isLoading &&
      props.selectedFilters.length > 0 &&
      activePage === (regulatoryDocs.pagination_output?.next_page ?? 1)
    ) {
      getDocs(activePage);
    } else if (
      activePage > (regulatoryDocs.pagination_output?.total_pages ?? 1)
    ) {
      setActivePage(regulatoryDocs.pagination_output?.current_page ?? 1);
    }
  }, [
    activePage,
    regulatoryDocs.pagination_output,
    props.selectedFilters,
    props.relevantOnly,
  ]);

  const observer = useRef(
    new IntersectionObserver((entries) => {
      const first = entries[0];
      if (first.isIntersecting) {
        setActivePage((no) => no + 1);
      }
    })
  );

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);

  return (
    <div className="overflow-y-auto space-y-5 pr-4 h-[calc(100vh-120px)]">
      {regulatoryDocs.docs.map((regulatoryDoc, index) => (
        <div
          key={index}
          ref={
            !isLoading && index === regulatoryDocs.docs.length - 1
              ? setLastElement
              : null
          }
        >
          <RegulatoryDocOverviewContent
            regulatoryDoc={regulatoryDoc}
            key={regulatoryDoc.id}
          />
        </div>
      ))}
      {!isLoading && regulatoryDocs.docs.length === 0 && (
        <p>No Regulatory Guidance found</p>
      )}
      {isLoading && <LoadingView />}
    </div>
  );
};

export const RegulatoryDocFeedView = () => {
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [relevantOnly, setRelevantOnly] = useState<boolean>(false);
  return (
    <Layout pageName="Regulatory Guidance">
      <div className="grid grid-cols-[auto_400px] gap-4">
        <FeedView
          selectedFilters={selectedFilters}
          relevantOnly={relevantOnly}
        />
        <SourcesFilter
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          relevantOnly={relevantOnly}
          setRelevantOnly={setRelevantOnly}
        />
      </div>
    </Layout>
  );
};
