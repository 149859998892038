import { useParams } from "react-router-dom";
import { Layout } from "../../components/Layout";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "../../shadcn/components/resizable";
import { DocView } from "./DocView";
import { DocHistoryView } from "./DocHistoryView";
import { useAuthInfo } from "@propelauth/react";
import {
  getApprovalConfig,
  getCurrentApprovalStatus,
  getDocHistory,
  getDocMetadata,
} from "../../utils/apiCalls";
import { toast } from "sonner";
import { useEffect, useMemo, useState } from "react";
import {
  ApprovalConfig,
  DocHistory,
  DocMetadata,
  WorkflowApprovalStatus,
} from "../../types";
import { DocApprovalWorkflowView } from "./DocApprovalView";
import { Separator } from "../../shadcn/components/separator";
import { DocApprovalCreatorDialog } from "./DocApprovalCreator";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../shadcn/components/breadcrumb";
import { DocAttestationsDialog } from "./DocAttestationsDialog";
import { Scheduler } from "./Scheduler";

const BreadcrumbNav = () => {
  return (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink href="/policy-repo">Overview</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbPage>Document</BreadcrumbPage>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  );
};

export const PolicyRepositoryDocView = () => {
  const { docId } = useParams();
  const authInfo = useAuthInfo();
  const [docMetadata, setDocMetadata] = useState<DocMetadata | null>(null);
  const [approvalStatus, setApprovalStatus] =
    useState<WorkflowApprovalStatus | null>(null);
  const [activeHistoryId, setActiveHistoryId] = useState<string>();
  const [docHistory, setDocHistory] = useState<DocHistory[]>([]);
  const [approvalConfig, setApprovalConfig] = useState<ApprovalConfig | null>(
    null
  );

  const docHistoryFmt = useMemo(() => {
    return docHistory.map((history) => ({
      ...history,
      active: history.id === docMetadata?.flow_instance_id,
    }));
  }, [docHistory, docMetadata?.flow_instance_id]);

  const activeHistory = docHistoryFmt.find(
    (history) => history.id === activeHistoryId
  );
  const comparisonDocs = docHistoryFmt.filter(
    (history) => history.id !== activeHistoryId
  );

  const activePolicy = docHistoryFmt.find(
    (history) =>
      history.initially_active !== null && history.last_active === null
  );

  const parentDocId =
    docMetadata === null
      ? null
      : docMetadata.approval_flow_id
        ? docMetadata.base_doc_id
        : docId;
  const editableDocId =
    docMetadata === null
      ? null
      : docMetadata.editable_doc_id
        ? docMetadata.editable_doc_id
        : docId;

  useEffect(() => {
    if (editableDocId) {
      getDocHistory(editableDocId, authInfo.accessToken ?? null).then(
        (history) => {
          if (history !== null) {
            setDocHistory(history);
          } else {
            toast.error("Failed to get document history");
          }
        }
      );
    }
  }, [editableDocId]);

  useEffect(() => {
    if (editableDocId) {
      getCurrentApprovalStatus(
        editableDocId,
        authInfo.accessToken ?? null
      ).then((response) => {
        if (response !== null) {
          setApprovalStatus(response);
        } else {
          toast.error("Error getting approval status, please try again");
          setApprovalStatus(null);
        }
      });
    }
  }, [editableDocId]);

  useEffect(() => {
    if (docId) {
      getDocMetadata(docId, authInfo.accessToken ?? null).then((metadata) => {
        if (metadata !== null) {
          setDocMetadata(metadata);
        } else {
          toast.error("Failed to load document metadata");
        }
      });
      getApprovalConfig(docId, authInfo.accessToken ?? null).then(
        (response) => {
          if (response !== null) {
            setApprovalConfig(response);
          }
        }
      );
    }
  }, [docId]);

  return (
    <Layout pageName="Policy Repository">
      <BreadcrumbNav />
      <ResizablePanelGroup direction="horizontal">
        {docId && (
          <>
            <ResizablePanel
              defaultSize={40}
              minSize={25}
              maxSize={50}
              id="policy-repo-control-panel"
              order={2}
            >
              <div className="space-y-2">
                <div className="space-x-2 flex items-center">
                  <div className="text-md font-bold">Approval Workflow</div>
                  {approvalStatus !== null && (
                    <DocApprovalCreatorDialog
                      docId={docId}
                      approvalConfig={approvalConfig}
                      setApprovalConfig={setApprovalConfig}
                    />
                  )}
                  {approvalStatus !== null && approvalConfig !== null && (
                    <Scheduler
                      docId={docId}
                      approvalConfig={approvalConfig}
                      activeSchedule={approvalConfig?.schedule ?? null}
                      setApprovalConfig={setApprovalConfig}
                    />
                  )}
                  {activePolicy && (
                    <DocAttestationsDialog flowInstanceId={activePolicy.id} />
                  )}
                </div>
                {editableDocId && (
                  <DocApprovalWorkflowView
                    editableDocId={editableDocId}
                    approvalStatus={approvalStatus}
                    setApprovalStatus={setApprovalStatus}
                  />
                )}
              </div>
              <Separator className="my-5" />
              <div className="space-y-2 h-[calc(100vh-270px)] overflow-y-auto">
                <div className="text-md font-bold">History</div>
                {parentDocId && editableDocId && (
                  <DocHistoryView
                    editableDocId={editableDocId}
                    activeHistoryId={activeHistoryId}
                    setActiveHistoryId={setActiveHistoryId}
                    docHistory={docHistoryFmt}
                  />
                )}
              </div>
            </ResizablePanel>
            <ResizableHandle withHandle className="mx-2" />
          </>
        )}
        <ResizablePanel
          defaultSize={60}
          minSize={50}
          maxSize={75}
          id="policy-repo-doc-panel"
          order={3}
        >
          {docId && docMetadata && (
            <DocView
              docId={docId}
              docMetadata={docMetadata}
              activeHistory={activeHistory}
              comparisonDocs={comparisonDocs}
            />
          )}
        </ResizablePanel>
      </ResizablePanelGroup>
    </Layout>
  );
};
