import { useAuthInfo } from "@propelauth/react";
import { Note, NoteReaction } from "../types";
import { createNote, deleteNote, noteReact } from "../utils/apiCalls";
import { toast } from "sonner";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../shadcn/components/tooltip";
import { Badge } from "../shadcn/components/badge";
import { ThumbsUp } from "lucide-react";
import { ReloadIcon, TrashIcon } from "@radix-ui/react-icons";
import { useState } from "react";
import { loadAndFormatTime } from "../utils/format";
import { Textarea } from "../shadcn/components/textarea";
import { Button } from "../shadcn/components/button";

const ReactionView = (props: {
  noteId: string;
  reactions: NoteReaction[];
  onReact: (noteId: string, reaction: boolean) => void;
  urlPrefix: string;
  urlSuffix: string;
}) => {
  const authInfo = useAuthInfo();
  const userReactions: string = props.reactions
    .map((r) => `${r.user.first_name} ${r.user.last_name[0]}`)
    .join(", ");

  const existingUserReaction = props.reactions.find(
    (r) => r.user.id === authInfo.user?.userId
  );

  const handleClick = async (reaction: boolean) => {
    const response = await noteReact(
      `${props.urlPrefix}/note-react/${props.urlSuffix}`,
      props.noteId,
      reaction ? "U+1F44D" : null,
      authInfo.accessToken ?? null
    );
    if (response) {
      props.onReact(props.noteId, reaction);
    } else {
      toast.error("Failed to react to note");
    }
  };

  return (
    <Tooltip>
      <TooltipTrigger>
        <Badge
          variant={existingUserReaction ? "default" : "ghost"}
          onClick={() => handleClick(existingUserReaction ? false : true)}
        >
          <ThumbsUp className="w-5 h-5" />
          {props.reactions.length > 0 && (
            <span className="text-xs pl-1">{props.reactions.length}</span>
          )}
        </Badge>
      </TooltipTrigger>
      <TooltipContent>{userReactions || "No reactions"}</TooltipContent>
    </Tooltip>
  );
};

const DeleteNoteButton = (props: {
  noteId: string;
  deleteNote: (noteId: string) => void;
  urlPrefix: string;
  urlSuffix: string;
}) => {
  const authInfo = useAuthInfo();

  const onClickDelete = async () => {
    const response = await deleteNote(
      `${props.urlPrefix}/note/${props.urlSuffix}/${props.noteId}`,
      authInfo.accessToken ?? null
    );
    if (response) {
      props.deleteNote(props.noteId);
    } else {
      toast.error("Failed to delete note");
    }
  };

  return (
    <Tooltip>
      <TooltipTrigger>
        <Badge variant="destructive" onClick={onClickDelete}>
          <TrashIcon className="w-5 h-5" />
        </Badge>
      </TooltipTrigger>
      <TooltipContent>Delete note</TooltipContent>
    </Tooltip>
  );
};

export const NoteView = (props: {
  notes: Note[];
  addNote: (note: Note) => void;
  onReact: (noteId: string, reaction: boolean) => void;
  deleteNote: (noteId: string) => void;
  urlPrefix: string;
  urlSuffix: string;
}) => {
  const authInfo = useAuthInfo();
  const [note, setNote] = useState<string>("");
  const [saveLoading, setSaveLoading] = useState<boolean>(false);

  const onSave = async () => {
    setSaveLoading(true);
    const response = await createNote(
      `${props.urlPrefix}/note/${props.urlSuffix}`,
      note,
      authInfo.accessToken ?? null
    );
    if (response !== null) {
      props.addNote(response);
      setNote("");
    } else {
      toast.error("Failed to save note");
    }
    setSaveLoading(false);
  };

  return (
    <>
      <div className="font-semibold text-lg">Notes</div>
      {props.notes
        .sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        )
        .map((note) => (
          <div
            key={note.id}
            className="flex flex-col items-start rounded-lg border p-3 text-left bg-white space-y-2"
          >
            <div className="w-full justify-between flex items-center">
              <div className="flex items-center space-x-2">
                <div className="text-sm font-semibold">{`${note.user.first_name} ${note.user.last_name[0]}`}</div>
                <div className="text-sm text-gray-500">
                  {loadAndFormatTime(note.created_at)}
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <ReactionView
                  noteId={note.id}
                  reactions={note.reactions.filter((r) => r.reaction !== null)}
                  onReact={props.onReact}
                  urlPrefix={props.urlPrefix}
                  urlSuffix={props.urlSuffix}
                />
                {note.user.id === authInfo.user?.userId && (
                  <DeleteNoteButton
                    noteId={note.id}
                    deleteNote={props.deleteNote}
                    urlPrefix={props.urlPrefix}
                    urlSuffix={props.urlSuffix}
                  />
                )}
              </div>
            </div>
            <div className="text-md wrap">{note.note}</div>
          </div>
        ))}
      <Textarea
        tabIndex={1}
        rows={3}
        value={note}
        onChange={(e) => {
          setNote(e.target.value);
        }}
        placeholder={`Enter note...`}
        spellCheck={false}
      />
      <div className="flex justify-end">
        <Button variant="default" size="sm" onClick={onSave}>
          Post{" "}
          {saveLoading && <ReloadIcon className="w-4 h-4 ml-2 animate-spin" />}
        </Button>
      </div>
    </>
  );
};
