import { ReactNode, useContext, useEffect, useState } from "react";
import { Layout } from "../../components/Layout";
import { useSearchParams } from "react-router-dom";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "../../shadcn/components/resizable";
import { ChatView } from "./ChatView";
import {
  ChatHistoryMetadata,
  Citation,
  CitationText,
  ModelChat,
} from "../../types";
import { toast } from "sonner";
import { useAuthInfo } from "@propelauth/react";
import {
  chatDoc,
  getChat,
  getChatHistory,
  getCitation,
} from "../../utils/apiCalls";
import { DocViewerContext } from "../../contexts/DocViewerContext";
import { Button } from "../../shadcn/components/button";
import { SparkleIcon, X } from "lucide-react";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../shadcn/components/tabs";
import {
  DocViewerCitation,
  IndividualDocSearchView,
} from "../../components/DocViewer";
import { SearchDocNameBar } from "../../components/SearchDocName";
import { BaseActions } from "./ChatHighlightActions";
import { cn } from "../../shadcn/lib/utils";
import { Badge } from "../../shadcn/components/badge";
import { TimeAgo } from "../../utils/format";

const ChatHistoryItem = (props: {
  metadata: ChatHistoryMetadata;
  activeChatId: string | null;
  onClick: (chatId: string, docId: string) => Promise<void>;
}) => {
  const selectedChat = props.metadata.chat_id === props.activeChatId;
  return (
    <div
      className={cn(
        "space-y-2 h-[80px] p-5 rounded-md",
        selectedChat
          ? "bg-gray-300"
          : "bg-white hover:bg-gray-200 cursor-pointer"
      )}
      onClick={() =>
        props.onClick(props.metadata.chat_id, props.metadata.doc_id)
      }
    >
      <div className="font-bold text-xs overflow-hidden text-ellipsis truncate">
        {props.metadata.doc_name}
      </div>
      <div className="flex items-center justify-between">
        <Badge className="text-xs" variant="secondary">
          {props.metadata.doc_type_name}
        </Badge>
        <div className="text-xs text-gray-500">
          <TimeAgo timestamp={props.metadata.updated_at} />
        </div>
      </div>
    </div>
  );
};

export const DocChatView = () => {
  const authInfo = useAuthInfo();
  const [chatEnabled, setChatEnabled] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [chatMessages, setChatMessages] = useState<{
    chat: ModelChat[];
    citations: Citation[];
  } | null>(null);
  const [chatLoading, setChatLoading] = useState(false);
  const [chatId, setChatId] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<string>("chat");
  const [tooltipContent, setTooltipContent] = useState<ReactNode>(null);
  const [chatHistory, setChatHistory] = useState<ChatHistoryMetadata[]>([]);
  const {
    pageNumber,
    setPageNumber,
    setCitationText,
    setDocToView,
    docToView,
    citationText,
  } = useContext(DocViewerContext);

  const userMessage = async (input: string) => {
    setChatMessages((prevMessages) => {
      return {
        chat: [...(prevMessages?.chat ?? []), { role: "user", content: input }],
        citations: prevMessages?.citations ?? [],
      };
    });
    setChatLoading(true);
    try {
      for await (const chatOutput of chatDoc(
        chatId,
        input,
        docToView?.docId!,
        pageNumber,
        authInfo.accessToken ?? null
      )) {
        setChatId(chatOutput.chat_id);
        if (chatOutput.output_message !== null) {
          setChatMessages((prevMessages) => {
            if (prevMessages) {
              // Add new citations to the citation map
              const updatedCitations = [...prevMessages.citations];
              chatOutput.citations.forEach((citation) => {
                if (!updatedCitations.includes(citation)) {
                  updatedCitations.push(citation);
                }
              });
              if (
                prevMessages.chat.length > 1 &&
                prevMessages.chat[prevMessages.chat.length - 1].role ===
                  "assistant"
              ) {
                return {
                  ...prevMessages,
                  chat: [
                    ...prevMessages.chat.slice(0, prevMessages.chat.length - 1),
                    chatOutput.output_message!,
                  ],
                  citations: updatedCitations,
                };
              } else {
                return {
                  ...prevMessages,
                  chat: [...prevMessages.chat, chatOutput.output_message!],
                  citations: updatedCitations,
                };
              }
            }
            return prevMessages;
          });
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong, try again");
    }
    setChatLoading(false);
  };

  useEffect(() => {
    const paramsDocId = searchParams.get("docId");
    const paramsCitationId = searchParams.get("citationId");
    if (paramsDocId && docToView?.docId !== paramsDocId) {
      setDocToView({
        docId: paramsDocId,
      });
      setPageNumber(Number(searchParams.get("page") ?? 1));
      setCitationText(
        searchParams.get("text")
          ? ({
              match: searchParams.get("text"),
              exactMatch: false,
              page: Number(searchParams.get("page") ?? 1),
            } as CitationText)
          : null
      );
    } else if (paramsCitationId) {
      getCitation(paramsCitationId, authInfo.accessToken ?? null).then(
        (citation) => {
          if (citation !== null) {
            setDocToView({
              docId: citation.doc_id!,
            });
            setPageNumber(citation.page);
            setCitationText({
              match: citation.text,
              exactMatch: false,
              page: citation.page,
            });
          } else {
            toast.error("Unable to find citation, please refresh");
          }
        }
      );
    } else if (!paramsDocId) {
      setDocToView(null);
      setPageNumber(1);
      setCitationText(null);
      setActiveTab("history");
    }
    getChatHistory(authInfo.accessToken ?? "").then((response) => {
      if (response !== null) {
        setChatHistory(response);
      } else {
        toast.error("Failed to load chat history");
      }
    });
  }, []);

  const citationClick = (citation: Citation) => {
    setPageNumber(citation.page);
    setCitationText({
      match: citation.text,
      exactMatch: false,
      page: citation.page,
    });
  };

  const onChatHistoryClick = async (chatId: string, docId: string) => {
    const response = await getChat(chatId, authInfo.accessToken ?? "");
    if (response !== null) {
      setChatId(chatId);
      setChatMessages({
        chat: response.chat,
        citations: response.citations,
      });
      setPageNumber(1);
      setCitationText(null);
      setDocToView({
        docId: docId,
      });
      setActiveTab("chat");
    } else {
      toast.error("Failed to load chat");
    }
  };

  useEffect(() => {
    const pageParam = searchParams.get("page");
    if (
      docToView?.docId !== null &&
      (!pageParam || Number(pageParam) !== pageNumber)
    ) {
      setSearchParams(
        (prev) => {
          prev.set("page", pageNumber.toString());
          prev.delete("text");
          return prev;
        },
        { replace: true }
      );
    }
    if (pageNumber !== citationText?.page && tooltipContent) {
      setTooltipContent(null);
    } else if (pageNumber === citationText?.page && !tooltipContent) {
      setTooltipContent(
        <BaseActions setMessage={userMessage} setActiveTab={setActiveTab} />
      );
    }
  }, [pageNumber]);

  useEffect(() => {
    if (docToView?.docId) {
      if (docToView.docId !== searchParams.get("docId")) {
        setSearchParams((prev) => {
          prev.set("docId", docToView.docId);
          return prev;
        });
      }
    }
  }, [docToView?.docId]);

  useEffect(() => {
    if (citationText?.match) {
      setTooltipContent(
        <BaseActions setMessage={userMessage} setActiveTab={setActiveTab} />
      );
    }
  }, [citationText?.match]);

  return (
    <Layout pageName="Document Viewer">
      <SearchDocNameBar
        docTypeIds={[]}
        onItemSelect={(item) => {
          setDocToView({
            docId: item.doc_id,
          });
          setActiveTab("chat");
          if (item.citation !== null) {
            setCitationText({
              match: item.citation.text,
              exactMatch: false,
              page: item.citation.page,
            });
            setPageNumber(item.citation.page);
          }
        }}
        nonRelative={true}
        hideAtlasWidget={true}
      />
      <ResizablePanelGroup direction="horizontal">
        {chatEnabled && (
          <>
            <ResizablePanel
              defaultSize={30}
              minSize={25}
              maxSize={50}
              id="chat-panel"
              order={2}
            >
              <Tabs value={activeTab} onValueChange={setActiveTab}>
                <div className="pr-4 relative">
                  <div className="flex justify-between items-center">
                    <div>
                      <TabsList>
                        <TabsTrigger
                          value="search"
                          disabled={!docToView?.docId}
                        >
                          Search
                        </TabsTrigger>
                        <TabsTrigger value="chat" disabled={!docToView?.docId}>
                          Chat
                        </TabsTrigger>
                        <TabsTrigger value="history">History</TabsTrigger>
                      </TabsList>
                    </div>
                    <Button
                      onClick={() => setChatEnabled(false)}
                      aria-label="Close chat"
                      variant="ghost"
                      size="icon"
                    >
                      <X className="w-4 h-4" />
                    </Button>
                  </div>
                  <TabsContent value="chat">
                    {docToView?.docId && (
                      <ChatView
                        docId={docToView.docId}
                        setChatEnabled={setChatEnabled}
                        citationClick={citationClick}
                        chatMessages={chatMessages}
                        chatLoading={chatLoading}
                        userMessage={userMessage}
                      />
                    )}
                  </TabsContent>
                  <TabsContent value="search">
                    {docToView?.docId && <IndividualDocSearchView />}
                  </TabsContent>
                  <TabsContent value="history">
                    <div className="space-y-2 h-[calc(100vh-218px)] overflow-y-auto">
                      {chatHistory.map((chatHistoryMetadata) => {
                        return (
                          <ChatHistoryItem
                            key={chatHistoryMetadata.chat_id}
                            metadata={chatHistoryMetadata}
                            activeChatId={chatId}
                            onClick={onChatHistoryClick}
                          />
                        );
                      })}
                    </div>
                  </TabsContent>
                </div>
              </Tabs>
            </ResizablePanel>
            <ResizableHandle withHandle />
          </>
        )}
        <ResizablePanel
          defaultSize={chatEnabled ? 70 : 100}
          minSize={50}
          maxSize={chatEnabled ? 70 : 100}
          id="doc-panel"
          order={3}
        >
          {docToView && (
            <DocViewerCitation
              enableHighlight={true}
              docId={docToView.docId}
              className="h-[calc(100vh-265px)]"
              hideAtlasWidget={false}
              tooltipContent={tooltipContent}
            >
              {!chatEnabled && docToView !== null && (
                <Button
                  variant="default"
                  onClick={() => setChatEnabled(true)}
                  size="sm"
                >
                  <SparkleIcon className="w-4 h-4 mr-2" />
                  Chat
                </Button>
              )}
            </DocViewerCitation>
          )}
        </ResizablePanel>
      </ResizablePanelGroup>
    </Layout>
  );
};
