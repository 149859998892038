import { useAuthInfo } from "@propelauth/react";
import { useEffect, useMemo, useState } from "react";
import { getDashboardData } from "../../utils/apiCalls";
import { toast } from "sonner";
import { Layout } from "../../components/Layout";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../shadcn/components/card";
import { cn } from "../../shadcn/lib/utils";
import { completedCheck, DashboardTable } from "./DashboardTable";
import { DashboardRecord } from "./DashboardRecord";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../shadcn/components/popover";
import { Button } from "../../shadcn/components/button";
import { addDays, addMonths, format } from "date-fns";
import { CalendarIcon } from "@radix-ui/react-icons";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectLabel,
  SelectGroup,
  SelectTrigger,
  SelectValue,
} from "../../shadcn/components/select";
import { Calendar } from "../../shadcn/components/calendar";
import { LoadingView } from "../../components/Loading";
import { InfoIcon, X } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import { Separator } from "../../shadcn/components/separator";
import { getFilterCounts, SelectFilter } from "../../components/FilterUtils";
import { InlineMath } from "react-katex";
import "katex/dist/katex.min.css";

const DateSelector = (props: {
  date: Date;
  setDate: (date: Date) => void;
  maxDate?: Date;
  disabled?: boolean;
}) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          disabled={props.disabled}
          variant={"outline"}
          className={cn(
            "w-[240px] justify-start text-left font-normal",
            !props.date && "text-muted-foreground"
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {props.date ? format(props.date, "PPP") : <span>Pick a date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        className="flex w-auto flex-col space-y-2 p-2"
      >
        <div className="rounded-md border">
          <Calendar
            mode="single"
            selected={props.date}
            onSelect={(date) => props.setDate(date ?? addDays(new Date(), -30))}
            toDate={props.maxDate}
            defaultMonth={props.date ?? new Date()}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
};

const HeaderCard = (props: {
  title: string;
  value: number | string;
  activeItem: string;
  setActiveItem: (item: string) => void;
  clickable: boolean;
  textColor?: string;
  info?: string;
  formula?: string;
}) => {
  return (
    <Card
      className={cn(
        "border-2 border-transparent",
        props.clickable && "cursor-pointer hover:border-gray-500",
        props.activeItem === props.title && "border-gray-500"
      )}
      onClick={() => {
        if (props.clickable) {
          props.setActiveItem(props.title);
        }
      }}
    >
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-sm font-medium">{props.title}</CardTitle>
        {props.info && (
          <Tooltip>
            <TooltipTrigger>
              <InfoIcon className="h-4 w-4 text-blue-500" />
            </TooltipTrigger>
            <TooltipContent>
              <div>{props.info}</div>
              {props.formula && (
                <div className="p-4 text-xl flex justify-center items-center">
                  <InlineMath math={props.formula} />
                </div>
              )}
            </TooltipContent>
          </Tooltip>
        )}
      </CardHeader>
      <CardContent className={props.textColor && `${props.textColor}`}>
        <div className="text-2xl font-bold">{props.value}</div>
      </CardContent>
    </Card>
  );
};

type TimePeriod =
  | "all-time"
  | "year-to-date"
  | "quarter-to-date"
  | "prior-quarter"
  | "prior-month"
  | "prior-30-days"
  | "prior-90-days"
  | "custom";

export const DashboardView = () => {
  const authInfo = useAuthInfo();
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const maxDate = new Date("12/31/2029");
  const [referenceDate, setReferenceDate] = useState(new Date("7/31/2024"));
  const [dataLoading, setDataLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date(currentYear, 0, 1));
  const [endDate, setEndDate] = useState(currentDate);
  const [timePeriod, setTimePeriod] = useState<TimePeriod>("year-to-date");
  const [data, setData] = useState<Record<string, any>[]>([]);
  const [activeRow, setActiveRow] = useState<Record<string, any> | null>(null);
  const [activeData, setActiveData] = useState<string>("Total");
  const [activeRegulator, setActiveRegulator] = useState<string[]>([]);
  const [activeDocument, setActiveDocument] = useState<string[]>([]);
  const [activeJobTitle, setActiveJobTitle] = useState<string[]>([]);
  const [activeWorkplanStatus, setActiveWorkplanStatus] = useState<string[]>(
    []
  );
  const localDayStart = "T00:00:00";

  const dataMap = useMemo(() => {
    let baseDataMap: Record<string, any[]> = {
      Pending: data.filter(
        (item) =>
          new Date(item["APL Implementation Date"] + localDayStart) >=
            startDate &&
          new Date(item["APL Implementation Date"] + localDayStart) <=
            endDate &&
          new Date(item["APL Implementation Date"] + localDayStart) >
            currentDate &&
          !completedCheck(item["WorkflowStatus"])
      ),
      "Past Due": data.filter(
        (item) =>
          new Date(item["APL Implementation Date"] + localDayStart) >=
            startDate &&
          new Date(item["APL Implementation Date"] + localDayStart) <=
            endDate &&
          new Date(item["APL Implementation Date"] + localDayStart) <=
            currentDate &&
          !completedCheck(item["WorkflowStatus"])
      ),
      Completed: data.filter(
        (item) =>
          new Date(item["APL Implementation Date"] + localDayStart) >=
            startDate &&
          new Date(item["APL Implementation Date"] + localDayStart) <=
            endDate &&
          completedCheck(item["WorkflowStatus"])
      ),
      "Due in 30 Days": data.filter(
        (item) =>
          new Date(item["APL_DateDue"]) > currentDate &&
          new Date(item["APL_DateDue"]) <
            new Date(currentDate.getTime() + 30 * 24 * 60 * 60 * 1000) &&
          !completedCheck(item["WorkflowStatus"])
      ),
      "Due in 90 Days": data.filter(
        (item) =>
          new Date(item["APL_DateDue"]) > currentDate &&
          new Date(item["APL_DateDue"]) <
            new Date(currentDate.getTime() + 90 * 24 * 60 * 60 * 1000) &&
          !completedCheck(item["WorkflowStatus"])
      ),
      "In Progress": data.filter(
        (item) =>
          new Date(item["APL Implementation Date"] + localDayStart) >=
            currentDate && !completedCheck(item["WorkflowStatus"])
      ),
    };
    baseDataMap["Total"] = [
      ...baseDataMap["Completed"],
      ...baseDataMap["Past Due"],
      ...baseDataMap["Pending"],
    ];
    return baseDataMap;
  }, [data, startDate, endDate]);

  const displayData = dataMap[activeData as keyof typeof dataMap];

  // filter for regulator
  const regulatorData = displayData.filter(
    (item) =>
      activeRegulator.includes(item["APL_Regulator"] || "Unknown") ||
      activeRegulator.length === 0
  );

  const workplanStatusData = regulatorData.filter(
    (item) =>
      activeWorkplanStatus.includes(item["WorkflowStatus"] || "Unknown") ||
      activeWorkplanStatus.length === 0
  );

  // filter for document
  const documentData = workplanStatusData.filter(
    (item) =>
      activeDocument.includes(item["APL_APLName"] || "Unknown") ||
      activeDocument.length === 0
  );

  // filter for job title
  const jobTitleData = documentData.filter(
    (item) =>
      activeJobTitle.includes(item["Job Title"] || "Unknown") ||
      activeJobTitle.length === 0
  );

  // get relevant data counts
  const regulatorDataCounts = getFilterCounts(
    displayData.map((item) => item["APL_Regulator"] || "Unknown")
  );
  const workplanStatusDataCounts = getFilterCounts(
    regulatorData.map((item) => item["WorkflowStatus"] || "Unknown")
  );
  const documentDataCounts = getFilterCounts(
    workplanStatusData.map((item) => item["APL_APLName"] || "Unknown")
  );
  const jobTitleDataCounts = getFilterCounts(
    documentData.map((item) => item["Job Title"] || "Unknown")
  );

  useEffect(() => {
    setDataLoading(true);
    getDashboardData(authInfo.accessToken ?? null).then((response) => {
      if (response !== null) {
        setReferenceDate(new Date(response.date + "T00:00:00"));
        setData(response.data);
      } else {
        toast.error("Failed to fetch dashboard data");
      }
      setDataLoading(false);
    });
  }, []);

  const handleTimePeriodChange = (value: TimePeriod) => {
    const currentYear = referenceDate.getFullYear();
    const currentMonth = referenceDate.getMonth(); // getMonth() returns 0 (January) to 11 (December)
    switch (value) {
      case "all-time":
        setStartDate(
          new Date(
            Math.min(
              ...data
                .map(
                  (item) =>
                    new Date(item["APL Implementation Date"] + localDayStart)
                )
                .filter((date) => !isNaN(date.getTime()))
                .map((date) => date.getTime())
            )
          )
        );
        setEndDate(maxDate);
        break;
      case "year-to-date":
        // get the first day of the current year
        const startOfYear = new Date(`1/1/${currentYear}`);
        setStartDate(startOfYear);
        setEndDate(referenceDate);
        break;
      case "quarter-to-date":
        // Calculate the start month of the current quarter
        const quarterStartMonth = Math.floor(currentMonth / 3) * 3;

        // Create a new date object for the start of the current quarter
        setStartDate(new Date(currentYear, quarterStartMonth, 1));
        setEndDate(referenceDate);
        break;
      case "prior-quarter":
        const currentQuarter = Math.floor(currentMonth / 3) + 1;

        // Calculate the prior quarter
        let priorQuarter = currentQuarter - 1;
        let year = currentYear;

        // Adjust year and quarter if we're in the first quarter
        if (priorQuarter === 0) {
          priorQuarter = 4;
          year -= 1;
        }

        // Calculate start month of the prior quarter
        const startMonth = (priorQuarter - 1) * 3;

        // Start date is the first day of the start month
        setStartDate(new Date(year, startMonth, 1));

        // End date is the last day of the third month of the prior quarter
        setEndDate(new Date(year, startMonth + 3, 0));
        break;
      case "prior-month":
        let priorMonth = currentMonth - 1;
        let priorYear = currentYear;
        if (priorMonth === -1) {
          priorMonth = 11;
          priorYear -= 1;
        }
        setStartDate(new Date(priorYear, priorMonth, 1));
        setEndDate(new Date(currentYear, currentMonth, 0));
        break;
      case "prior-30-days":
        setStartDate(addDays(currentDate, -30));
        setEndDate(currentDate);
        break;
      case "prior-90-days":
        setStartDate(addDays(currentDate, -90));
        setEndDate(currentDate);
        break;
      case "custom":
        break;
    }
    setTimePeriod(value);
  };

  return (
    <Layout pageName="Dashboard">
      {activeRow && (
        <DashboardRecord record={activeRow} setActiveRecord={setActiveRow} />
      )}
      {!dataLoading && (
        <>
          <div className="grid gap-4 grid-cols-[66%_1%_32%] pr-6">
            <div className="space-y-6">
              <div className="flex items-center h-12 space-x-2">
                <span className="flex flex-col text-sm text-gray-500">
                  Impl. Date:
                </span>
                <Select
                  onValueChange={handleTimePeriodChange}
                  value={timePeriod}
                >
                  <SelectTrigger className="w-[200px] bg-white">
                    <SelectValue placeholder="Select Implementation Date Range" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectLabel className="pr-10">
                        Implementation Date Range
                      </SelectLabel>
                      <SelectItem value="all-time">All Time</SelectItem>
                      <SelectItem value="year-to-date">Year to Date</SelectItem>
                      <SelectItem value="quarter-to-date">
                        Quarter to Date
                      </SelectItem>
                      <SelectItem value="prior-quarter">
                        Last Quarter
                      </SelectItem>
                      <SelectItem value="prior-month">Last Month</SelectItem>
                      <SelectItem value="prior-30-days">
                        Last 30 Days
                      </SelectItem>
                      <SelectItem value="prior-90-days">
                        Last 90 Days
                      </SelectItem>
                      <SelectItem value="custom">Custom</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
                <div className="text-sm text-gray-500">From:</div>
                <DateSelector
                  date={startDate}
                  setDate={setStartDate}
                  maxDate={addDays(endDate, -1)}
                  disabled={timePeriod !== "custom"}
                />
                <div className="text-sm text-gray-500">To:</div>
                <DateSelector
                  date={endDate}
                  setDate={setEndDate}
                  maxDate={maxDate}
                  disabled={timePeriod !== "custom"}
                />
              </div>
              <div className="grid gap-4 grid-cols-3">
                <HeaderCard
                  title="Total"
                  value={dataMap["Total"].length}
                  activeItem={activeData}
                  setActiveItem={setActiveData}
                  clickable={true}
                  info="All Tasks Within Date Range"
                />
                <HeaderCard
                  title="Compliance Rate"
                  value={`${(
                    (dataMap["Completed"].length /
                      (dataMap["Completed"].length +
                        dataMap["Past Due"].length)) *
                    100
                  ).toFixed(1)}%`}
                  activeItem={activeData}
                  setActiveItem={setActiveData}
                  clickable={false}
                  info="Percentage of tasks compliant."
                  formula="\frac{\text{Completed}}{\text{Completed} + \text{Past Due}}"
                />
                <HeaderCard
                  title="Non-Compliance Rate"
                  value={`${(
                    (dataMap["Past Due"].length /
                      (dataMap["Completed"].length +
                        dataMap["Past Due"].length)) *
                    100
                  ).toFixed(1)}%`}
                  activeItem={activeData}
                  setActiveItem={setActiveData}
                  clickable={false}
                  info="Percentage of tasks implemented."
                  formula="\frac{\text{Past Due}}{\text{Completed} + \text{Past Due}}"
                />
                <HeaderCard
                  title="Completed"
                  value={dataMap["Completed"].length}
                  activeItem={activeData}
                  setActiveItem={setActiveData}
                  textColor="text-green-500"
                  clickable={true}
                  info="Completed Tasks Within Date Range"
                />
                <HeaderCard
                  title="Pending"
                  value={dataMap["Pending"].length}
                  activeItem={activeData}
                  setActiveItem={setActiveData}
                  textColor="text-yellow-500"
                  clickable={true}
                  info="Pending Tasks Within Date Range"
                />
                <HeaderCard
                  title="Past Due"
                  value={dataMap["Past Due"].length}
                  activeItem={activeData}
                  setActiveItem={setActiveData}
                  textColor="text-red-500"
                  clickable={true}
                  info="Past Due Tasks Within Date Range"
                />
              </div>
            </div>
            <Separator orientation="vertical" className="h-full ml-2" />
            <div className="space-y-6">
              <div className="flex h-12 items-center justify-between">
                <div className="text-xl font-semibold">Upcoming</div>
                <div className="text-xs text-gray-500 italic pl-2">
                  Last Updated: {format(referenceDate, "MMM d, y")}
                </div>
              </div>
              <div className="grid gap-4 grid-cols-2">
                <HeaderCard
                  title="In Progress"
                  value={dataMap["In Progress"].length}
                  activeItem={activeData}
                  setActiveItem={setActiveData}
                  textColor="text-yellow-500"
                  clickable={true}
                  info="All Pending Tasks"
                />
                <div />
                <HeaderCard
                  title="Due in 30 Days"
                  value={dataMap["Due in 30 Days"].length}
                  activeItem={activeData}
                  setActiveItem={setActiveData}
                  textColor="text-orange-500"
                  clickable={true}
                />
                <HeaderCard
                  title="Due in 90 Days"
                  value={dataMap["Due in 90 Days"].length}
                  activeItem={activeData}
                  setActiveItem={setActiveData}
                  textColor="text-orange-500"
                  clickable={true}
                />
              </div>
            </div>
          </div>
          <div className="pb-10">
            <DashboardTable
              data={jobTitleData.sort((a, b) => {
                return (
                  new Date(b["APL Implementation Date"]).getTime() -
                  new Date(a["APL Implementation Date"]).getTime()
                );
              })}
              setActiveRow={setActiveRow}
              endDate={endDate}
            >
              {activeData ? (
                <div className="flex items-center justify-end space-x-2">
                  {(activeRegulator.length > 0 ||
                    activeDocument.length > 0 ||
                    activeJobTitle.length > 0 ||
                    activeWorkplanStatus.length > 0) && (
                    <Button
                      variant="outline"
                      className="text-red-500"
                      onClick={() => {
                        setActiveRegulator([]);
                        setActiveDocument([]);
                        setActiveJobTitle([]);
                        setActiveWorkplanStatus([]);
                      }}
                    >
                      <X className="h-4 w-4 mr-2" />
                      Reset
                    </Button>
                  )}
                  <SelectFilter
                    title="Regulator"
                    filterCounts={regulatorDataCounts}
                    activeFilter={activeRegulator}
                    setActiveFilter={setActiveRegulator}
                  />
                  <SelectFilter
                    title="Workplan Status"
                    filterCounts={workplanStatusDataCounts}
                    activeFilter={activeWorkplanStatus}
                    setActiveFilter={setActiveWorkplanStatus}
                  />
                  <SelectFilter
                    title="Document"
                    filterCounts={documentDataCounts}
                    activeFilter={activeDocument}
                    setActiveFilter={setActiveDocument}
                  />
                  <SelectFilter
                    title="Responsible Party"
                    filterCounts={jobTitleDataCounts}
                    activeFilter={activeJobTitle}
                    setActiveFilter={setActiveJobTitle}
                  />
                </div>
              ) : (
                <div className="w-[600px] flex items-center justify-center">
                  <div className="text-center">
                    Please click on an item above to view additional filters
                  </div>
                </div>
              )}
            </DashboardTable>
          </div>
        </>
      )}
      {dataLoading && <LoadingView customText="Loading dashboard data..." />}
    </Layout>
  );
};
