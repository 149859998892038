import { SearchDocName } from "../types";
import { useEffect, useRef, useState } from "react";
import { docNameClick, searchDocName } from "../utils/apiCalls";
import { useAuthInfo } from "@propelauth/react";
import { toast } from "sonner";
import {
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandNoHeight,
} from "../shadcn/components/command";
import { ReloadIcon } from "@radix-ui/react-icons";
import { cn } from "../shadcn/lib/utils";
import { FileSelectorDialog } from "./FileSelectorDialog";

export const SearchDocNameComponent = (props: {
  docTypeName: string;
  docName: string;
}) => {
  return (
    <>
      <div className="text-muted-foreground">{`${props.docTypeName}`}</div>
      <div className="font-semibold break-words">{props.docName}</div>
    </>
  );
};

const SearchResult = (props: {
  result: SearchDocName;
  queryId: string;
  onItemSelect: (result: SearchDocName) => void;
  setSearchTerm: (term: string) => void;
  setSearchResults: (results: {
    results: SearchDocName[];
    query_id: string;
  }) => void;
  setInputActive: (active: boolean) => void;
}) => {
  const authInfo = useAuthInfo();
  const onSelect = () => {
    props.onItemSelect(props.result);
    props.setSearchTerm("");
    props.setSearchResults({ results: [], query_id: "" });
    props.setInputActive(false);

    if (props.result.id !== null) {
      docNameClick(
        props.queryId,
        props.result.doc_id,
        authInfo?.accessToken ?? null
      );
    }
  };

  return (
    <CommandItem
      key={`${props.result.doc_id}`}
      onSelect={onSelect}
      onClick={onSelect}
    >
      <div className="w-full p-2 pl-6 space-y-1">
        <SearchDocNameComponent
          docTypeName={props.result.doc_type_name}
          docName={props.result.name}
        />
      </div>
    </CommandItem>
  );
};

export const SearchDocNameBar = (props: {
  docTypeIds: string[];
  onItemSelect: (result: SearchDocName) => void;
  suggestions?: SearchDocName[];
  placeholder?: string;
  nonRelative?: boolean;
  autoSelect?: boolean;
  disabled?: boolean;
  hideAtlasWidget?: boolean;
}) => {
  const authInfo = useAuthInfo();
  const [searchResults, setSearchResults] = useState<{
    results: SearchDocName[];
    query_id: string;
  }>({ results: [], query_id: "" });
  const [searchTerm, setSearchTerm] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [containerWidth, setContainerWidth] = useState<number | null>(null);
  const [inputActive, setInputActive] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const resultRef = useRef<HTMLDivElement>(null);
  const cancelRef = useRef<boolean>(false);

  const suggestionsToDisplay = props.suggestions
    ? props.suggestions.filter((suggestion) =>
        suggestion.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : null;

  const filenameResults = searchResults.results.filter(
    (result) => result.result_type === "filename"
  );

  const contentResults = searchResults.results.filter(
    (result) => result.result_type === "content"
  );

  const containerRef = (container: HTMLDivElement) => {
    if (container) {
      setContainerWidth(container.clientWidth);
    }
  };

  const handleSearch = async (searchQuery: string) => {
    cancelRef.current = false;
    setSearchLoading(true);
    try {
      for await (const output of searchDocName(
        searchQuery,
        props.docTypeIds,
        authInfo.accessToken ?? null
      )) {
        if (cancelRef.current) {
          break;
        }
        setSearchResults(output);
      }
    } catch (error: any) {
      console.error("There was an error running the search", error);
      toast.error("Unable to run search");
    }
    setSearchLoading(false);
  };

  useEffect(() => {
    // wait for the user to stop typing
    const timeoutId = setTimeout(() => {
      if (searchLoading) {
        cancelRef.current = true;
      }
      if (searchTerm) {
        handleSearch(searchTerm);
      } else {
        setSearchResults({ results: [], query_id: "" });
      }
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [searchTerm]);

  useEffect(() => {
    if (props.autoSelect) {
      inputRef.current?.focus();
    } else {
      inputRef.current?.blur();
      setInputActive(false);
    }
  }, [props.autoSelect]);

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        event.preventDefault();
        setSearchTerm("");
        setSearchResults({ results: [], query_id: "" });
        setInputActive(false);
      }
    };

    const onClickOutside = (e: MouseEvent) => {
      if (resultRef.current && !resultRef.current.contains(e.target as Node)) {
        setInputActive(false);
      }
    };

    document.addEventListener("keydown", handleEscape);
    document.addEventListener("mousedown", onClickOutside);

    return () => {
      document.removeEventListener("keydown", handleEscape);
      document.removeEventListener("mousedown", onClickOutside);
    };
  }, []);
  return (
    <div className={cn(!props.nonRelative && "relative")} ref={containerRef}>
      <CommandNoHeight shouldFilter={false}>
        <CommandInput
          placeholder={props.placeholder ?? "Search Documents..."}
          className="text-base"
          onValueChange={setSearchTerm}
          value={searchTerm}
          onFocus={() => setInputActive(true)}
          ref={inputRef}
          disabled={props.disabled}
        >
          <div className="flex justify-end">
            <FileSelectorDialog
              onItemSelect={props.onItemSelect}
              docTypeIds={props.docTypeIds}
              hideAtlasWidget={props.hideAtlasWidget}
            />
          </div>
        </CommandInput>
        <CommandList
          className={cn(
            "absolute mt-11 max-h-[300px] overflow-y-auto z-10 bg-white px-2",
            searchResults.results.length > 0 &&
              "border border-4 border-gray-200 rounded-md"
          )}
          style={{
            width: containerWidth ? `${containerWidth}px` : "auto",
          }}
          ref={resultRef}
        >
          {searchLoading && (
            <div className="flex items-center justify-center text-muted-foreground text-sm py-5">
              <ReloadIcon className="animate-spin h-4 w-4 mr-2" />
              Loading results...
            </div>
          )}
          {filenameResults.length > 0 && (
            <CommandGroup heading="Filename Matches">
              {filenameResults.map((result) => (
                <SearchResult
                  key={result.doc_id}
                  queryId={searchResults.query_id}
                  result={result}
                  onItemSelect={props.onItemSelect}
                  setSearchTerm={setSearchTerm}
                  setSearchResults={setSearchResults}
                  setInputActive={setInputActive}
                />
              ))}
            </CommandGroup>
          )}
          {contentResults.length > 0 && (
            <CommandGroup heading="Content Matches">
              {contentResults.map((result) => (
                <SearchResult
                  key={result.doc_id}
                  queryId={searchResults.query_id}
                  result={result}
                  onItemSelect={props.onItemSelect}
                  setSearchTerm={setSearchTerm}
                  setSearchResults={setSearchResults}
                  setInputActive={setInputActive}
                />
              ))}
            </CommandGroup>
          )}
          {suggestionsToDisplay && inputActive && (
            <CommandGroup heading="Suggestions">
              {suggestionsToDisplay.length > 0 ? (
                suggestionsToDisplay.map((suggestion) => (
                  <SearchResult
                    key={suggestion.doc_id}
                    result={suggestion}
                    queryId={searchResults.query_id}
                    onItemSelect={props.onItemSelect}
                    setSearchTerm={setSearchTerm}
                    setSearchResults={setSearchResults}
                    setInputActive={setInputActive}
                  />
                ))
              ) : (
                <div className="pl-2 text-xs italic text-gray-400">
                  None at the moment
                </div>
              )}
            </CommandGroup>
          )}
        </CommandList>
      </CommandNoHeight>
    </div>
  );
};
