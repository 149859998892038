import { useContext, useEffect, useRef, useState } from "react";
import { Citation, Department, SearchDocName } from "../../types";
import { toast } from "sonner";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../../shadcn/components/dialog";
import { Button } from "../../shadcn/components/button";
import { SearchDocNameBar } from "../SearchDocName";
import { DocViewerCitation, IndividualDocSearchView } from "../DocViewer";
import { ReloadIcon } from "@radix-ui/react-icons";
import { DocViewerContext } from "../../contexts/DocViewerContext";
import { useAuthInfo } from "@propelauth/react";
import { toggleAtlasWidget } from "../../utils/cookies";
import { useModalContext } from "../../contexts/ActiveModalContext";

export const NewCitation = (props: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  saveClick: (
    citation: Citation
  ) => Promise<{ citation_ids: string[]; departments?: Department[] } | null>;
  relevantDocs: SearchDocName[];
  allowedDocTypeIds: string[];
  headerChildren?: React.ReactNode;
  question?: string;
  successCallback?: (citation: Citation, departments?: Department[]) => void;
  existingCitation?: Citation;
  onCancel?: () => void;
  allowedDocId?: string;
  hideAtlasWidget?: boolean;
}) => {
  const {
    pageNumber,
    setPageNumber,
    setCitationText,
    citationText,
    docToView,
    setDocToView,
  } = useContext(DocViewerContext);
  const authInfo = useAuthInfo();
  const [searchDoc, setSearchDoc] = useState<SearchDocName | null>(null);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const activeModalRef = useRef<HTMLElement>(null);
  const { setActiveModalRef } = useModalContext();
  useEffect(() => {
    if (props.existingCitation && props.open) {
      if (searchDoc === null) {
        setSearchDoc({
          id: null,
          doc_id: props.existingCitation.doc_id!,
          name: props.existingCitation.doc_name!,
          doc_type_name: "",
          additional_metadata: {},
          result_type: "filename",
          citation: null,
        });
      }
      setCitationText({
        match: props.existingCitation.text,
        exactMatch: false,
        page: props.existingCitation.page,
      });
      setPageNumber(props.existingCitation.page);
    } else if (props.open && !props.existingCitation) {
      setCitationText({
        match: "",
        exactMatch: false,
        page: 1,
      });
      setDocToView(null);
    }
  }, [props.existingCitation, props.open]);

  useEffect(() => {
    if (searchDoc?.doc_id && docToView?.docId !== searchDoc.doc_id) {
      setDocToView({
        docId: searchDoc.doc_id,
      });
      if (props.existingCitation) {
        setCitationText({
          match: props.existingCitation.text,
          exactMatch: false,
          page: props.existingCitation.page,
        });
        setPageNumber(props.existingCitation.page);
      } else if (searchDoc.citation !== null) {
        setCitationText({
          match: searchDoc.citation.text,
          exactMatch: false,
          page: searchDoc.citation.page,
        });
        setPageNumber(searchDoc.citation.page);
      }
    }
  }, [searchDoc?.doc_id]);

  useEffect(() => {
    setActiveModalRef(activeModalRef);

    return () => {
      setActiveModalRef(null);
    };
  }, [activeModalRef.current]);
  const clearData = async (saveSuccess: boolean) => {
    setSearchDoc(null);
    if (!saveSuccess) {
      if (props.existingCitation) {
        setCitationText({
          match: props.existingCitation.text,
          exactMatch: false,
          page: props.existingCitation.page,
        });
        setPageNumber(props.existingCitation.page);
        if (props.existingCitation.doc_id) {
          setDocToView({
            docId: props.existingCitation.doc_id,
          });
        }
      } else {
        setCitationText(null);
        setPageNumber(1);
      }
    }
  };

  const addCitation = async () => {
    setSaveLoading(true);
    const newCitation = {
      id: props.existingCitation?.id ?? "",
      text: citationText?.match ?? "",
      page: pageNumber,
      doc_id: searchDoc?.doc_id ?? "",
      doc_name: searchDoc?.name ?? "",
      formatted_text: citationText?.match ?? "",
      created_at: new Date().toISOString().slice(0, -1),
      user: {
        id: authInfo.user?.userId ?? "unknown",
        email: authInfo.user?.email ?? "unknown",
        first_name: authInfo.user?.firstName ?? "unknown",
        last_name: authInfo.user?.lastName ?? "unknown",
      },
      start_index: null,
    };

    const response = await props.saveClick(newCitation);
    if (response !== null) {
      newCitation.id = response.citation_ids[0];
      if (props.successCallback) {
        props.successCallback(newCitation, response.departments);
      }
      clearData(true);
      props.setOpen(false);
      toast.success(
        `Citation ${props.existingCitation ? "updated" : "added"} successfully`
      );
    } else {
      toast.error(
        `Failed to ${props.existingCitation ? "update" : "add"} citation`
      );
    }
    setSaveLoading(false);
  };

  useEffect(() => {
    if (props.hideAtlasWidget) {
      toggleAtlasWidget(props.open);
    }
  }, [props.open]);

  return (
    <Dialog
      open={props.open}
      onOpenChange={(dOpen) => {
        if (!dOpen) {
          clearData(false);
          if (props.onCancel) {
            props.onCancel();
          }
        }
        props.setOpen(dOpen);
      }}
    >
      <DialogContent
        className="flex flex-col max-w-[90%] h-[90%]"
        ref={activeModalRef as React.RefObject<HTMLDivElement>}
      >
        <DialogHeader>
          <div className="flex items-center justify-between">
            <div className="space-y-2">
              <DialogTitle>{props.title}</DialogTitle>
              <DialogDescription>{props.headerChildren}</DialogDescription>
            </div>
            <Button
              variant="default"
              className="w-[120px] mr-12"
              onClick={() => addCitation()}
            >
              Save
              {saveLoading && (
                <ReloadIcon className="w-4 h-4 ml-2 animate-spin" />
              )}
            </Button>
          </div>
        </DialogHeader>
        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <SearchDocNameBar
              docTypeIds={props.allowedDocTypeIds}
              onItemSelect={(item) => {
                setSearchDoc(item);
              }}
              nonRelative={true}
              suggestions={props.relevantDocs}
              placeholder="Search Documents..."
              autoSelect={searchDoc === null}
              disabled={props.allowedDocId !== undefined}
              hideAtlasWidget={false}
            />
            {docToView?.docId && props.open && (
              <IndividualDocSearchView question={props.question} />
            )}
          </div>
          {docToView?.docId && (
            <DocViewerCitation
              enableHighlight={true}
              docId={docToView?.docId}
              hideAtlasWidget={false}
              className="h-[calc(100vh-300px)]"
            />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
