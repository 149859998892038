import React, {
  createContext,
  useContext,
  useRef,
  useState,
  RefObject,
} from "react";

interface ModalContextProps {
  activeModalRef: RefObject<HTMLElement> | null;
  setActiveModalRef: (ref: RefObject<HTMLElement> | null) => void;
}

const ModalContext = createContext<ModalContextProps>({
  activeModalRef: null,
  setActiveModalRef: () => {},
});

export const ModalProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [activeModalRef, setActiveModalRef] =
    useState<RefObject<HTMLElement> | null>(null);

  return (
    <ModalContext.Provider value={{ activeModalRef, setActiveModalRef }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => {
  return useContext(ModalContext);
};
