import { v4 as uuidv4 } from "uuid";
import { useEffect, useState } from "react";
import { Button } from "../../shadcn/components/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from "../../shadcn/components/dialog";
import { Scheduler } from "./Scheduler";
import { ApprovalConfig, ApprovalFlow } from "../../types";
import { getApprovalFlows, updateApprovalConfig } from "../../utils/apiCalls";
import { toast } from "sonner";
import { useAuthInfo } from "@propelauth/react";
import { PlusIcon, ReloadIcon } from "@radix-ui/react-icons";
import { Switch } from "../../shadcn/components/switch";
import { Label } from "../../shadcn/components/label";
import { ApprovalFlowBuilder } from "./ApprovalFlowBuilder";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../shadcn/components/select";
import { Input } from "../../shadcn/components/input";

export const DocApprovalCreatorDialog = (props: {
  docId: string;
  approvalConfig: ApprovalConfig | null;
  setApprovalConfig: React.Dispatch<
    React.SetStateAction<ApprovalConfig | null>
  >;
}) => {
  const authInfo = useAuthInfo();
  const [open, setOpen] = useState(false);
  const [configLoading, setConfigLoading] = useState(false);
  const [approvalFlows, setApprovalFlows] = useState<ApprovalFlow[]>([]);
  const [chosenApprovalFlow, setChosenApprovalFlow] =
    useState<ApprovalFlow | null>(null);

  const addApprovalFlow = () => {
    const newApprovalFlow = {
      id: uuidv4(),
      name: "New Approval Flow",
      flow: [],
    } as ApprovalFlow;
    setChosenApprovalFlow(newApprovalFlow);
  };

  useEffect(() => {
    getApprovalFlows(authInfo.accessToken ?? "").then((flows) => {
      if (flows !== null) {
        setApprovalFlows(flows);
        setChosenApprovalFlow(
          flows.find((flow) => flow.id === props.approvalConfig?.flow_id) ??
            flows[0]
        );
      } else {
        toast.error("Failed to get approval flows");
      }
    });
  }, [authInfo.accessToken]);

  const onClickUpdate = async () => {
    // TODO: validate provided flow
    if (props.approvalConfig) {
      setConfigLoading(true);
      const response = await updateApprovalConfig(
        props.docId,
        props.approvalConfig,
        chosenApprovalFlow,
        authInfo.accessToken ?? ""
      );
      setConfigLoading(false);
      if (response !== null) {
        toast.success("Approval config updated");
        props.setApprovalConfig((prev) => {
          if (prev && prev.schedule) {
            return {
              ...prev,
              schedule: response.schedule_instance_id
                ? {
                    ...prev.schedule,
                    schedule_instance_id: response.schedule_instance_id,
                  }
                : null,
            };
          }
          return prev;
        });
        setOpen(false);
      } else {
        toast.error("Failed to update approval config");
      }
    }
  };

  useEffect(() => {
    props.setApprovalConfig((prev) => {
      if (prev) {
        return {
          ...prev,
          flow_id: chosenApprovalFlow?.id ?? null,
        };
      }
      return prev;
    });
  }, [chosenApprovalFlow]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button size="sm" variant="outline">
          {props.approvalConfig?.flow_id ? "Edit" : "Create"}
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-[90%] h-[90%]">
        <DialogTitle>Approval Config</DialogTitle>
        <div className="h-[85%] overflow-auto">
          <div className="space-y-2">
            <div className="flex items-center space-x-2 w-[600px]">
              <Select
                value={chosenApprovalFlow?.id}
                onValueChange={(id) => {
                  setChosenApprovalFlow(
                    approvalFlows.find((flow) => flow.id === id) ?? null
                  );
                }}
              >
                <SelectTrigger className="bg-white">
                  <SelectValue placeholder="Select approval flow.." />
                </SelectTrigger>
                <SelectContent>
                  {approvalFlows.map((flow) => (
                    <SelectItem value={flow.id} key={flow.id}>
                      {flow.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <Button
                variant="default"
                className="w-[400px]"
                onClick={addApprovalFlow}
              >
                <PlusIcon className="h-4 w-4" />
                New Approval Workflow
              </Button>
            </div>
            <div className="flex items-center space-x-2 w-[600px]">
              <Label htmlFor="approval-flow-name">Name</Label>
              <Input
                value={chosenApprovalFlow?.name}
                onChange={(e) => {
                  const name = e.target.value;
                  setChosenApprovalFlow((prev) =>
                    prev ? { ...prev, name } : null
                  );
                }}
                id="approval-flow-name"
              />
            </div>
            <div className="flex items-center space-x-2">
              <Switch
                id="attestation-toggle"
                checked={props.approvalConfig?.attestation ?? false}
                onCheckedChange={(attestation) =>
                  props.setApprovalConfig((prev) => {
                    if (prev) {
                      return {
                        ...prev,
                        attestation: attestation,
                      };
                    } else {
                      return {
                        attestation: attestation,
                        schedule: null,
                        flow_id: chosenApprovalFlow?.id ?? null,
                      } as ApprovalConfig;
                    }
                  })
                }
              />
              <Label htmlFor="attestation-toggle">
                Require Attestation Once Approved?
              </Label>
            </div>
          </div>
          <ApprovalFlowBuilder
            approvalStages={chosenApprovalFlow?.flow ?? []}
            setChosenApprovalFlow={setChosenApprovalFlow}
          />
        </div>
        <DialogFooter>
          <Button onClick={onClickUpdate} disabled={!props.approvalConfig}>
            Save
            {configLoading && (
              <ReloadIcon className="ml-2 h-4 w-4 animate-spin" />
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
