import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "../shadcn/components/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../shadcn/components/tooltip";
import {
  FileIcon,
  GearIcon,
  MagnifyingGlassIcon,
  ArchiveIcon,
  ClipboardIcon,
  EyeOpenIcon,
  RulerSquareIcon,
  DashboardIcon,
} from "@radix-ui/react-icons";
import { InboxStackIcon } from "./Icons";
import { useContext } from "react";
import { Separator } from "../shadcn/components/separator";
import { cn } from "../shadcn/lib/utils";
import { UserContext } from "../contexts/UserContext";

const SidebarButton = (props: {
  icon: any;
  path: string;
  name: string;
  collapsed: boolean;
  currPage: string;
}) => {
  const navigate = useNavigate();
  const pathRoot = props.path.split("/")[1].toLowerCase();
  const isActive = props.currPage.toLowerCase() === pathRoot;

  return props.collapsed ? (
    <div className="flex justify-start">
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            className={cn(
              "ml-1",
              isActive &&
                "bg-gray-800 text-white hover:bg-gray-800 hover:text-white"
            )}
            size="icon"
            variant="ghost"
            onClick={() => navigate(props.path)}
          >
            <props.icon
              className={cn(
                "h-5 w-5",
                isActive ? "text-gray-200" : "text-gray-600"
              )}
            />
          </Button>
        </TooltipTrigger>
        <TooltipContent side="right">{props.name}</TooltipContent>
      </Tooltip>
    </div>
  ) : (
    <Button
      className={cn(
        "w-[calc(100%-8px)] justify-start text-sm ml-1",
        isActive && "bg-gray-800 text-white hover:bg-gray-800 hover:text-white"
      )}
      size="icon"
      variant="ghost"
      onClick={() => navigate(props.path)}
    >
      <props.icon
        className={cn(
          "h-5 w-5 ml-2.5 mr-2 min-w-[20px]",
          isActive ? "text-gray-200" : "text-gray-600"
        )}
      />
      <span
        className={cn(
          "text-start overflow-hidden whitespace-nowrap",
          isActive ? "text-gray-200" : "text-gray-6000"
        )}
      >
        {props.name}
      </span>
    </Button>
  );
};

declare global {
  interface Window {
    Atlas: any;
  }
}

export const SideNav = (props: { collapsed: boolean }) => {
  const location = useLocation();
  const { features, org, isAgent } = useContext(UserContext);

  const currPage = location.pathname.split("/")[1];

  return (
    <div
      data-collapsed={props.collapsed}
      className="h-full bg-white overflow-hidden p-2 flex flex-col border-r border-gray-200"
    >
      <div className="flex-grow space-y-2">
        <div
          className={cn(
            !props.collapsed
              ? "flex items-center"
              : "flex items-center justify-start"
          )}
        >
          <div className="flex-shrink-0">
            <img
              src="/diligent-logo.png"
              alt="Logo"
              className="h-auto w-12 transition-all duration-300 ease-in-out"
            />
          </div>
          <div className="flex flex-col h-[60px] justify-center">
            {!props.collapsed && (
              <span className="ml-2.5 font-bold">diligent</span>
            )}
            {org && !props.collapsed && (
              <div
                className="ml-2.5 b-2 text-sm text-gray-500 items-center mt-[-2px] overflow-hidden text-ellipsis"
                style={{
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                }}
              >
                {org}
              </div>
            )}
          </div>
        </div>
        <Separator />
        {(features.includes("search") || isAgent) && (
          <SidebarButton
            icon={MagnifyingGlassIcon}
            name="Search"
            path="/search"
            collapsed={props.collapsed}
            currPage={currPage}
          />
        )}
        {(features.includes("search") || isAgent) && (
          <SidebarButton
            icon={FileIcon}
            name="Document Viewer"
            path="/doc-chat"
            collapsed={props.collapsed}
            currPage={currPage}
          />
        )}
        {(features.includes("regulatory_doc") || isAgent) && (
          <SidebarButton
            icon={InboxStackIcon}
            name="Regulations"
            path="/regulatory-doc"
            collapsed={props.collapsed}
            currPage={currPage}
          />
        )}
        {(features.includes("gap_analysis") || isAgent) && (
          <SidebarButton
            icon={RulerSquareIcon}
            name="Gap Analysis"
            path="/gap-analysis"
            collapsed={props.collapsed}
            currPage={currPage}
          />
        )}
        {(features.includes("audit") || isAgent) && (
          <SidebarButton
            icon={ClipboardIcon}
            name="Audit"
            path="/audit"
            collapsed={props.collapsed}
            currPage={currPage}
          />
        )}
        {(features.includes("insight") || isAgent) && (
          <SidebarButton
            icon={EyeOpenIcon}
            name="Insight"
            path="/insight"
            collapsed={props.collapsed}
            currPage={currPage}
          />
        )}
        {(features.includes("policy_repo") || isAgent) && (
          <SidebarButton
            icon={ArchiveIcon}
            name="Policy Repository"
            path="/policy-repo"
            collapsed={props.collapsed}
            currPage={currPage}
          />
        )}
        {(features.includes("dashboard") || isAgent) && (
          <SidebarButton
            icon={DashboardIcon}
            name="Dashboard"
            path="/dashboard"
            collapsed={props.collapsed}
            currPage={currPage}
          />
        )}
      </div>
      <div className="mb-2 space-y-2">
        <Separator />
        <SidebarButton
          icon={GearIcon}
          name="Settings"
          path="/settings"
          collapsed={props.collapsed}
          currPage={currPage}
        />
      </div>
      <div className="absolute bottom-0 w-full"></div>
    </div>
  );
};
