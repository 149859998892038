import { useEffect, useRef, useState } from "react";
import {
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandNoHeight,
} from "../../shadcn/components/command";
import { PreviousSearch } from "../../types";
import { loadAndFormatTime } from "../../utils/format";
import { cn } from "../../shadcn/lib/utils";

export const SearchBar = (props: {
  previousSearches: PreviousSearch[];
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  selectSearch: (searchId: string) => void;
  handleSearch: () => void;
  disabled: boolean;
}) => {
  const [containerWidth, setContainerWidth] = useState<number | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const resultRef = useRef<HTMLDivElement>(null);
  const [inputFocused, setInputFocused] = useState(false);

  const containerRef = (container: HTMLDivElement) => {
    if (container) {
      setContainerWidth(container.clientWidth);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      if (props.searchTerm) {
        event.preventDefault();
        props.handleSearch();
        setInputFocused(false);
        inputRef.current?.blur();
      }
    }
  };

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        event.preventDefault();
        setInputFocused(false);
        inputRef.current?.blur();
      }
    };

    const onClickOutside = (e: MouseEvent) => {
      if (resultRef.current && !resultRef.current.contains(e.target as Node)) {
        setInputFocused(false);
        inputRef.current?.blur();
      }
    };

    document.addEventListener("keydown", handleEscape);
    document.addEventListener("mousedown", onClickOutside);

    return () => {
      document.removeEventListener("keydown", handleEscape);
      document.removeEventListener("mousedown", onClickOutside);
    };
  }, []);

  const previousSearchesToDisplay = props.previousSearches.filter((search) =>
    search.query.includes(props.searchTerm)
  );

  return (
    <div className="relative" ref={containerRef}>
      <CommandNoHeight shouldFilter={false}>
        <CommandInput
          placeholder="Search..."
          className="text-base"
          onValueChange={props.setSearchTerm}
          value={props.searchTerm}
          disabled={props.disabled}
          onKeyDown={handleKeyDown}
          onFocus={() => setInputFocused(true)}
          ref={inputRef}
        />
        <CommandList
          className={cn(
            "absolute mt-11 max-h-[300px] overflow-y-auto z-10 bg-white px-2",
            previousSearchesToDisplay.length > 0 &&
              inputFocused &&
              previousSearchesToDisplay.length > 0 &&
              "border border-4 border-gray-200 rounded-md"
          )}
          style={{
            width: containerWidth ? `${containerWidth}px` : "auto",
          }}
          ref={resultRef}
        >
          {inputFocused && (
            <CommandGroup
              heading={
                previousSearchesToDisplay.length === 0 ? "" : "Recent Searches"
              }
            >
              {previousSearchesToDisplay.slice(0, 10).map((previousSearch) => (
                <CommandItem
                  key={`${previousSearch.id}`}
                  onSelect={() => {
                    props.selectSearch(previousSearch.id);
                    setInputFocused(false);
                  }}
                  onClick={() => {
                    props.selectSearch(previousSearch.id);
                    setInputFocused(false);
                  }}
                >
                  <div className="w-full p-1 space-y-1">
                    <div className="font-semibold break-words">
                      {previousSearch.query}
                    </div>
                    <div className="text-muted-foreground text-xs italic">
                      {loadAndFormatTime(previousSearch.timestamp)}
                    </div>
                    <div className="hidden">
                      {previousSearch.id.slice(0, 5)}
                    </div>
                  </div>
                </CommandItem>
              ))}
            </CommandGroup>
          )}
        </CommandList>
      </CommandNoHeight>
    </div>
  );
};
