import {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import useResizeObserver from "@react-hook/resize-observer";

import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import pdfWorker from "../assets/pdf.worker.min.mjs?url";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../shadcn/components/tooltip";
import { Badge } from "../shadcn/components/badge";
import { Button } from "../shadcn/components/button";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ReaderIcon,
  ReloadIcon,
  RotateCounterClockwiseIcon,
} from "@radix-ui/react-icons";
import { Input } from "../shadcn/components/input";
import { LoadingView } from "./Loading";
import { Citation, ExactMatches } from "../types";
import { DocViewerContext } from "../contexts/DocViewerContext";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../shadcn/components/dropdown-menu";
import { MoreVertical } from "lucide-react";
import { toast } from "sonner";
import { useAuthInfo } from "@propelauth/react";
import {
  createPdfUrl,
  downloadData,
  individualDocClick,
  searchIndividualDoc,
} from "../utils/apiCalls";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../shadcn/components/accordion";
import { PdfHighlighterProvider, usePdfHighlighter } from "./PdfHighlighter";
import { cn } from "../shadcn/lib/utils";
import React from "react";

pdfjs.GlobalWorkerOptions.workerSrc = pdfWorker;

const ClickableCitation = (props: {
  queryId: string | null;
  citation: Citation;
}) => {
  const authInfo = useAuthInfo();
  const { setCitationText, setPageNumber } = useContext(DocViewerContext);
  return (
    <div
      className="text-left gap-4 p-3 w-full rounded-md shadow-md cursor-pointer hover:bg-gray-100"
      key={props.citation.id}
      onClick={() => {
        setCitationText({
          match: props.citation.text,
          exactMatch: false,
          page: props.citation.page,
        });
        setPageNumber(props.citation.page);
        if (props.queryId) {
          individualDocClick(
            props.queryId,
            "result",
            props.citation.id!,
            authInfo.accessToken ?? null
          );
        }
      }}
    >
      {props.citation.text.slice(0, 100)}...
    </div>
  );
};

export const IndividualDocSearchView = (props: { question?: string }) => {
  const authInfo = useAuthInfo();
  const { docToView, setCitationText, setPageNumber } =
    useContext(DocViewerContext);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<{
    contentMatch: Citation[];
    exactMatch: ExactMatches | null;
    queryId: string;
  } | null>(null);
  const [suggestionResults, setSuggestionResults] = useState<{
    question: string;
    docId: string;
    results: Citation[];
  } | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSuggestions = async () => {
    if (props.question) {
      setSuggestionResults(null);
      try {
        for await (const output of searchIndividualDoc(
          props.question,
          docToView?.docId ?? "",
          false,
          authInfo.accessToken ?? null
        )) {
          setSuggestionResults({
            question: props.question,
            docId: docToView?.docId ?? "",
            results: output.search_results,
          });
        }
      } catch (error: any) {
        console.error("There was an error getting suggestions", error);
        toast.error("Unable to get suggestions");
      }
    }
  };

  const handleSearch = async (searchTerm: string) => {
    setSearchLoading(true);
    setSearchResults(null);
    setCitationText({
      match: "",
      exactMatch: false,
      page: 1,
    });
    try {
      for await (const output of searchIndividualDoc(
        searchTerm,
        docToView?.docId ?? "",
        true,
        authInfo.accessToken ?? null
      )) {
        setSearchResults({
          contentMatch: output.search_results,
          exactMatch: output.pages_with_exact_match,
          queryId: output.query_id,
        });
      }
    } catch (error: any) {
      console.error("There was an error running the search", error);
      toast.error("Unable to run search");
    }
    setSearchLoading(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch(searchTerm);
    }
  };

  useEffect(() => {
    if (
      suggestionResults === null ||
      suggestionResults.docId !== docToView?.docId ||
      suggestionResults.question !== props.question
    ) {
      handleSuggestions();
    }
    inputRef.current?.focus();
  }, [docToView?.docId, props.question]);

  return (
    <div className="h-[calc(100vh-350px)] p-2 overflow-y-auto space-y-2">
      <div className="flex items-center space-x-2">
        <div className="flex-grow">
          <Input
            type="search"
            className="text-sm"
            placeholder="Find in document..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={handleKeyDown}
            disabled={searchLoading}
            ref={inputRef}
          />
        </div>
        <Button
          variant="default"
          size="sm"
          onClick={() => handleSearch(searchTerm)}
          disabled={searchLoading}
        >
          Search{" "}
          {searchLoading && (
            <ReloadIcon className="w-4 h-4 ml-2 animate-spin" />
          )}
        </Button>
      </div>
      <Accordion type="single" collapsible className="w-full">
        {searchResults !== null && (
          <AccordionItem key="content-match" value="content-match">
            <AccordionTrigger>
              <div className="flex space-x-4">
                <span>Content Match</span>
                <Badge className="text-xs bg-gray-400">
                  {searchResults.contentMatch.length}
                </Badge>
              </div>
            </AccordionTrigger>
            <AccordionContent>
              {searchResults.contentMatch.map((result) => (
                <ClickableCitation
                  citation={result}
                  queryId={searchResults.queryId}
                  key={result.id}
                />
              ))}
            </AccordionContent>
          </AccordionItem>
        )}
        {searchResults?.exactMatch && (
          <AccordionItem key="exact-match" value="exact-match">
            <AccordionTrigger>
              <div className="flex space-x-4">
                <span>Exact Match</span>
                <Badge className="text-xs bg-gray-400">
                  {searchResults.exactMatch.total}
                </Badge>
              </div>
            </AccordionTrigger>
            <AccordionContent>
              {searchResults.exactMatch.page_matches.map((match) => (
                <div
                  className="text-left gap-4 p-3 w-full rounded-md shadow-md cursor-pointer hover:bg-gray-100"
                  key={match.page}
                  onClick={() => {
                    setCitationText({
                      match: searchTerm,
                      exactMatch: true,
                      page: match.page,
                    });
                    setPageNumber(match.page);
                    individualDocClick(
                      searchResults.queryId,
                      "page",
                      match.id,
                      authInfo.accessToken ?? null
                    );
                  }}
                >
                  Page {match.page}
                </div>
              ))}
            </AccordionContent>
          </AccordionItem>
        )}
        {suggestionResults !== null && suggestionResults.results.length > 0 && (
          <AccordionItem key="suggestions" value="suggestions">
            <AccordionTrigger>
              <span>Suggestions</span>
              <Badge className="text-xs bg-gray-400">
                {suggestionResults.results.length}
              </Badge>
            </AccordionTrigger>
            <AccordionContent>
              {suggestionResults.results.map((result) => (
                <ClickableCitation citation={result} queryId={null} />
              ))}
            </AccordionContent>
          </AccordionItem>
        )}
      </Accordion>
    </div>
  );
};

const AdditionalViewerControls = (props: { hideAtlasWidget?: boolean }) => {
  const authInfo = useAuthInfo();
  const { docToView, pageNumber, citationText } = useContext(DocViewerContext);

  const onLinkClick = async () => {
    let link = `${window.location.origin}/doc-chat?docId=${docToView?.docId}&page=${pageNumber}`;
    if (citationText?.match) {
      link += `&text=${citationText.match}`;
    }
    await navigator.clipboard.writeText(link);
    toast.success("Copied to clipboard");
  };

  const onClickDownload = async () => {
    if (docToView) {
      const success = await downloadData(
        docToView.docId,
        authInfo.accessToken ?? null
      );
      if (!success) {
        toast.error("Error exporting data, please try again");
      }
    }
  };

  return (
    <DropdownMenu modal={true}>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon">
          <MoreVertical className="h-4 w-4" />
          <span className="sr-only">More</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="max-w-[400px]">
        {/* <DropdownMenuItem onClick={() => setOwnersOpen(true)}>
          Owners
        </DropdownMenuItem>
        <DropdownMenuItem onClick={onLinkClick}>
          Link to Section
        </DropdownMenuItem> */}
        <DropdownMenuItem onClick={onClickDownload}>
          Download Document
        </DropdownMenuItem>
        {/* {docMetadata?.regulatory_doc_id && (
          <DropdownMenuItem
            onClick={() =>
              navigate(
                `/regulatory-doc/overview/${docMetadata.regulatory_doc_id}`
              )
            }
          >
            Regulatory Doc Summary
          </DropdownMenuItem>
        )}
        {(docMetadata?.editable_doc_id || docMetadata?.approval_flow_id) && (
          <DropdownMenuItem
            onClick={() =>
              navigate(
                `/policy-repo/doc-view/${
                  docMetadata.editable_doc_id ?? docMetadata.id
                }`
              )
            }
          >
            Policy Repository
          </DropdownMenuItem>
        )} */}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const NumPagesControls = () => {
  const { pageNumber, numPages, setPageNumber } = useContext(DocViewerContext);

  return (
    <div className="flex items-center space-x-1">
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="outline"
            size="icon"
            disabled={pageNumber <= 1}
            onClick={() => setPageNumber((prevPage) => prevPage - 1)}
          >
            <ArrowLeftIcon className="w-4 h-4" />
            <span className="sr-only">Previous Page</span>
          </Button>
        </TooltipTrigger>
        <TooltipContent>Previous Page</TooltipContent>
      </Tooltip>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="outline"
            size="icon"
            disabled={pageNumber >= numPages!}
            onClick={() => setPageNumber((prevPage) => prevPage + 1)}
          >
            <ArrowRightIcon className="w-4 h-4" />
            <span className="sr-only">Next Page</span>
          </Button>
        </TooltipTrigger>
        <TooltipContent>Next Page</TooltipContent>
      </Tooltip>
      <div className="flex items-center space-x-1">
        <Input
          type="number"
          min="1"
          max={numPages!}
          value={pageNumber}
          onChange={(e) => setPageNumber(Number(e.target.value))}
          className="w-18 text-center"
          aria-label="Go to page"
          disabled={numPages === null}
        />
        <div className="w-12">/ {numPages}</div>
      </div>
    </div>
  );
};

export const BaseDocViewerControls = (props: {
  enableHighlight?: boolean;
  hideAtlasWidget?: boolean;
  children?: React.ReactNode;
}) => {
  const { docToView, setRotation, numPages, docMetadata } =
    useContext(DocViewerContext);

  return (
    <div className="space-y-2 w-full">
      <div>
        <Tooltip>
          <TooltipTrigger>
            <a
              href={`/doc-chat?page=1&docId=${docToView?.docId}`}
              className={cn(
                "max-w-[100%] text-md font-semibold truncate text-ellipsis overflow-hidden whitespace-nowrap hover:underline hover:text-blue-500",
                window.location.pathname === "/doc-chat" &&
                  "pointer-events-none"
              )}
            >
              {docMetadata?.name}
            </a>
          </TooltipTrigger>
          <TooltipContent>{docMetadata?.name}</TooltipContent>
        </Tooltip>
      </div>
      <div className="flex justify-between max-w-[100%] items-center space-x-2 overflow-x-auto">
        <div className="flex items-center space-x-2">
          {numPages && (
            <>
              <NumPagesControls />
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="outline"
                    size="icon"
                    onClick={() => setRotation((prev) => prev - 90)}
                  >
                    <RotateCounterClockwiseIcon className="w-4 h-4" />
                    <span className="sr-only">Rotate Left</span>
                  </Button>
                </TooltipTrigger>
                <TooltipContent>Rotate Left</TooltipContent>
              </Tooltip>
            </>
          )}
          {props.children}
        </div>
        {docToView && (
          <AdditionalViewerControls hideAtlasWidget={props.hideAtlasWidget} />
        )}
      </div>
    </div>
  );
};

const useSize = (target: React.RefObject<any>) => {
  const [size, setSize] = useState<any>();

  useEffect(() => {
    if (target.current) {
      setSize(target.current.getBoundingClientRect());
    }
  }, [target]);

  // Where the magic happens
  useResizeObserver(target, (entry: any) => setSize(entry.contentRect));
  return size;
};

type DocViewerProps = {
  enableHighlight: boolean;
  tooltipContent?: ReactNode;
};
export const DocViewer: React.FC<DocViewerProps> = ({
  enableHighlight,
  tooltipContent,
}) => {
  return (
    <PdfHighlighterProvider tooltipContent={tooltipContent}>
      <DocViewerWithHighlighting enableHighlight={enableHighlight} />
    </PdfHighlighterProvider>
  );
};

const DocViewerWithHighlighting: React.FC<{
  enableHighlight: boolean;
}> = ({ enableHighlight }) => {
  const {
    docToView,
    setNumPages,
    numPages,
    pageNumber,
    setPageNumber,
    rotation,
    paginatePDF,
    scrollBoxRef,
  } = useContext(DocViewerContext);
  const canvasRefs = useRef<React.RefObject<HTMLCanvasElement>[]>([]);
  const [pageToScrollWhenLoaded, setPageToScrollWhenLoaded] = useState<
    number | false
  >(false);
  const isPageScrolling = useRef<boolean>(false);
  const onRenderTextLayerSuccessRefs = useRef<(() => void)[]>([]);

  const { onPageRenderSuccess, pdfPageRefs, setPdfPageRefs } =
    usePdfHighlighter();

  useEffect(() => {
    if (numPages && numPages > 0) {
      const pageRefs = new Array(numPages)
        .fill(null)
        .map(() => React.createRef<HTMLDivElement>());
      setPdfPageRefs(pageRefs);
      canvasRefs.current = new Array(numPages)
        .fill(null)
        .map(() => React.createRef<HTMLCanvasElement>());
    }

    // onRenderTextLayerSuccessRefs.current = new Array(numPages)
    //   .fill(null)
    //   .map((_, index) => {
    //     return () => {
    //       const canvasRef = canvasRefs.current[index]?.current;
    //       const pageRef = pageRefs.current[index]?.current;
    //       if (canvasRef && pageRef) {
    //         const spans = pageRef.querySelectorAll("span[role='presentation']");
    //         spans.forEach((span) => {
    //           const { style } = span as HTMLElement;
    //           const canvasBoundingRect = canvasRef.getBoundingClientRect();
    //           const computedStyle = window.getComputedStyle(canvasRef);
    //           const pdfCanvasWidth = computedStyle.width;
    //           const wScale =
    //             parseInt(pdfCanvasWidth) / canvasBoundingRect.width;
    //           // style.color = "red";
    //           // style.transform = `scaleX(${wScale})`;
    //         });
    //       }
    //     };
    //   });
  }, [numPages]);

  const onPageLoaded = (pageNumber: number) => {
    if (pageToScrollWhenLoaded === pageNumber) {
      scrollToPage(pageNumber);
      setPageToScrollWhenLoaded(false);
    }
  };
  const scrollToPage = (pageNumber: number) => {
    isPageScrolling.current = true;
    const pageToScroll = pdfPageRefs?.current[pageNumber]?.current;
    if (pageToScroll) {
      pageToScroll.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    setTimeout(() => {
      isPageScrolling.current = false;
    }, 1000);
  };

  const authInfo = useAuthInfo();
  const target = useRef<HTMLDivElement>(null);
  const size = useSize(target);

  const [isDocumentReady, setIsDocumentReady] = useState<boolean>(false);
  const fileUrl = useMemo(() => {
    return createPdfUrl(docToView?.docId ?? "", docToView?.historyId ?? "");
  }, [docToView?.docId, docToView?.historyId]);

  const fileOptions = useMemo(() => {
    return {
      httpHeaders: {
        Authorization: `Bearer ${authInfo.accessToken}`,
      },
    };
  }, [authInfo.isLoggedIn]);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumPages(numPages);
    setIsDocumentReady(true);
    if (!paginatePDF) {
      setPageToScrollWhenLoaded(pageNumber - 1);
    } else {
      setPageToScrollWhenLoaded(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (scrollBoxRef.current && !isPageScrolling.current) {
        const scrollBoxRect = scrollBoxRef.current.getBoundingClientRect();
        const checkpoint = scrollBoxRect.top + scrollBoxRect.height * 0.66;
        let topmostPageIndex = null;
        pdfPageRefs?.current.forEach((pageRef, index) => {
          if (pageRef.current) {
            const pageTop = pageRef.current.getBoundingClientRect().top;
            if (pageTop <= checkpoint) {
              topmostPageIndex = index;
            }
          }
        });
        if (topmostPageIndex !== null) {
          setPageNumber(topmostPageIndex + 1);
        }
      }
    };
    const scrollBox = scrollBoxRef.current;
    if (scrollBox) {
      scrollBox.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (scrollBox) {
        scrollBox.removeEventListener("scroll", handleScroll);
      }
    };
  }, [scrollBoxRef, scrollBoxRef.current]);
  useEffect(() => {
    setPageToScrollWhenLoaded(pageNumber - 1);
    if (!paginatePDF) {
      scrollToPage(pageNumber - 1);
    }
  }, [pageNumber]);

  useEffect(() => {
    if (!paginatePDF) {
      setPageToScrollWhenLoaded(pageNumber - 1);
    }
  }, [paginatePDF]);
  return (
    <div>
      <div ref={target} style={{ userSelect: "none", position: "relative" }}>
        <Document
          file={fileUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          error={
            <div className="font-semibold">
              Failed to load document because it is not a valid PDF. You can
              still download the document by clicking the download button above
            </div>
          }
          options={fileOptions}
          loading={<LoadingView />}
        >
          {isDocumentReady &&
            (paginatePDF || (numPages && numPages > 20) ? (
              <Page
                className="border-2 border-gray-100 scale-99"
                pageNumber={pageNumber}
                width={size?.width ?? undefined}
                height={size?.height ?? undefined}
                loading={<LoadingView />}
                inputRef={pdfPageRefs?.current[pageNumber - 1]}
                onRenderSuccess={() => {
                  onPageRenderSuccess();
                }}
                rotate={rotation}
              />
            ) : (
              Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  className="border-2 border-gray-100 scale-99"
                  width={size?.width ?? undefined}
                  height={size?.height ?? undefined}
                  onRenderSuccess={() => {
                    onPageRenderSuccess();
                    onPageLoaded(index);
                  }}
                  onRenderTextLayerSuccess={
                    onRenderTextLayerSuccessRefs.current[index]
                  }
                  loading={<LoadingView />}
                  inputRef={pdfPageRefs?.current[index]}
                  canvasRef={canvasRefs.current[index]}
                  rotate={rotation}
                />
              ))
            ))}
        </Document>
      </div>
    </div>
  );
};

export const DocViewerCitation = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    docId: string;
    enableHighlight?: boolean;
    hideAtlasWidget?: boolean;
    children?: React.ReactNode;
    tooltipContent?: ReactNode;
  }
>(
  ({
    className,
    docId,
    enableHighlight,
    hideAtlasWidget,
    children,
    tooltipContent,
  }) => {
    const {
      docToView,
      setDocToView,
      setScrollBoxRef,
      numPages,
      paginatePDF,
      setPaginatePDF,
    } = useContext(DocViewerContext);
    const scrollBoxRef = useRef<HTMLDivElement | null>(null);
    const [enableFullPdf, setEnableFullPdf] = useState<boolean>(true);
    useEffect(() => {
      if (docId && (docToView?.docId !== docId || docToView === null)) {
        setDocToView({
          docId: docId,
        });
      }
    }, [docId]);

    useEffect(() => {
      if (scrollBoxRef.current) {
        setScrollBoxRef(scrollBoxRef.current);
      }
    }, [scrollBoxRef, scrollBoxRef.current]);

    useEffect(() => {
      if (numPages && numPages > 20) {
        setEnableFullPdf(false);
        setPaginatePDF(true);
      } else {
        setEnableFullPdf(true);
      }
    }, [numPages]);

    return (
      <div className="space-y-4 mt-2 pl-2">
        <BaseDocViewerControls hideAtlasWidget={hideAtlasWidget}>
          {children}
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant={paginatePDF ? "outline" : "default"}
                size="icon"
                disabled={!enableFullPdf}
                onClick={() => setPaginatePDF(!paginatePDF)}
              >
                <ReaderIcon className="w-4 h-4" />
                <span className="sr-only">Full PDF</span>
              </Button>
            </TooltipTrigger>
            <TooltipContent>Enable 'Full PDF' scroll mode</TooltipContent>
          </Tooltip>
        </BaseDocViewerControls>
        <div ref={scrollBoxRef} className={cn("overflow-y-auto", className)}>
          {docToView && (
            <DocViewer
              enableHighlight={enableHighlight === true}
              tooltipContent={tooltipContent}
            />
          )}
        </div>
      </div>
    );
  }
);
