import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Badge } from "../../shadcn/components/badge";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "../../shadcn/components/resizable";
import { useAuthInfo } from "@propelauth/react";
import {
  DocType,
  PreviousSearch,
  SearchDocName,
  SearchResult,
  SpecificDocSearchResult,
} from "../../types";
import {
  getPreviousSearches,
  getSearchResults,
  resultClick,
  runSearch,
  searchAdditionalResults,
} from "../../utils/apiCalls";
import {
  ReloadIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@radix-ui/react-icons";
import { Button } from "../../shadcn/components/button";
import { toast } from "sonner";
import {
  SetURLSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Layout } from "../../components/Layout";
import {
  SearchResultCard,
  processRelevantQuote,
} from "../../components/SearchResultCard";
import { RequestError } from "../../utils/Ajax";
import { UserContext } from "../../contexts/UserContext";
import { MultiSelectControl } from "../../components/MultiSelectControl";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../shadcn/components/tabs";
import { DocViewerCitation } from "../../components/DocViewer";
import { DocViewerContext } from "../../contexts/DocViewerContext";
import { processPdfString } from "../../utils/format";
import { SparkleIcon } from "lucide-react";
import { SearchBar } from "./SearchBar";
import { MarkdownCitationDisplay } from "../../components/MarkdownDisplay";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../../shadcn/components/collapsible";

const getUniqueDocSources = (searchResults: SearchResult[]) => {
  return [
    ...new Map(
      searchResults
        .map((result) => result.doc_type)
        .filter(Boolean)
        .map((docType) => [docType.id, docType])
    ).values(),
  ];
};

const getUniqueDocNames = (searchResults: SearchResult[]) => {
  return [
    ...new Map(
      searchResults
        .map((result) => ({
          id: result.doc_id,
          name: result.doc_name,
          group: result.doc_type.name,
        }))
        .filter(Boolean)
        .map((result) => [result.id, result])
    ).values(),
  ];
};

interface SearchModel {
  time: number | null;
  results: SearchResult[] | null;
  search_id: string | null;
  summary: string | null;
  query_suggestions: string[];
  search_doc_names: SearchDocName[];
  data_source_suggestions: DocType[];
  web_search_result: string | null;
  specific_doc_search_result: SpecificDocSearchResult | null;
}

const emptySearchModel: SearchModel = {
  time: null,
  results: null,
  search_id: null,
  summary: null,
  query_suggestions: [],
  search_doc_names: [],
  data_source_suggestions: [],
  web_search_result: null,
  specific_doc_search_result: null,
};

const QuerySuggestions = (props: {
  query_suggestions: string[];
  setUserInput: React.Dispatch<React.SetStateAction<string>>;
  handleSearch: (searchQuery: string) => void;
}) => {
  const onClick = (suggestion: string) => {
    props.setUserInput(suggestion);
    props.handleSearch(suggestion);
  };

  return (
    <div className="flex flex-wrap gap-2">
      {props.query_suggestions.map((suggestion) => (
        <Button
          className="h-auto w-full py-2 text-left justify-start"
          variant="outline"
          key={suggestion}
          onClick={() => onClick(suggestion)}
        >
          {suggestion}
        </Button>
      ))}
    </div>
  );
};

const SearchFilterSuggestions = (props: {
  dataSourceSuggestions: DocType[];
  setSearchResults: React.Dispatch<React.SetStateAction<SearchModel>>;
  setDocumentTypes: React.Dispatch<React.SetStateAction<DocType[]>>;
  handleSearch: (docTypes: DocType[]) => void;
}) => {
  return (
    <div className="flex items-center space-x-2">
      <div className="font-semibold text-xs">
        Looking for something else? Try focusing on one of these data sources:
      </div>
      {props.dataSourceSuggestions.map((docType) => (
        <Badge
          key={docType.id}
          className="cursor-pointer bg-white text-black hover:bg-gray-100 text-xs"
          onClick={() => {
            props.setSearchResults(emptySearchModel);
            props.setDocumentTypes([docType]);
            props.handleSearch([docType]);
          }}
        >
          {docType.short_display_name || docType.name}
        </Badge>
      ))}
    </div>
  );
};

const SearchHeader = (props: {
  searchResults: SearchModel;
  setSearchResults: React.Dispatch<React.SetStateAction<SearchModel>>;
  handleSearch: (searchQuery: string, docTypes: DocType[]) => void;
  userInput: string;
  setUserInput: React.Dispatch<React.SetStateAction<string>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  documentTypes: DocType[];
  setDocumentTypes: React.Dispatch<React.SetStateAction<DocType[]>>;
  handleCancel: () => void;
  searchMessage: string;
  cancelLoading: boolean;
  setSearchParams: SetURLSearchParams;
  previousSearches: PreviousSearch[];
}) => {
  const { allDocTypes } = useContext(UserContext);

  return (
    <div className="space-y-2">
      <div className="w-[100%] flex items-center space-x-4">
        <div className="flex items-center">
          <MultiSelectControl
            title="Data Sources"
            items={allDocTypes
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((docType) => ({
                id: docType.id,
                name: docType.name,
                group: docType.external ? "External" : "Internal",
              }))}
            selectedItems={props.documentTypes.map((docType) => ({
              id: docType.id,
              name: docType.name,
              group: docType.external ? "External" : "Internal",
            }))}
            clearSelectedItems={() => {
              props.setDocumentTypes([]);
            }}
            selectAll={() => {
              props.setDocumentTypes(allDocTypes);
            }}
            selectItem={(item, isSelected) =>
              props.setDocumentTypes((prev) => {
                if (isSelected) {
                  const docType = allDocTypes.find((dt) => dt.id === item.id);
                  return docType ? [...prev, docType] : prev;
                }
                return prev.filter((docType) => docType.id !== item.id);
              })
            }
            selectItemOnly={(item) => {
              const docType = allDocTypes.find((dt) => dt.id === item.id);
              if (docType) {
                props.setDocumentTypes([docType]);
              }
            }}
            selectGroupOnly={(group) => {
              const docTypes = allDocTypes.filter(
                (dt) => dt.external === (group === "External")
              );
              props.setDocumentTypes(docTypes);
            }}
          />
        </div>
        <div className="flex-grow">
          <SearchBar
            previousSearches={props.previousSearches}
            searchTerm={props.userInput}
            setSearchTerm={props.setUserInput}
            handleSearch={() => {
              props.handleSearch(props.userInput, props.documentTypes);
            }}
            selectSearch={(searchId: string) => {
              props.setSearchParams({ search_id: searchId });
            }}
            disabled={props.isLoading || props.documentTypes.length === 0}
          />
        </div>
        <Button
          variant={props.isLoading ? "destructive" : "default"}
          size="sm"
          onClick={() => {
            if (props.isLoading) {
              props.handleCancel();
            } else {
              props.handleSearch(props.userInput, props.documentTypes);
            }
          }}
          disabled={props.documentTypes.length === 0 || props.cancelLoading}
        >
          {props.isLoading ? "Cancel" : "Search"}
        </Button>
      </div>
      {props.searchMessage && (
        <div className="text-sm text-center text-red-500 pt-2">
          {props.searchMessage}
        </div>
      )}
      {props.searchResults.data_source_suggestions &&
        props.searchResults.data_source_suggestions.length > 0 && (
          <SearchFilterSuggestions
            dataSourceSuggestions={props.searchResults.data_source_suggestions}
            setSearchResults={props.setSearchResults}
            setDocumentTypes={props.setDocumentTypes}
            handleSearch={(docTypes: DocType[]) => {
              props.handleSearch(props.userInput, docTypes);
            }}
          />
        )}
    </div>
  );
};

const getResultDocFilterValues = (searchResults: SearchResult[]) => {
  let resultDocumentTypes: { value: DocType; count: number }[] = [];
  let resultDocumentNames: {
    value: { id: string; name: string; group: string };
    count: number;
  }[] = [];
  searchResults.forEach((result) => {
    const docType = result.doc_type;
    if (resultDocumentTypes.some((option) => option.value.id === docType.id)) {
      resultDocumentTypes = resultDocumentTypes.map((option) =>
        option.value.id === docType.id
          ? { ...option, count: option.count + 1 }
          : option
      );
    } else {
      resultDocumentTypes.push({ value: docType, count: 1 });
    }
    const docId = result.doc_id;
    if (resultDocumentNames.some((option) => option.value.id === docId)) {
      resultDocumentNames = resultDocumentNames.map((option) =>
        option.value.id === docId
          ? { ...option, count: option.count + 1 }
          : option
      );
    } else {
      resultDocumentNames.push({
        value: {
          id: docId,
          name: result.doc_name,
          group: result.doc_type.short_display_name || result.doc_type.name,
        },
        count: 1,
      });
    }
  });
  return { resultDocumentTypes, resultDocumentNames };
};

const DocTypeFilterDisplay = (props: {
  searchResults: SearchResult[];
  dataSourceResultFilter: DocType[];
  setDataSourceResultFilter: React.Dispatch<React.SetStateAction<DocType[]>>;
  documentResultFilter: { id: string; name: string; group: string }[];
  setDocumentResultFilter: React.Dispatch<
    React.SetStateAction<{ id: string; name: string; group: string }[]>
  >;
}) => {
  const { resultDocumentTypes, resultDocumentNames } = getResultDocFilterValues(
    props.searchResults
  );

  useEffect(() => {
    if (props.dataSourceResultFilter.length > 0) {
      // clear any document result filters that don't have a dataSourceResultFilter
      props.setDocumentResultFilter((prev) =>
        prev.filter((docName) =>
          props.dataSourceResultFilter.some(
            (docType) =>
              docType.name === docName.group ||
              (docType.short_display_name &&
                docType.short_display_name === docName.group)
          )
        )
      );
    }
  }, [props.dataSourceResultFilter]);

  return (
    <div className="space-x-2 flex items-center">
      <div className="font-bold">Filter By:</div>
      <MultiSelectControl
        title="Data Sources"
        items={resultDocumentTypes.map((docType) => ({
          id: docType.value.id,
          name: docType.value.short_display_name || docType.value.name,
          count: docType.count,
          group: docType.value.external ? "External" : "Internal",
        }))}
        selectedItems={props.dataSourceResultFilter.map((docType) => ({
          id: docType.id,
          name: docType.short_display_name || docType.name,
        }))}
        selectItem={(item, isSelected) => {
          if (isSelected) {
            // find doc type
            const docTypeResult = resultDocumentTypes.find(
              (docType) => docType.value.id === item.id
            );
            if (docTypeResult) {
              props.setDataSourceResultFilter((prev) => [
                ...prev,
                docTypeResult.value,
              ]);
            }
          } else {
            props.setDataSourceResultFilter((prev) =>
              prev.filter((docType) => docType.id !== item.id)
            );
          }
        }}
        clearSelectedItems={() => {
          props.setDataSourceResultFilter([]);
        }}
        selectAll={() => {
          props.setDataSourceResultFilter(
            resultDocumentTypes.map((docType) => docType.value)
          );
        }}
        selectItemOnly={(item) => {
          const docTypeResult = resultDocumentTypes.find(
            (docType) => docType.value.id === item.id
          );
          if (docTypeResult) {
            props.setDataSourceResultFilter([docTypeResult.value]);
          }
        }}
      />
      <MultiSelectControl
        title="Documents"
        items={resultDocumentNames
          .filter((docName) =>
            props.dataSourceResultFilter
              .map((docType) => docType.short_display_name || docType.name)
              .includes(docName.value.group)
          )
          .map((docName) => ({
            id: docName.value.id,
            name: docName.value.name,
            group: docName.value.group,
            count: docName.count,
          }))}
        selectedItems={props.documentResultFilter}
        selectItem={(item, isSelected) => {
          if (isSelected) {
            props.setDocumentResultFilter((prev) => [
              ...prev,
              { id: item.id, name: item.name, group: item.group! },
            ]);
          } else {
            props.setDocumentResultFilter((prev) =>
              prev.filter((docName) => docName.id !== item.id)
            );
          }
        }}
        clearSelectedItems={() => {
          props.setDocumentResultFilter([]);
        }}
        selectAll={() => {
          props.setDocumentResultFilter(
            resultDocumentNames.map((docName) => ({
              id: docName.value.id,
              name: docName.value.name,
              group: docName.value.group,
            }))
          );
        }}
        selectItemOnly={(item) => {
          props.setDocumentResultFilter([
            { id: item.id, name: item.name, group: item.group! },
          ]);
        }}
      />
    </div>
  );
};

const SummaryDisplay = (props: {
  summary: string;
  searchDocIds: string[];
  setActiveSearchDocId: (searchDocId: string | null) => void;
  setSecondaryTab: (tab: string) => void;
}) => {
  return (
    <div className="space-y-2">
      <strong className="text-xl">Summary</strong>
      <MarkdownCitationDisplay
        text={props.summary}
        onCitationClick={(citationId) => {
          props.setSecondaryTab("Viewer");
          props.setActiveSearchDocId(citationId);
        }}
        clickableCitationIds={props.searchDocIds}
      />
    </div>
  );
};

const SuggestionsDisplay = (props: {
  query_suggestions: string[];
  setUserInput: React.Dispatch<React.SetStateAction<string>>;
  handleSearch: (searchQuery: string) => void;
}) => {
  return (
    <>
      {props.query_suggestions.length > 0 && (
        <div className="space-y-2">
          <strong className="text-xl">Related</strong>
          <QuerySuggestions
            query_suggestions={props.query_suggestions}
            setUserInput={props.setUserInput}
            handleSearch={props.handleSearch}
          />
        </div>
      )}
    </>
  );
};

const SearchDocNameDisplay = (props: { searchDocNames: SearchDocName[] }) => {
  return (
    <>
      {props.searchDocNames.length > 0 && (
        <div className="space-y-2 max-h-[400px] overflow-y-auto">
          <strong className="text-xl">Most Relevant Documents</strong>
          <div className="space-y-1">
            {props.searchDocNames.map((docName) => (
              <div
                className="flex items-center space-x-2 whitespace-nowrap overflow-hidden"
                key={docName.doc_id}
              >
                <Badge className="bg-gray-400 inline ml-4 text-xs">
                  {docName.doc_type_name}
                </Badge>
                <a
                  className="flex-grow truncate"
                  href={`/doc-chat?docId=${docName.doc_id}`}
                  key={docName.doc_id}
                >
                  <div className="text-sm text-blue-500 cursor-pointer hover:underline hover:text-blue-700 truncate">
                    {docName.name}
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

const ReferenceDisplay = (props: {
  isLoading: boolean;
  searchResults: SearchModel;
  dataSourceResultFilter: DocType[];
  setDataSourceResultFilter: React.Dispatch<React.SetStateAction<DocType[]>>;
  documentResultFilter: { id: string; name: string; group: string }[];
  setDocumentResultFilter: React.Dispatch<
    React.SetStateAction<{ id: string; name: string; group: string }[]>
  >;
  activeSearchDocId: string | null;
  setActiveSearchDocId: (searchDocId: string | null) => void;
  handleLoadMoreResults: () => Promise<void>;
}) => {
  const authInfo = useAuthInfo();

  const searchResultsToDisplay =
    props.searchResults.results?.filter((result) => {
      return (
        props.dataSourceResultFilter.some(
          (docType) => docType.id === result.doc_type.id
        ) &&
        props.documentResultFilter.some(
          (docName) => docName.id === result.doc_id
        )
      );
    }) || [];

  return (
    <div className="space-y-6">
      <div className="flex items-center space-x-2">
        {props.isLoading ? (
          <>
            <div className="ml-2 text-xl font-normal text-gray-400">
              Finding References..
            </div>
            <ReloadIcon className="h-5 w-5 animate-spin ml-4 text-gray-400" />
          </>
        ) : (
          <>
            {(props.searchResults.results ||
              props.searchResults.web_search_result) && (
              <div className="text-2xl font-bold">
                {props.searchResults.results?.length ?? 0} References Found
              </div>
            )}
            {props.searchResults.time && (
              <div className="ml-2 text-xl text-gray-400">
                ({`${(props.searchResults.time / 1000).toFixed(0)} seconds`})
              </div>
            )}
            {props.searchResults.results &&
              props.searchResults.results.length > 0 &&
              props.searchResults.search_id && (
                <Button
                  size="sm"
                  variant="outline"
                  onClick={props.handleLoadMoreResults}
                >
                  Load More Results
                </Button>
              )}
          </>
        )}
      </div>
      {props.searchResults.results &&
        props.searchResults.results.length > 0 && (
          <div>
            <DocTypeFilterDisplay
              searchResults={props.searchResults.results || []}
              dataSourceResultFilter={props.dataSourceResultFilter}
              setDataSourceResultFilter={props.setDataSourceResultFilter}
              documentResultFilter={props.documentResultFilter}
              setDocumentResultFilter={props.setDocumentResultFilter}
            />
          </div>
        )}
      <div className="overflow-y-auto space-y-4 pr-4 pb-6 h-[calc(100vh-315px)]">
        {searchResultsToDisplay.map((result) => (
          <SearchResultCard
            key={result.search_doc_id}
            searchResult={result}
            clickCallback={async () => {
              await resultClick(
                props.searchResults.search_id!,
                result.search_doc_id,
                authInfo.accessToken ?? null
              );
            }}
            activeSearchDocId={props.activeSearchDocId}
            setActiveSearchDocId={props.setActiveSearchDocId}
          />
        ))}
      </div>
    </div>
  );
};

const WebSearchDisplay = (props: { webSearchResult: string }) => {
  return (
    <div className="space-y-2 h-[calc(100vh-195px)] overflow-y-auto">
      <div className="text-md text-gray-500">
        We couldn't find any references in the documents we have access to.
        While we cannot guarantee its accuracy, here's a summary of results from
        searching the web:
      </div>
      <MarkdownCitationDisplay
        text={props.webSearchResult}
        onCitationClick={() => {}}
        clickableCitationIds={[]}
      />
    </div>
  );
};

const SpecificDocSearchDisplay = (props: {
  specificDocSearchResult: SpecificDocSearchResult;
  setSecondaryTab: (tab: string) => void;
  setActiveSearchDocId: (searchDocId: string | null) => void;
  searchDocIds: string[];
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) => {
  return (
    <Collapsible
      open={props.isOpen}
      onOpenChange={props.setIsOpen}
      className="space-y-1"
    >
      <CollapsibleTrigger>
        <div className="flex items-center space-x-2 text-left">
          <div className="text-xl font-bold">Doc Summary</div>
          <Tooltip>
            <TooltipTrigger>
              <Badge variant="default" className="text-sm">
                {props.specificDocSearchResult.doc_name.slice(0, 20)}
                {props.specificDocSearchResult.doc_name.length > 20 && "..."}
              </Badge>
            </TooltipTrigger>
            <TooltipContent>
              {props.specificDocSearchResult.doc_name}
            </TooltipContent>
          </Tooltip>
          {props.isOpen ? (
            <Tooltip>
              <TooltipTrigger>
                <ChevronUpIcon />
              </TooltipTrigger>
              <TooltipContent>Hide</TooltipContent>
            </Tooltip>
          ) : (
            <Tooltip>
              <TooltipTrigger>
                <ChevronDownIcon />
              </TooltipTrigger>
              <TooltipContent>Show</TooltipContent>
            </Tooltip>
          )}
        </div>
      </CollapsibleTrigger>
      <CollapsibleContent>
        <MarkdownCitationDisplay
          text={props.specificDocSearchResult.answer}
          onCitationClick={(citationId) => {
            props.setSecondaryTab("Viewer");
            props.setActiveSearchDocId(citationId);
          }}
          clickableCitationIds={props.searchDocIds}
        />
      </CollapsibleContent>
    </Collapsible>
  );
};

const DocView = (props: { searchResult: SearchResult }) => {
  const navigate = useNavigate();
  const docChatUrl = useMemo(() => {
    const maxUrlLength = 2048;
    let url = `/doc-chat?docId=${props.searchResult.doc_id}&page=${props.searchResult.page}`;
    if (props.searchResult.relevant_quote) {
      url += `&text=`;
      const availableLength = maxUrlLength - url.length;
      const substring = props.searchResult.text.substring(
        props.searchResult.relevant_quote.start_index,
        Math.min(
          props.searchResult.relevant_quote.start_index + availableLength,
          props.searchResult.relevant_quote.end_index
        )
      );
      const processedText = processPdfString(substring);
      let encodedText = encodeURIComponent(processedText);
      if (encodedText.length > availableLength) {
        encodedText = encodedText.substring(0, availableLength);
      }
      url += encodedText;
    }
    return url;
  }, [props.searchResult]);

  return (
    <DocViewerCitation
      docId={props.searchResult.doc_id}
      className="h-[calc(100vh-330px)]"
      hideAtlasWidget={true}
    >
      <Button onClick={() => navigate(docChatUrl)} variant="default">
        <SparkleIcon className="h-4 w-4 mr-2" /> Search & Chat
      </Button>
    </DocViewerCitation>
  );
};

export const SearchPage = () => {
  const authInfo = useAuthInfo();
  const [isLoading, setIsLoading] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchResults, setSearchResults] =
    useState<SearchModel>(emptySearchModel);
  const { allDocTypes } = useContext(UserContext);
  const [documentTypes, setDocumentTypes] = useState<DocType[]>([]);
  const [searchMessage, setSearchMessage] = useState<string>("");
  const [cancelLoading, setCancelLoading] = useState<boolean>(false);
  const [secondaryTab, setSecondaryTab] = useState<string>("Results");
  const [activeSearchDocId, setActiveSearchDocId] = useState<string | null>(
    null
  );
  const [previousSearches, setPreviousSearches] = useState<PreviousSearch[]>(
    []
  );
  const [dataSourceResultFilter, setDataSourceResultFilter] = useState<
    DocType[]
  >([]);
  const [documentResultFilter, setDocumentResultFilter] = useState<
    {
      id: string;
      name: string;
      group: string;
    }[]
  >([]);
  const [searchDocSummaryOpen, setSearchDocSummaryOpen] =
    useState<boolean>(false);
  const { setDocToView, setCitationText, setPageNumber } =
    useContext(DocViewerContext);
  const cancelRef = useRef<boolean>(false);
  const activeSearchResult = searchResults.results?.find(
    (result) => result.search_doc_id === activeSearchDocId
  );

  useEffect(() => {
    getPreviousSearches(authInfo.accessToken ?? null).then((responses) => {
      if (responses !== null) {
        setPreviousSearches(responses);
      } else {
        toast.error("There was an error fetching the previous searches");
      }
    });
  }, []);

  useEffect(() => {
    if (
      searchResults.specific_doc_search_result !== null &&
      searchResults.summary === null
    ) {
      setSearchDocSummaryOpen(true);
    } else {
      setSearchDocSummaryOpen(false);
    }
  }, [searchResults.specific_doc_search_result]);

  useEffect(() => {
    if (activeSearchResult) {
      setDocToView({ docId: activeSearchResult.doc_id });
      setPageNumber(activeSearchResult.page ?? 1);
      if (activeSearchResult.relevant_quote) {
        const { quote } = processRelevantQuote(
          activeSearchResult.text,
          activeSearchResult.relevant_quote.start_index,
          activeSearchResult.relevant_quote.end_index
        );
        setCitationText({
          match: quote,
          exactMatch: false,
          page: activeSearchResult.page ?? 1,
        });
      }
      if (secondaryTab !== "Viewer") {
        setSecondaryTab("Viewer");
      }
    } else {
      setSecondaryTab("Results");
      setDocToView(null);
      setPageNumber(1);
      setCitationText(null);
    }
  }, [activeSearchResult?.search_doc_id]);

  useEffect(() => {
    if (allDocTypes.length > 0) {
      setDocumentTypes(allDocTypes);
    }
  }, [allDocTypes]);

  useEffect(() => {
    const searchId = searchParams.get("search_id");
    if (searchId && searchResults.search_id !== searchId) {
      getSearchResults(searchId, authInfo.accessToken ?? null).then(
        (results) => {
          if (results !== null) {
            setUserInput(results.query);
            setSearchResults({
              time: null,
              results: results.search_results,
              search_id: results.search_id,
              summary: results.summary,
              query_suggestions: results.query_suggestions,
              search_doc_names: results.search_doc_names,
              data_source_suggestions: results.data_source_suggestions,
              web_search_result: results.web_search_result,
              specific_doc_search_result: results.specific_doc_search_result,
            });
            setDataSourceResultFilter(
              getUniqueDocSources(results.search_results)
            );
            setDocumentResultFilter(getUniqueDocNames(results.search_results));
            setDocumentTypes(
              results.filter_ !== null
                ? allDocTypes.filter((docType) =>
                    results.filter_!.includes(docType.id)
                  )
                : allDocTypes
            );
            setSecondaryTab("Results");
            setActiveSearchDocId(null);
          } else {
            toast.error("There was an error fetching the search results");
          }
        }
      );
    } else if (!searchId) {
      setSearchResults(emptySearchModel);
      setDataSourceResultFilter([]);
      setDocumentResultFilter([]);
      setUserInput("");
    }
  }, [searchParams, allDocTypes]);

  useEffect(() => {
    if (searchResults.search_id !== null) {
      setSearchParams({ search_id: searchResults.search_id });
    }
  }, [searchResults.search_id]);

  const handleCancel = () => {
    setCancelLoading(true);
    cancelRef.current = true;
  };

  const handleSearch = async (searchQuery: string, docTypes: DocType[]) => {
    setSearchMessage("");
    cancelRef.current = false;
    setIsLoading(true);
    const startTime = performance.now();
    setDataSourceResultFilter([]);
    setDocumentResultFilter([]);
    setSearchResults(emptySearchModel);
    setSecondaryTab("Results");
    setActiveSearchDocId(null);
    let newSearchId = "";
    try {
      for await (const output of runSearch(
        searchQuery,
        25,
        docTypes.map((docType) => docType.id),
        authInfo.accessToken ?? null
      )) {
        if (cancelRef.current) {
          setSearchMessage("Search cancelled");
          setCancelLoading(false);
          break;
        }
        if (!newSearchId) {
          newSearchId = output.search_id;
        }
        setSearchResults({
          time: null,
          results: output.search_results,
          search_id: output.search_id,
          summary: output.summary,
          query_suggestions: output.query_suggestions,
          search_doc_names: output.search_doc_names,
          data_source_suggestions: output.data_source_suggestions,
          web_search_result: output.web_search_result,
          specific_doc_search_result: output.specific_doc_search_result,
        });
        setDataSourceResultFilter(getUniqueDocSources(output.search_results));
        setDocumentResultFilter(getUniqueDocNames(output.search_results));
      }
    } catch (error: any) {
      let unknownError = true;
      if (error instanceof RequestError && error.response.status === 403) {
        const errorText = await error.response.json();
        if (
          errorText.detail &&
          (errorText.detail.startsWith("Query contains PHI") ||
            errorText.detail.startsWith("No query provided"))
        ) {
          toast.error(errorText.detail);
          unknownError = false;
        }
      }
      if (unknownError) {
        console.error("There was an error running the search", error);
        toast.error("Unable to run search");
      }
    }

    if (!cancelRef.current) {
      const endTime = performance.now();
      setSearchResults((prev) => ({
        ...prev,
        time: endTime - startTime,
      }));
      setPreviousSearches((prev) => [
        {
          id: newSearchId,
          query: searchQuery,
          timestamp: new Date().toISOString().slice(0, -1),
        } as PreviousSearch,
        ...prev,
      ]);
    }
    setIsLoading(false);
  };

  const handleLoadMoreResults = async () => {
    if (!searchResults.search_id) {
      return;
    }
    setSearchMessage("");
    cancelRef.current = false;
    setIsLoading(true);
    const startTime = performance.now();
    setDataSourceResultFilter(
      getUniqueDocSources(searchResults?.results ?? [])
    );
    setDocumentResultFilter(getUniqueDocNames(searchResults?.results ?? []));
    try {
      for await (const output of searchAdditionalResults(
        searchResults.search_id,
        25,
        authInfo.accessToken ?? null
      )) {
        if (cancelRef.current) {
          setSearchMessage("Search cancelled");
          setCancelLoading(false);
          break;
        }
        setSearchResults({
          time: null,
          results: output.search_results,
          search_id: output.search_id,
          summary: output.summary,
          query_suggestions: output.query_suggestions,
          search_doc_names: output.search_doc_names,
          data_source_suggestions: output.data_source_suggestions,
          web_search_result: output.web_search_result,
          specific_doc_search_result: output.specific_doc_search_result,
        });
        setDataSourceResultFilter(getUniqueDocSources(output.search_results));
        setDocumentResultFilter(getUniqueDocNames(output.search_results));
      }
    } catch (error) {
      console.error(error);
      toast.error("Unable to load more results");
    }

    if (!cancelRef.current) {
      const endTime = performance.now();
      setSearchResults((prev) => ({
        ...prev,
        time: endTime - startTime,
      }));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (documentTypes.length === 0) {
      setSearchMessage("Select at least one data source to search");
    } else {
      setSearchMessage("");
    }
  }, [documentTypes]);

  return (
    <Layout pageName="Search">
      <SearchHeader
        searchResults={searchResults}
        setSearchResults={setSearchResults}
        handleSearch={handleSearch}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        userInput={userInput}
        setUserInput={setUserInput}
        documentTypes={documentTypes}
        setDocumentTypes={setDocumentTypes}
        handleCancel={handleCancel}
        searchMessage={searchMessage}
        cancelLoading={cancelLoading}
        setSearchParams={setSearchParams}
        previousSearches={previousSearches}
      />
      <ResizablePanelGroup className="gap-6" direction="horizontal">
        <ResizablePanel
          defaultSize={activeSearchDocId ? 50 : 70}
          minSize={50}
          maxSize={70}
          id="reference-panel"
          order={2}
        >
          {searchResults.web_search_result ? (
            <WebSearchDisplay
              webSearchResult={searchResults.web_search_result}
            />
          ) : (
            <ReferenceDisplay
              isLoading={isLoading}
              searchResults={searchResults}
              dataSourceResultFilter={dataSourceResultFilter}
              setDataSourceResultFilter={setDataSourceResultFilter}
              documentResultFilter={documentResultFilter}
              setDocumentResultFilter={setDocumentResultFilter}
              activeSearchDocId={activeSearchDocId}
              setActiveSearchDocId={setActiveSearchDocId}
              handleLoadMoreResults={handleLoadMoreResults}
            />
          )}
        </ResizablePanel>
        <ResizableHandle withHandle />
        <ResizablePanel
          defaultSize={30}
          minSize={activeSearchDocId ? 50 : 30}
          maxSize={50}
          id="summary-panel"
          order={3}
        >
          <Tabs value={secondaryTab} onValueChange={setSecondaryTab}>
            {(searchResults.search_doc_names?.length > 0 ||
              searchResults.summary ||
              searchResults.query_suggestions?.length > 0 ||
              activeSearchResult) && (
              <TabsList>
                {(searchResults.search_doc_names?.length > 0 ||
                  searchResults.summary ||
                  searchResults.query_suggestions?.length > 0) && (
                  <TabsTrigger value="Results">Results</TabsTrigger>
                )}
                {activeSearchResult && (
                  <TabsTrigger value="Viewer">Document</TabsTrigger>
                )}
              </TabsList>
            )}
            <TabsContent value="Results">
              <div className="space-y-6 h-[calc(100vh-240px)] overflow-y-auto pr-4">
                <SearchDocNameDisplay
                  searchDocNames={searchResults.search_doc_names}
                />
                {searchResults.summary && (
                  <SummaryDisplay
                    summary={searchResults.summary}
                    setActiveSearchDocId={setActiveSearchDocId}
                    searchDocIds={
                      searchResults.results?.map(
                        (result) => result.search_doc_id
                      ) ?? []
                    }
                    setSecondaryTab={setSecondaryTab}
                  />
                )}
                {searchResults.specific_doc_search_result && (
                  <SpecificDocSearchDisplay
                    specificDocSearchResult={
                      searchResults.specific_doc_search_result
                    }
                    setSecondaryTab={setSecondaryTab}
                    setActiveSearchDocId={setActiveSearchDocId}
                    searchDocIds={
                      searchResults.results?.map(
                        (result) => result.search_doc_id
                      ) ?? []
                    }
                    isOpen={searchDocSummaryOpen}
                    setIsOpen={setSearchDocSummaryOpen}
                  />
                )}
                <SuggestionsDisplay
                  query_suggestions={searchResults.query_suggestions}
                  setUserInput={setUserInput}
                  handleSearch={(searchQuery) => {
                    handleSearch(searchQuery, documentTypes);
                  }}
                />
              </div>
            </TabsContent>
            <TabsContent value="Viewer">
              {activeSearchResult && (
                <DocView searchResult={activeSearchResult} />
              )}
            </TabsContent>
          </Tabs>
        </ResizablePanel>
      </ResizablePanelGroup>
    </Layout>
  );
};
