import { useAuthInfo } from "@propelauth/react";
import { ApprovalEvent, WorkflowApprovalStatus } from "../../types";
import { useEffect, useState } from "react";
import {
  getApprovalEvents,
  signalApproval,
  startApproval,
} from "../../utils/apiCalls";
import { toast } from "sonner";
import React from "react";
import { Badge } from "../../shadcn/components/badge";
import { loadAndFormatTime } from "../../utils/format";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../shadcn/components/alert-dialog";
import { Button } from "../../shadcn/components/button";
import { ReloadIcon } from "@radix-ui/react-icons";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";

const ApprovalEventItem = (props: { approvalEvent: ApprovalEvent }) => {
  let eventTypeBadge;
  switch (props.approvalEvent.event.name) {
    case "approve":
      eventTypeBadge = <Badge className="bg-green-500">Approved</Badge>;
      break;
    case "reject":
      eventTypeBadge = <Badge className="bg-red-500">Rejected</Badge>;
      break;
    case "skip":
      eventTypeBadge = <Badge className="bg-gray-500">Skipped</Badge>;
      break;
    case "cancel":
      eventTypeBadge = <Badge className="bg-yellow-500">Cancelled</Badge>;
      break;
    case "failed":
      eventTypeBadge = <Badge className="bg-red-500">Failed</Badge>;
      break;
    case "start":
      eventTypeBadge = <Badge className="bg-blue-500">Started</Badge>;
      break;
  }

  return (
    <div className="w-full text-sm">
      {eventTypeBadge} by{" "}
      <span className="font-semibold">{`${props.approvalEvent.user.first_name} ${props.approvalEvent.user.last_name[0]}`}</span>{" "}
      <span className="text-gray-500">
        {loadAndFormatTime(props.approvalEvent.updated_at)}
      </span>
    </div>
  );
};

export const DocApprovalEventView = (props: {
  editableDocId: string;
  flowInstanceId: string;
}) => {
  const authInfo = useAuthInfo();
  const [approvalEvents, setApprovalEvents] = useState<ApprovalEvent[]>([]);

  useEffect(() => {
    getApprovalEvents(
      props.editableDocId,
      props.flowInstanceId,
      authInfo.accessToken ?? null
    ).then((events) => {
      if (events !== null) {
        setApprovalEvents(events);
      } else {
        toast.error("Failed to get approval events");
      }
    });
  }, [props.editableDocId, props.flowInstanceId]);

  return (
    <div className="space-y-2 pl-4">
      {approvalEvents.length > 0 &&
        approvalEvents.map((event) => (
          <ApprovalEventItem approvalEvent={event} key={event.id} />
        ))}
      {approvalEvents.length === 0 && <div>No approval events</div>}
    </div>
  );
};

const ApprovalActionButton = (props: {
  docId: string;
  action: "start" | "approve" | "reject" | "cancel";
  stageId: string;
  setApprovalStatus: React.Dispatch<
    React.SetStateAction<WorkflowApprovalStatus | null>
  >;
  buttonVariant: "default" | "outline" | "destructive";
}) => {
  const authInfo = useAuthInfo();
  const [open, setOpen] = useState<boolean>(false);
  const [actionLoading, setActionLoading] = useState<boolean>(false);

  const actionTitle =
    props.action.charAt(0).toUpperCase() + props.action.slice(1);

  const onClickAction = async () => {
    setActionLoading(true);
    let response: boolean;
    if (props.action === "start") {
      response = await startApproval(props.docId, authInfo.accessToken ?? null);
    } else {
      response = await signalApproval(
        props.docId,
        props.action,
        props.stageId,
        authInfo.accessToken ?? null
      );
    }
    if (response) {
      setOpen(false);
    } else {
      toast.error("Error performing action, please try again");
    }
    setActionLoading(false);
  };

  const handleTriggerClick = (event: React.MouseEvent) => {
    event.stopPropagation(); // Stop event propagation here
    event.preventDefault();
    setOpen(true);
  };

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>
        <Button variant={props.buttonVariant} onClick={handleTriggerClick}>
          {actionTitle}
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            {`Are You Sure You Want To ${actionTitle}?`}
          </AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button variant="default" size="sm" onClick={onClickAction}>
            {actionTitle}
            {actionLoading && (
              <ReloadIcon className="h-4 w-4 ml-2 animate-spin" />
            )}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

const OthersTooltip = (props: { emails: string[] }) => {
  return (
    <Tooltip>
      <TooltipTrigger>
        <Badge className="bg-gray-500">{props.emails.length} others</Badge>
      </TooltipTrigger>
      <TooltipContent>
        {props.emails.map((email) => (
          <div key={email}>{email}</div>
        ))}
      </TooltipContent>
    </Tooltip>
  );
};

export const DocApprovalWorkflowView = (props: {
  editableDocId: string;
  approvalStatus: WorkflowApprovalStatus | null;
  setApprovalStatus: React.Dispatch<
    React.SetStateAction<WorkflowApprovalStatus | null>
  >;
}) => {
  const authInfo = useAuthInfo();

  let title = (
    <span>
      You don't have an approval flow associated with this document. Create one
      now
    </span>
  );

  let buttons: React.ReactNode[] = [];
  if (props.approvalStatus !== null) {
    if (props.approvalStatus.approval_stage !== null) {
      if (
        props.approvalStatus.allowed_user_emails.includes(
          authInfo.user?.email ?? ""
        )
      ) {
        title = (
          <span>
            Awaiting Your Approval
            {props.approvalStatus.allowed_user_emails.length > 1 && (
              <span>
                {` and `}
                {
                  <OthersTooltip
                    emails={props.approvalStatus.allowed_user_emails.filter(
                      (email) => email !== authInfo.user?.email
                    )}
                  />
                }
              </span>
            )}
          </span>
        );
        buttons = [
          <ApprovalActionButton
            key="approve"
            docId={props.editableDocId}
            action="approve"
            stageId={props.approvalStatus.approval_stage.id}
            setApprovalStatus={props.setApprovalStatus}
            buttonVariant="default"
          />,
          <ApprovalActionButton
            key="reject"
            docId={props.editableDocId}
            action="reject"
            stageId={props.approvalStatus.approval_stage.id}
            setApprovalStatus={props.setApprovalStatus}
            buttonVariant="destructive"
          />,
          <ApprovalActionButton
            key="cancel"
            docId={props.editableDocId}
            action="cancel"
            stageId={props.approvalStatus.approval_stage.id}
            setApprovalStatus={props.setApprovalStatus}
            buttonVariant="outline"
          />,
        ];
      } else {
        title = (
          <span>
            Awaiting Approval by{" "}
            <OthersTooltip
              emails={props.approvalStatus.allowed_user_emails.filter(
                (email) => email !== authInfo.user?.email
              )}
            />
          </span>
        );
        buttons = [];
      }
    } else if (props.approvalStatus.can_start === true) {
      title = <span>No Approval Flow Running</span>;
      buttons = [
        <ApprovalActionButton
          key="start"
          docId={props.editableDocId}
          action="start"
          stageId=""
          setApprovalStatus={props.setApprovalStatus}
          buttonVariant="default"
        />,
      ];
    }
  }

  return (
    <div className="space-y-2">
      <div className="text-gray-500">{title}</div>
      {buttons.length > 0 && <div className="space-x-2">{buttons}</div>}
    </div>
  );
};
