import { useEffect, useState } from "react";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from "../../shadcn/components/sheet";

const DataItem = (props: { title: string; value: string }) => {
  return (
    <div className="flex items-center justify-between text-sm">
      <div className="font-semibold">{props.title}</div>
      <div className="text-gray-500">{props.value}</div>
    </div>
  );
};

const DataSection = (props: {
  title: string;
  items: { title: string; value: string }[];
}) => {
  return (
    <div className="space-y-2">
      <div className="font-bold">{props.title}</div>
      <div className="space-y-1 pl-4">
        {props.items
          .filter((item) => item.value)
          .map((item) => (
            <DataItem key={item.title} title={item.title} value={item.value} />
          ))}
      </div>
    </div>
  );
};

export const DashboardRecord = (props: {
  record: Record<string, any>;
  setActiveRecord: (record: Record<string, any> | null) => void;
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true);
  }, [props.record]);

  return (
    <Sheet
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          props.setActiveRecord(null);
        }
        setOpen(open);
      }}
    >
      <SheetContent style={{ maxWidth: "50vw" }}>
        <SheetHeader>
          <SheetTitle>{props.record.APL_Regulator}</SheetTitle>
          <SheetDescription>{props.record.APL_APLName}</SheetDescription>
        </SheetHeader>
        <div className="pt-4 space-y-4 overflow-y-auto max-h-[calc(100vh-100px)]">
          <DataSection
            title="Dates"
            items={[
              {
                title: "Released",
                value: props.record.APL_ReleasedDate,
              },
              {
                title: "Received",
                value: props.record.APL_DateReceived,
              },
              {
                title: "Workplan Received",
                value: props.record.APL_DateWorkplanReceived,
              },
              {
                title: "Workplan Completed",
                value: props.record.APL_DateWorkplanCompleted,
              },
              {
                title: "Response Due",
                value: props.record.APL_ResponseDueDate,
              },
              {
                title: "Due",
                value: props.record.APL_DateDue,
              },
              {
                title: "Implementation",
                value: props.record["APL Implementation Date"],
              },
              {
                title: "Anticipated Compliance",
                value: props.record["APL_AnticipatedComplianceDate"],
              },
            ].sort((a, b) => {
              return new Date(a.value).getTime() - new Date(b.value).getTime();
            })}
          />
          <DataSection
            title="Status"
            items={[
              {
                title: "Review Level of Compliance",
                value: props.record.APL_ReviewLevelofCompliance,
              },
              {
                title: "Workflow Status",
                value: props.record.WorkflowStatus,
              },
              { title: "Status Update", value: props.record.APL_StatusUpdate },
            ]}
          />
          <DataSection
            title="People"
            items={[
              { title: "Employee", value: props.record["Employee Name"] },
              {
                title: "Additional Responsible Parties",
                value: props.record.APL_AdditionalResponsiblePartiesCSV,
              },
              {
                title: "Job Title",
                value: props.record["Job Title"],
              },
            ]}
          />
          <div className="space-y-4">
            <div className="font-bold">Details</div>
            <div className="space-y-4 pl-4 text-sm">
              {props.record["APL_ActionItems-Compliant"] && (
                <div>
                  <div className="font-semibold">Action Items - Compliant</div>
                  <div className="text-gray-500 whitespace-pre-wrap">
                    {props.record["APL_ActionItems-Compliant"]}
                  </div>
                </div>
              )}
              {props.record["APL_ActionItemsWorkplan"] && (
                <div>
                  <div className="font-semibold">Action Items - Workplan</div>
                  <div className="text-gray-500 whitespace-pre-wrap">
                    {props.record["APL_ActionItemsWorkplan"]}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};
