import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Box } from "./types";
import { DocViewerContext } from "../../contexts/DocViewerContext";
import ReactDOM from "react-dom";
import { usePdfHighlighter } from ".";
import { cn } from "../../shadcn/lib/utils";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import { useModalContext } from "../../contexts/ActiveModalContext";

export const HighlightTooltipActionButton = (props: {
  text: string;
  explanationContent: string;
  onClick: () => void;
}) => {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <div
          className="w-full hover:bg-accent hover:text-accent-foreground text-sm text-left py-2 pl-8"
          onClick={props.onClick}
        >
          {props.text}
        </div>
      </TooltipTrigger>
      <TooltipContent side="right">{props.explanationContent}</TooltipContent>
    </Tooltip>
  );
};

type HighlightToolTipProps = {
  isVisible: boolean;
  highlightBox: Box;
  className?: string;
};
const HighlightTooltip: React.FC<PropsWithChildren & HighlightToolTipProps> = ({
  children,
  isVisible,
  highlightBox,
  className,
}) => {
  const toolTipRef = useRef<HTMLDivElement | null>(null);
  const { scrollBoxRef } = useContext(DocViewerContext);
  const { activeModalRef } = useModalContext();
  const [toolTipH, setToolTipH] = useState(0);
  const [toolTipR, setToolTipR] = useState(0);
  const [isHighlightOob, setIsHighlightOob] = useState(false);
  useEffect(() => {
    const element = scrollBoxRef.current;
    if (!element) return;
    element.addEventListener("scroll", calculateTooltipHeight);

    // Cleanup listener on unmount
    return () => {
      element.removeEventListener("scroll", calculateTooltipHeight);
    };
  }, [scrollBoxRef, scrollBoxRef.current, highlightBox]);

  useEffect(() => {
    calculateTooltipHeight();
  }, [highlightBox]);

  const calculateTooltipHeight = () => {
    const scrollBoxRect = scrollBoxRef.current?.getBoundingClientRect();
    const scrollPosition = scrollBoxRef.current?.scrollTop || 0;
    const pdfPageH = scrollBoxRect?.top || 0;
    const toolTipH = toolTipRef.current?.getBoundingClientRect().height || 0;
    const pdfPageL = scrollBoxRect?.left || 0;
    const calculatedHeight = pdfPageH + highlightBox.top - scrollPosition;
    const maxToolTipHeight = (scrollBoxRect?.bottom || 0) - toolTipH - 15;
    const minToolTipheight = scrollBoxRect?.top || 0;
    const isOutOfBounds =
      calculatedHeight > (scrollBoxRect?.bottom || 0) ||
      highlightBox.bottom < scrollPosition;
    //Bind calculated height between window height and pdf box top
    setToolTipH(
      Math.max(Math.min(maxToolTipHeight, calculatedHeight), minToolTipheight)
    );

    setIsHighlightOob(isOutOfBounds);
    setToolTipR(window.innerWidth - pdfPageL - highlightBox.left + 10);
  };
  useEffect(() => {}, [toolTipRef.current]);

  const ttStyle =
    "overflow-hidden rounded-md border bg-popover text-sm text-popover-foreground shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2";
  return ReactDOM.createPortal(
    <div
      className={cn(`absolute z-10 ${ttStyle}`, className)}
      style={{
        top: `${Math.floor(toolTipH)}px`,
        right: `${Math.floor(toolTipR)}px`,
        opacity: isVisible && !isHighlightOob ? "1" : "0",
        pointerEvents: isVisible && !isHighlightOob ? "all" : "none",
        transition: `opacity 100ms`,
      }}
      ref={toolTipRef}
    >
      {children}
    </div>,
    activeModalRef?.current
      ? activeModalRef?.current
      : (document.getElementById("root") as Element)
  );
};

export default HighlightTooltip;
